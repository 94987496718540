import {
    Component,
    ViewChild,
    Injector,
    Output,
    EventEmitter,
    OnInit,
    ChangeDetectorRef
} from '@angular/core';

import { FormBuilder } from '@angular/forms';
import { UtilService } from '../../service/util.service';
import { VelocityService } from '../../service/velocity.service';

import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { ProgressBarOptions, ProgressBarHelper } from './../../progress-bar-module/progress-bar-options';
import { CONTENTS } from '../../config/content.const';
import { BaseComponent } from './../../component/base.component';
import { Router } from '@angular/router';
import { LogTypes, LogAction } from '../../service/model/log-models';
import { LogService } from '../../service/log.service';

import { GrowthPlanListDto, DuplicateGrowthPlanData, GrowthPlanSessionDto } from '../../focus/model/face-to-face.model';
import { FaceToFaceService } from '../../service/face-to-face.service';
import { StorageHelperService } from '../../service/storage-helper.service';
import { Constants } from '../../service/constant.service';
import { DataShareService } from '../../service/data-share.service';
import { ModalDialogService } from "./../../modal-dialog-module/modal-dialog.service";

@Component({
    selector: "corporate-owner-info-dialog",
    templateUrl: "./corporate-owner-info-dialog-component.html",
    styleUrls: ['./corporate-owner-info-dialog-component.scss']

})

export class CorporateOwnerInfoDialogComponent extends BaseComponent implements OnInit {


    observable: Observable<any>;
    subject: any;
    PageContents: any = {};

    // isFacilitator: boolean;
    growthPlanId: any;
    growthPlan: any;
    typeId: any;

    growthPlanDetailsArr: any = {};
    growthPlanDetailsArr2: any = {};
    ownerDetailsArr: any = {};
    delegatedOwnerArr: any = {};
    faciliatorArr: any = {};
    participantsArr: any = {};
    EYParticipantaArr: any = {};
    ChangedGrowthPlandetails: boolean = false;
    @Output("closeEvent") componentEvent: any = new EventEmitter()
    @ViewChild('corporateOwnerInfoDialogModal', { static: true }) coInfoDialogModal: ModalDirective;
    //  activeStep: number = 1;
    isLoaded: boolean = false;
    growthPlanName: string;
    showGpDelete: boolean = false;
    showGpDeleteButton: boolean = false;
    showGpInfoDialog: boolean = true;
    errorMsgDelete: boolean = false;
    showGPbutton: boolean = false;
    showGPselectbox: boolean = false;
    isalreadyNominatedGP: any;
    checkisAssessmentStarted: any;
  
    growthPlanTypeId: any;
    isParticipant: boolean = false;
    isFacilitator: boolean = false;
    isFinalized: boolean = false;
    gpNameSectionEdit: boolean = false;
    gpTitle: any;
    errorMessageRename: boolean = false;
    errorGPEmpty: boolean = false;
    //isGpEditable: boolean = true;
    isOwner: boolean = false;
    isDelOwner: boolean = false;
    isShared: boolean = false;
    selectedGP: any = undefined;
    selectedGPvalue: any;
    showalreadyNominatedError: boolean = false;
    showunexpectedError: boolean = false;
    selfFacilitateNominations: any[] = [];
    isDuplicate: boolean = false;
    showGpDuplicate: boolean = false;
    errorresponseMessageData: string = '';
    duplicate_meetingDate: any;
    duplicate_growthPlanTypeId: any;
    duplicate_facilitatorCode: any;
    duplicate_memberId: any;
    duplicate_clientName: any;
    duplicate_clientEmail: any;
    duplicate_companyName: any;
    duplicate_facilitatorFlow: any;
    formSubmission_SPECIALCHAR_IsValid: boolean = false;
    duplicateGrowthPlanName: string = '';
    errorGPEmptyDuplicate: boolean = false;
    formSubmission_GP_IsValid: boolean = false;
    errorMessageData: string = '';
    private currentGrowthPlan: GrowthPlanSessionDto = new GrowthPlanSessionDto(0, '', false, false);
    isParticipantCheck: boolean = false;
    showNomination: boolean = false;
    showSelectGP: boolean = true;
    selfGP: any = [];
    errorExist = false;
    errorMsg = '';
    selectedGPName: string = '';
    selectedGPId: number;
    selectedMessage = '';
  growthPlanTypeIdNominate: any;
    isInvitationAccepted = null;
    isOwnerSelfStarted: boolean = false;
    isLocked: boolean = false;
    count4error: boolean = false;
    isPracticeDemoMeeting: boolean = false;
    onlyParticipantArr = [];
    demoParticipantArr = [];
    dummyUsers = [];
    hasAxisDifference: boolean = false;
    isContinued: boolean = false;
    otherContents: any;
    axesDifferenceModalContent: any;
    isLoading: boolean = false;
    showNominationTitle: boolean = false;
    constructor(
        private utilService: UtilService,
        private change: ChangeDetectorRef,
        private velocityService: VelocityService,
        private formBuilder: FormBuilder,
        private injector: Injector,
        private router: Router,
        private logTypes: LogTypes,
        private logService: LogService,
        private faceToFaceService: FaceToFaceService,
        private storageHelper: StorageHelperService,
        private constants: Constants,
        private dataShareService: DataShareService,
        private dialogService: ModalDialogService
    ) {
        super(injector);
        this.growthPlan = this.injector.get('growthPlan');
        this.PageContents = this.injector.get('PageContents');
        this.isDuplicate = this.injector.get('isDuplicate');
        this.showNomination = this.injector.get('showNomination');
        this.showNominationTitle = this.injector.get('showNomination');
        this.typeId = this.injector.get('typeId');
        this.otherContents = this.injector.get('otherContents');

      this.currentGrowthPlan = this.storageHelper.GetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
      if (this.PageContents == null) {
        this.PageContents = {};
        this.utilService.getContent(CONTENTS.GROWTH_PLAN_SESSION).subscribe((x: any) => {
          this.PageContents = x;
        });
      }
    }

    public config = {
        animated: false,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };

    returnedValue: any = {};

    ngOnInit() {
        this.growthPlanId = this.growthPlan.growthPlanId;
        this.growthPlanTypeId = this.growthPlan.growthPlanTypeId;
        console.log('this.growthPlanTypeId');
        console.log(this.growthPlanTypeId);
        this.growthPlanTypeIdNominate = this.growthPlan.growthPlanTypeId;
        this.checkCGPStatus();
        this.getGPDetails();
        if (this.isDuplicate) {
            this.duplicateGP();
        }
        this.selectedGP = undefined;
        //var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("CPInfoDialog");
        //var getSelfFacilitateNomination = this.velocityService.getSelfNomination(updateProgressBar);
        //getSelfFacilitateNomination.subscribe((data) => {
        //    this.selfFacilitateNominations = data;
        //})
    }

    getDummyUsers() {
        this.velocityService.getDummyUsers().subscribe((users: any) => {
            this.dummyUsers = users;
            this.dummyUsers.forEach(u => u.isDemoUser = false);
            this.demoParticipantArr?.forEach(p => {
                this.dummyUsers?.forEach((d, i) => {
                    if (p.userId === d.userId) {
                        d.isDemoUser = p.isDemoUser;
                    }
                });
            });
        });
    }
    getGPDetails() {
        var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("COInfoDialog");
        this.velocityService.getCorporateOwnerGrowthPlanDetails(this.growthPlanId, updateProgressBar).subscribe((data) => {
            //console.log("kkpp---------------------------");
            //console.log(data.GrowthPlanDetails);
            if (data) {
              this.growthPlanDetailsArr = data.CorporateGrowthPlanDetails;
                // this.growthPlanName = data.GrowthPlanDetails.growthPlanName;
                this.ownerDetailsArr = data.GrowthPlanOwnerDetails;
                this.faciliatorArr = data.GrowthPlanFacilitatorDetails;
                this.delegatedOwnerArr = data.GrowthPlanDelegateOwnerDetails;
                     
                this.participantsArr = data.Participants;
                this.participantsArr.forEach(part => {
                    if (part.isDemoUser) {
                        this.demoParticipantArr.push(part);
                    }
                    if (!part.isDemoUser) {
                        this.onlyParticipantArr.push(part);
                    }
                });
            if (this.isFinalized && this.isParticipant && !this.isOwner)
              this.excludeParticpant();    
                this.EYParticipantaArr = data.Attendees;
            }
            this.getDummyUsers();
            var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("CPInfoDialog");
            var getSelfFacilitateNomination = this.velocityService.getSelfNomination(updateProgressBar);
            getSelfFacilitateNomination.subscribe((data) => {
                this.selfFacilitateNominations = data;
                this.selfGP = data;

                if (this.growthPlanDetailsArr.nominatedGrowthPlanId == '') {
                    this.ChangedGrowthPlandetails = false;
                    this.showGPbutton = true;
                }
                else {
                    this.ChangedGrowthPlandetails = true;
                    this.showGPbutton = false;
                }



                var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("CPInfoDialog");

                var gpDetails = this.velocityService.getCorporateGPNomination(this.growthPlanId, updateProgressBar);
                //var getselfNominations = this.velocityService.getSelfNomination(updateProgressBar);

                //forkJoin(gpDetails, getselfNominations)
                gpDetails.subscribe(
                (data) => {
                    if (data) {
                        this.growthPlanDetailsArr2 = data;
                    }
                    console.log(this.growthPlanDetailsArr2);
                    if (this.growthPlanDetailsArr2.nominatedGrowthPlanId) {
                        let selectedGPNameArr = this.selfGP.filter((x: any) => x.growthPlanId === this.growthPlanDetailsArr2.nominatedGrowthPlanId);
                        if (selectedGPNameArr.length > 0) {
                            this.selectedGPName = selectedGPNameArr[0].growthPlanName;
                            this.selectedGPId = selectedGPNameArr[0].growthPlanId;
                        }
                        this.selfGP = this.selfGP.filter((x: any) => x.growthPlanId !== this.growthPlanDetailsArr2.nominatedGrowthPlanId);
                        let name = "'" + this.selectedGPName + "'";
                        this.selectedMessage = this.PageContents.lblAlreadyNominatedMsg.replace('#name', name);
                    }
                    if (this.selfGP.length > 0) {
                        this.selectedGP = this.selfGP[0]["growthPlanId"];
                        if (this.selfGP[0].nominatedCount === 4) {
                            this.count4error = true;
                        }
                    }
                    this.isLoaded = true;
                    if (this.showNomination) {
                        setTimeout(() => {
                            let element = document.getElementById('createLinkId');
                            element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                        }, 0);



                        //document.getElementsByClassName('modal-body')[0].scrollTop = 100;
                    }
                });


            })
        });
  }
  excludeParticpant() {
    if (this.participantsArr.length > 0) {
      this.participantsArr = this.participantsArr.filter(x => x.isInvitationAccepted != null);
    }
  }
    checkCGPStatus() {
        if (this.growthPlanId) {
            this.velocityService.getGrowthPlan(this.growthPlanId).subscribe((data: any) => {
                this.isFacilitator = data.isFacilitator;
                this.isParticipant = data.isParticipant;
                this.isFinalized = data.isFinalized;
                this.isOwner = data.isOwner;
                this.isDelOwner = data.isDelegateOwner;
              this.isShared = data.isShared;
                this.isInvitationAccepted = data.isInvitationAccepted;
                this.isOwnerSelfStarted = data.isOwnerSelfStarted;
                this.isPracticeDemoMeeting = data.isPracticeDemoMeeting;
              if (data.growthPlanTypeId == 3 && this.isOwner)
                this.isInvitationAccepted = true;
                this.checkisAssessmentStarted = data.isAssessmentStarted;
                this.isParticipantCheck = data.isParticipant;
                if (!this.isParticipantCheck && this.isOwner) {
                    this.isParticipantCheck = this.isOwner;
                }
                this.isLocked = data.isLocked
              this.growthPlanTypeIdNominate = data.growthPlanTypeId;
                this.growthPlanTypeId = data.growthPlanTypeId;
              this.isLocked = data.isLocked;
              if (this.isFinalized && this.isParticipant && !this.isOwner)
                  this.excludeParticpant();
                //this.isFacilitator = data.isFacilitator;
                //// Meeting In Progress
                //if (data.isAssessmentStarted) {
                //    this.cgpInprogress = true;
                //    console.log("Meeting is In Progress");
                //}
                //// Meeting Completed
                //if (data.isLocked) {
                //    this.isCGpEditable = false;
                //    console.log("Meeting Completed");
                //}
                if (this.isShared) {
                    this.showGpDeleteButton = true;
                }
            });
        }
    }

    getIinitalsIcon(client: any) {
        if (client.name) {
            return this.utilService.getIinitals(client.name, '', true);
        }
        if (client.email) {
            return this.utilService.getIinitals(client.email, '', false);
        }
        return '';
    }
    goToDriverAssessment() {
        this.router.navigate(['focus', 'seven-drivers']);
    }

    showDropdown() {
        this.showGPselectbox = true;
        this.showGPbutton = false;
    }

    onChange(val) {

        if (this.selectedGP != undefined) {
            this.isalreadyNominatedGP = this.selfFacilitateNominations.filter(
                nomination => nomination.growthPlanId === val)[0].alreadyNominated;
            //if (this.ownerDetails.alreadyNominated == true) {
            //    this.showalreadyNominatedError = true;
            //    return;
            //}
            // else {
            this.showalreadyNominatedError = false;
            this.showunexpectedError = false;
            this.selectedGPvalue = JSON.parse(val);

            //}
        }
        else {
            this.selectedGPvalue = '';
            //this.ownerDetails.alreadyNominated = false;
        }
    }
    ChangeGrowthPlan() {

        if (this.isalreadyNominatedGP == true) {
            this.showalreadyNominatedError = true;
            this.showunexpectedError = false;
        }
        else {
            this.showalreadyNominatedError = false;
            let params;
            var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("corporateGpDetails");
            params = { nominatedGrowthPlanId: this.selectedGPvalue, growthplanId: this.growthPlanId, userId: -1 };
            this.velocityService.updateNominatedGrowthPlan(params, updateProgressBar).subscribe((data: any) => {
                if (data) {
                    if (data.result) {
                        this.checkCGPStatus();
                        this.getGPDetails();
                        this.showGPselectbox = false;
                        this.showGPbutton = false;
                        this.showunexpectedError = false;
                    } else {
                        this.showunexpectedError = true;
                    }
                }
                else {
                    this.showunexpectedError = true;
                }
            });
        }

    }
    hidechangeGPSection() {
        this.showGPselectbox = false;
        this.showGPbutton = true;
        this.showalreadyNominatedError = false;
        this.selectedGP = undefined;
        this.showunexpectedError = false;
    }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
        this.componentEvent.emit({ value: "closed" });
    }
    
    openDialog() {
        this.returnedValue = {};
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.coInfoDialogModal.config = this.config;
        this.coInfoDialogModal.show();
        return this.observable;
    }

    public closeDialog() {
        this.coInfoDialogModal.hide();
        this.subject.next(this.returnedValue);
        this.componentEvent.emit({ value: "closed" });
    }
    goToCorporatePage() {
        this.router.navigate(['focus', 'corporate-growthplan-info', this.growthPlanId]);
    }

    selectedOwner = "";
    clearOwnerSelection() {
        this.selectedOwner = "";
    }

    saveOwnerSelection() {
        let params = {
            "growthPlanId": this.growthPlanId,
            "ownerId": this.selectedOwner['userId']
        }
        let updateProgressBar: ProgressBarOptions = new ProgressBarOptions();
        this.velocityService.saveTeamMeetingOwner(params, updateProgressBar).subscribe((data: any) => {
            this.getGPDetails();
            const cOwner = { changeOwner: true };
            this.returnedValue = { ...this.returnedValue, ...cOwner };
        });
    }

    duplicateGrowthPlanSubmission() {
        this.errorresponseMessageData = null;
        if (this.duplicateGrowthPlanName.trim() != '' && this.formSubmission_SPECIALCHAR_IsValid == true) {
            this.errorGPEmptyDuplicate = false;
            this.duplicate_meetingDate = "";
            this.duplicate_facilitatorCode = "";
            this.duplicate_facilitatorFlow = 1;
            this.duplicate_memberId = null;
            this.duplicate_clientName = "";
            this.duplicate_clientEmail = "";
            this.duplicate_companyName = "";
            this.createDuplicateGrowthPlan();
        }
        else {
            if (this.duplicateGrowthPlanName.trim() == '') {
                this.formSubmission_GP_IsValid = false;
                this.errorGPEmptyDuplicate = true;
            }
            else if (this.formSubmission_SPECIALCHAR_IsValid == true) {
                this.errorGPEmptyDuplicate = true;
                this.errorMessageData = null;
            }
            else {
                this.errorMessageData = this.PageContents.lblGrowthAssessmentValidation;
                this.errorGPEmptyDuplicate = false;
            }
        }
    }

    public createDuplicateGrowthPlan() {
        var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("f2finfoduplicategrowthplan");
        let member = { "memberId": this.duplicate_memberId };
        let nonMember = { "clientName": this.duplicate_clientName, "clientEmail": this.duplicate_clientEmail, "companyName": this.duplicate_companyName }
        this.faceToFaceService.duplicateGrowthPlan(this.growthPlan, this.duplicateGrowthPlanName.trim(), this.growthPlanTypeId, this.duplicate_facilitatorCode, this.duplicate_meetingDate, this.duplicate_facilitatorFlow, member, nonMember, updateProgressBar).subscribe((response: DuplicateGrowthPlanData) => {
            if (response.growthPlanId) {
                this.returnedValue['growthPlanId'] = response.growthPlanId;
                const dGP = { isDuplicated: true }
                this.returnedValue = { ...this.returnedValue, ...dGP };
                this.closeDialog();
            } else {
                if (response.errorCode === '80001') {
                    this.errorresponseMessageData = this.PageContents.messageDuplicategrowthPlan;
                } else {
                    this.errorresponseMessageData = response.message;
                }
                setTimeout(() => {
                    const element = document.getElementById('errorresponseMessageDataID');
                    element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                }, 100);
            }
        });
    }

    public clearErrorMsg() {
        this.errorMessageData = null;
        this.errorGPEmptyDuplicate = false;
        let isValidGp = this.validateAlphanumericName(this.duplicateGrowthPlanName);
        if (isValidGp) {
            this.errorMessageData = null;
            this.formSubmission_SPECIALCHAR_IsValid = true;
        }
        else {
            if (this.duplicateGrowthPlanName.trim() != '') {
                this.errorMessageData = this.PageContents.lblGrowthAssessmentValidation;
                this.formSubmission_SPECIALCHAR_IsValid = false;
            }
            else {
                this.errorMessageData = null;
                this.formSubmission_SPECIALCHAR_IsValid = true;
            }
        }
    }

    validateAlphanumericName(planName) {
        let pattern = /^([a-zA-Z0-9 .\-'()[\]_]+)$/;
        if (pattern.test(planName)) { return true; } else { return false; }
    }

    showDuplicateBtn() {
        if (this.typeId == 1 && this.isOwner && this.isFinalized && (this.growthPlanTypeId == 5 || this.growthPlanTypeId == 3))
            return true;
        return false;
    }

    showMangeBtn() {
        if (this.isOwner || this.isDelOwner || (this.growthPlanTypeId == 5 && this.isFacilitator && !this.isParticipant && !this.isFinalized))
            return true;
        return false;
    }

    deleteGP() {
        this.showGpInfoDialog = false;
        this.showGpDelete = true;
        this.errorMsgDelete = false;
    }
    cancelDeleteGP() {
        if (this.typeId == 3) {
            this.closeDialog();
        }
        else {
            this.showGpDelete = false;
            this.showGpInfoDialog = true;
            this.getGPDetails();
        }
    }
    deleteGrowthPlan() {
        console.log("################## inside delete GP#############################");
        let params;
        let updateProgressBar: ProgressBarOptions = new ProgressBarOptions();
        params = { growthPlanId: this.growthPlanId }
        this.velocityService.deleteGrowthPlan(params, updateProgressBar).subscribe((data: any) => {
            if (data === 1) {
                console.log("inside api success");
                this.setGrowthPlan();
                const dGP = { isDeleted: true }
                this.returnedValue = { ...this.returnedValue, ...dGP };

                let log = new LogAction();
                log.action = this.logTypes.GROWTH_PLAN_DELETED;
                log.growthPlanId = this.growthPlanId;
                this.logService.logAction(log);

                this.closeDialog();
                // Need to relaod current route 
            }
            else if (data === 2) {
                console.log("inside deleteGrowthPlan api fails");
                this.errorMsgDelete = true;
            }
            else {
                console.log("inside deleteGrowthPlan api fails for 0 ");
            }
        });
    }

    private setGrowthPlan() {
        console.log('this.growthPlanId-' + this.growthPlanId);
        console.log('this.currentGrowthPlan.growthPlanId-' + this.currentGrowthPlan.growthPlanId);
        if (this.growthPlanId === this.currentGrowthPlan.growthPlanId) {
            this.storageHelper.ClearSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
            return this.faceToFaceService.getSessionGrowthPlanData().subscribe();
        }
    }

    duplicateGP() {
        // When clicked duplicate  Button
        this.showGpDuplicate = true;
        this.showGpInfoDialog = false;

        this.checkForDuplicate();
    }

    acceptDecline(btnType) {
        console.log(this.router.url);
        let param = {
            growthPlanId: this.growthPlanId,
            userId: -1,
            actionType: btnType == 'accept' ? 1 : 2
        }
        this.velocityService.acceptDecline(param).subscribe((result) => {
            this.showFlash(btnType);
            
            //if (btnType == 'declined') {
            //    let currentgp:any = this.storageHelper.GetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
            //    if (currentgp.growthPlanId == this.growthPlanId) {
            //        this.setGrowthPlan();
            //        if (this.router.url != '/dashboard' && this.router.url != '/focus/seven-drivers') {
            //            this.router.navigateByUrl('/dashboard');
            //        } else {
            //            //reload page
            //            console.log('ffff111');
            //            let routeArray = this.router.url.split('/');
            //            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            //                this.router.navigate(routeArray)
            //            );
            //        }
            //    }
            //}
            this.dataShareService.actionedGP.next(true);
        });
    }

    showMessage: boolean = false;
    flashType: string = '';
    btnType: string = '';
    showFlash(btnType: string) {
        this.showMessage = true;
        this.flashType = btnType;
        this.btnType = btnType;
    }

    showHideChange(e) {
        this.showMessage = e;
        if (this.btnType != 'accept') {
            this.coInfoDialogModal.hide();
            this.isInvitationAccepted = false;
            this.subject.next({ 'gid': this.growthPlanId,isDeclined:true });
            this.componentEvent.emit({ value: "closed" });

            let currentgp: any = this.storageHelper.GetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
            if (currentgp.growthPlanId == this.growthPlanId) {
                this.setGrowthPlan();
                if (this.router.url != '/dashboard' && this.router.url != '/focus/seven-drivers') {
                    //if (this.router.url.includes('/seven-drivers/')) {
                    //    this.router.navigateByUrl('/focus/seven-drivers');
                    //} else {
                        this.router.navigateByUrl('/dashboard');
                    //}
                    
                } else {
                    //reload page
                    let routeArray = this.router.url.split('/');
                    if (this.router.url == '/dashboard') {
                        this.router.navigateByUrl('/focus/seven-drivers', { skipLocationChange: true }).then(() =>
                            this.router.navigate(routeArray)
                        );
                    } else {
                        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                            this.router.navigate(routeArray)
                        );
                    }
                }
            }
        } else {
            this.isInvitationAccepted = true;
            //this.showNomination = true;
            this.subject.next({ 'gid': this.growthPlanId, isAccepted: true });
        }

    }

    //getFacDetails(facDetails: any) {
    //    this.facDetails = facDetails;
    //    this.activeStep++;
    //}
    //getAttDetails(attDetails: any) {
    //    this.attendeeInfo = attDetails;
    //    this.activeStep++;
    //}
    //getSelDetails(selDetails: any) {
    //    this.selMember = selDetails;
    //    this.activeStep++;
    //}
    //getConfirmDetails(getConfirmDetails: any) {

    // }
    //back(value: any) {
    //    this.activeStep--;
    //}

    //reNameGp() {
    //    this.gpNameSectionEdit = true;
    //    this.gpTitle = this.growthPlanDetailsArr.growthPlanName;
    //    this.errorMessageRename = false;
    //    this.errorGPEmpty = false;
    //}

    //cancelRenameGp() {
    //    this.errorMessageRename = false;
    //    this.errorGPEmpty = false;
    //    this.gpNameSectionEdit = false;
    //}

    //private setRenamedGrowthPlan() {
    //    if (this.growthPlanId === this.currentGrowthPlan.growthPlanId) {
    //        this.currentGrowthPlan.growthPlanName = this.gpTitle;
    //        this.storageHelper.SetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN, this.currentGrowthPlan);
    //        this.dataShareService.activePlan.next(true);
    //    }
    //}

    //onKeyUPTitle() {
    //    if (this.gpTitle.trim() != '') {
    //        let params;
    //        let updateProgressBar: ProgressBarOptions = new ProgressBarOptions();
    //        params = { growthPlanId: this.growthPlanId, growthPlanName: this.gpTitle.trim() }
    //        this.velocityService.renameGrowthPlan(params, updateProgressBar).subscribe((data: any) => {
    //            if (data) {
    //                if (data.result) {
    //                    this.setRenamedGrowthPlan();
    //                    this.cancelRenameGp();
    //                    this.getGPDetails();
    //                }
    //                else {
    //                    if (data.errorCode === 3003) {
    //                        //Duplicate growth plan. The same growthplan already exists
    //                        this.errorMessageRename = true;
    //                    }
    //                    // Else scenaario Need to handle Here
    //                }
    //            }
    //        });
    //    } else {
    //        this.errorGPEmpty = true;
    //    }
    //}

    //clearError() {
    //    this.errorGPEmpty = false;
    //    this.errorMessageRename = false;
    //}
    
    selectGP(selectedGP) {
        this.count4error = false;
        this.errorExist = false;
        const selGP = this.selfGP.find(x => x.growthPlanId === selectedGP);
        if (selGP.nominatedCount === 4) {
            this.count4error = true;
        }

    }
    cancelGpName() {
        this.gpTitle = '';
        this.showNomination = false;
        this.showSelectGP = true;
        this.errorGPEmpty = false;
        this.errorMessageRename = false;
        this.errorExist = false;
        this.count4error = false;
        if (this.selfGP.length > 0) {
            if (this.growthPlanDetailsArr.nominatedGrowthPlanId) {

                this.selectedGP = this.growthPlanDetailsArr.nominatedGrowthPlanId;
            } else
                this.selectedGP = this.selfGP[0]["growthPlanId"];
        }

    }

    clearError() {
        this.errorGPEmpty = false;
        this.errorMessageRename = false;
        this.errorExist = false;
        this.count4error = false;
    }

    getContent() {
        if (this.growthPlanDetailsArr.isAssessmentStarted) {
            return this.PageContents.lblChangeGrowthplanTooltip;
        } else {
            return '';
        }
    }

    onKeyValidate(event) {
        //if (this.value.match(/[^a-zA-Z0-9 ]/g)) 
        var regex = new RegExp(/^[\w\-\s]+$/);
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        } else {
            return true;
        }
    }

    saveGrowthPlan(type: number) {
        this.count4error = false;
        if (type == 2 && this.gpTitle == '') {
            this.errorGPEmpty = true;
            return;
        }
        if (type == 1 && !this.selectedGP)
            return;

        var params = {
            selfGrowthPlanId: 0,
            growthPlanName: '',
            corporateGrowthPlanId: this.growthPlanId
        };
        if (type == 2) {
            params.growthPlanName = this.gpTitle;
        } else {
            params.selfGrowthPlanId = this.selectedGP;
        }
        this.velocityService.saveNomination(params).subscribe((data) => {
            if (data) {
                if (data.growthPlanId) {
                    this.setActiveGrowthPlan(data.growthPlanId);
                this.dataShareService.actionedGP.next(true);
                    this.returnedValue = "yes";
                    //this.closeDialog();
                    let name = '';
                    if (this.gpTitle != '' && this.gpTitle != undefined) {
                        name = this.gpTitle;
                    } else {
                        this.selfGP.forEach((gp) => {
                            if (gp.growthPlanId == this.selectedGP) {
                                name = gp.growthPlanName;
                                this.selectedGPId = gp.growthPlanId;

                            }
                        })
                    }

                    var updateProgressBar: ProgressBarOptions = ProgressBarHelper.newUpdateProgressOption("CPInfoDialog");
                    var getSelfFacilitateNomination = this.velocityService.getSelfNomination(updateProgressBar);
                    getSelfFacilitateNomination.subscribe((data) => {
                        this.selfGP = data;
                    });
                    this.selectedGPName = name;
                    this.showNomination = false;
                    this.showSelectGP = true;
                    this.growthPlanDetailsArr2.nominatedGrowthPlanId = data.growthPlanId;
                    this.subject.next({ 'gid': this.growthPlanId, isNominated: true, nominatedId: data.growthPlanId, growthPlanName: name });
                    this.gpTitle = undefined;
                }
                else if (data.message == 'duplicate_growthplan_name') {
                    this.errorMessageRename = true;
                    this.errorMsg = this.PageContents.lblPlanAlreadyExists;
                } else if (data.message == 'growthplan_already_nominated') {
                    this.errorExist = true;
                }
                else {
                    this.returnedValue = "error";
                    this.closeDialog();
                }
            }
        });
    }

    showNominate() {
        if (this.isOwnerSelfStarted && this.isOwner) {
            let config: any = {
                type: 'LEAVE_COMMUNITY',
                action: {
                    cancel: this.PageContents.buttonCancel,
                    ok: this.SiteContents.buttonOk,
                    or: this.PageContents.or
                }
            };
            this.dialogService.confirm(
                '',
                this.PageContents.msgConfirmOverwriteSelfScore,
                config).subscribe(
                    (data) => {
                        if (data && data === 'yes') {
                            this.showNomination = true;
                            setTimeout(() => {
                                let element = document.getElementById('createLinkId');
                                element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                            }, 0);
                        }
                    });
        } else {
            this.showNomination = true;
            setTimeout(() => {
                let element = document.getElementById('createLinkId');
                element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
            }, 0);
        }
    }

    changeGP() {
        this.showNomination = true
        setTimeout(() => {
            let element = document.getElementById('createLinkId');
            element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
        }, 0);
    }


    checkForDuplicate() {
        this.isLoading = true;
        this.velocityService.getAxisValuesForGrowthPlan(this.growthPlanId                                                                                                          ).subscribe(
            (data: any) => {
                if (data) {
                    this.hasAxisDifference = data.isAxisValueUpdated;
                    this.axesDifferenceModalContent = this.otherContents.lblDuplicateGPAxisWarning
                                                        .replace("#Risk", data.xAxisLabel)
                                                        .replace("#Impact", data.yAxisLabel);
                    this.isLoading = false;
                }
            }
        );
    }

    setActiveGrowthPlan(growthPlanId) {
        const param = {
            growthPlanId: growthPlanId
        }
        this.velocityService.setGrowthPlanId(param).subscribe((result) => {
            if (result) {
                this.velocityService.getGrowthPlanDetails(growthPlanId).subscribe((data) => {                   
                    this.setGrowthPlanSessionStorageData(data.GrowthPlanDetails, growthPlanId);
                    if (this.router.url != '/dashboard' && this.router.url != '/focus/seven-drivers' && this.router.url != '/focus/view-all-growthplans') {
                        this.router.navigateByUrl('/focus/seven-drivers');
                    } else {
                        this.dataShareService.changeActiveGP.next(true);
                    }
                });
            }
        });
    }
    private setGrowthPlanSessionStorageData(data: GrowthPlanListDto, growthPlanId) {
        const sessionStorageData: GrowthPlanSessionDto = {
            growthPlanId: growthPlanId,
            growthPlanName: data.growthPlanName,//will be working on to remove from session
            growthPlanTypeId: data.growthPlanTypeId,
            isLocked: data.isLocked,
            isShared: data.isShared
        };
        this.storageHelper.SetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN, sessionStorageData);
        this.storageHelper.SetLocalGrowthPlan(this.growthPlan);
    }
    activeGP() {
        if (this.router.url != '/focus/seven-drivers') {
            const param = {
                growthPlanId: this.selectedGPId
            }
            this.velocityService.setGrowthPlanId(param).subscribe((result) => {


                this.routingToSevenDriver();
            });
        } else {
            this.setActiveGrowthPlan(this.selectedGPId) ;
        }
        
    }
    routingToSevenDriver() {
        const log = new LogAction();
        log.action = this.logTypes.NAVIGATION;
        log.otherInfo = 'Navigated to seven-drivers';
        this.logService.logAction(log);
        this.storageHelper.ClearSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
        this.router.navigate(['focus', 'seven-drivers']);
    }
}


