///*import { Component, Input, ViewChild, Injector, Output, EventEmitter, NgZone } from '@angular/core';
import { Component, Input, OnInit, AfterViewInit, Injector, EventEmitter, NgZone, Output, ElementRef, Renderer2, ViewChild, OnChanges, SimpleChanges, SimpleChange, HostListener, ViewEncapsulation } from '@angular/core';

//import { ModalModule, Modal } from 'ng2-modal';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from './../../component/base.component';


import { Observable, of } from 'rxjs';
import { Subject } from 'rxjs';
import { SaveGrowthPlanActionPrioritize, SavePrioritizeActionsModel, ParticipantPrioritizeModel } from '../../focus/model/save-gp-action-prioritize.model';
import { ComponentContent } from '../../component/component-content.decorator';
import { GrowthService } from '../../service/growth.service';
import { VelocityService } from '../../service/velocity.service';
import { CONTENTS } from '../../config/content.const';
//import { ModalDialogService } from '../../modal-dialog-module/modal-dialog.service';
//import { TemplateTypes } from '../../modal-dialog-module/template-types.enum';
import { ProgressBarOptions, ProgressBarHelper } from '../../progress-bar-module/progress-bar-options';
import { UtilService } from '../../service/util.service';
import { LogService } from "../../service/log.service";
import { LogTypes, LogAction } from "../../service/model/log-models";
import { Constants } from "../../service/constant.service";
import { DragDataService } from '../../component/drag-drop/service/drag-data.service';
import { StorageHelperService } from '../../service/storage-helper.service';
import { GrowthPlanDetails } from '../../component/growth-plan-details.decorator';
import { Subscription } from 'rxjs';
import { DataShareService } from '../../service/data-share.service';
import { GrowthPlanListDto } from '../../focus/model/face-to-face.model';

@ComponentContent(CONTENTS.MY_GROWTH_PLAN)
@Component({
    //moduleId: module.id.toString(),
    selector: "zoom-matrix-dialog",
  templateUrl: './zoom-2x2-matrix.html',
    styleUrls: ['./zoom-2x2-matrix.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ZooomDialogComponent extends BaseComponent implements OnInit, AfterViewInit {
    customize: any = {};
    observable: Observable<any>;
    subject: any;
    @Output("closeEvent") componentEvent: any = new EventEmitter();
    currentGrowthPlan: any;
    currentGrowthPlanId: number;
    currentGrowthPlanName: string = '';
    // variable to check the current growth plan is locked or not
    isLocked: boolean = false;
    self: boolean = true;

    tagLine: string = "";

    driverId: string = '';
    // driverReference: string;

    showBubble: boolean = false;

    pageContents: any;
    needHelpText: string = "";
    continueButtonText: string = "";

    breadcrumbRoot: string = "";
    breadcrumbItems: any[] = [];
    showBreadcrumb: boolean = false;
    actionCards: any = [];
    //corporateActionCards: any = {};
    //actionReferance: any = [];
    actionId: any = [];
    growthActions: any = [];
    drivers: any = [];
    selectedType: number = 1;
    //selectedReferance: string = "";
    selectedId: string = "";
    selBoxType: string = '';
    actionCardAppcontent: any;

    dropEnabled: boolean = true;
    isExpanded: boolean = false;

    // isLeaving: boolean = false;
    // isChanged: boolean = false;
    isCorporate: boolean = false;
    isAuthOwner: boolean = false;

    CorporateSelfGrowthPlanId: number;
    partcipantQuadrantWidth: any;
    newAreaWidth: number;
    //For Animation
  @ViewChild('Quadrant1', { static: true }) quadrant: ElementRef;
  @ViewChild('PQuadrant1', { static: false }) PQuadrant1: ElementRef;
  @ViewChild('quadrantArea1', { static: true }) quadrantArea: ElementRef;
  @ViewChild('accordion1', { static: true }) accordionGroup: ElementRef;
  @ViewChild('mainContainer1', { static: true }) mainContainer: ElementRef;
  @ViewChild('actionArea1', { static: true }) actionArea1: ElementRef;
  @ViewChild('participantArea1', { static: false }) participantArea: ElementRef;
  @ViewChild('quadrantContainer1', { static: true }) quadrantContainer: ElementRef;

    actionAreaWidth: number;

    //@ViewChild('extraArea') extraArea: ElementRef;

    listCardPos = "";
    state = 'small';
    className = "";

    prioritizedActions: any = [];
    initialActions: any = [];
    actionsToBePrioritized: any = [];
    selectedPrioCorpActions = [];

    updateProgressBar: ProgressBarOptions;
    growthPlanId: number;
    currentExpandedQuadrant: number = -1;
    draggedFrom = 0;

    mouseOffset: any = {};
    selectedCard: any = {};
    highLightBubbles: string = "";
    quadrantHeight: number;
    quadrantAreaWidth: number;
    quadrantWidth: number;
    headerWidth: number;
    calQuadrantHeight: number;
    partcipantWidth: number;

    //extraSpaceMargin: number;
    showDeleteActionalert: boolean;
    IsDraftReportAvailable: false;
    IsDriverAssessed: false;

    shouldShowNavigationToGrowthSummaryPage: boolean = true;
    isPlotted = false;
    isMobile = false;
    growthPlanSessionContent: any = {};
    currencySymbol: string;
    selectedCardNumber: any;
    selectedTitle: any;
    curentActionCard: any;
    topPartPos = '';
    selectedFilterDriver = 0;
    showAll: boolean = false;

    private activeGPSubscription: Subscription;
    public configSet = [
        {
            className: 'lable-text',
            newFont: '12px',
            oldFont: '20px'
        }
    ];
    newPrioritizedActions = {};
    prioritizedActionsKeys = [];
    parentClass: string = '';
    countBubbleSelected: any = {};
    countBubbleHighlight: any = {};
    hideShowTooltip: boolean = false;
    changeFilter: boolean = false;
    xLabel:string = '';
    yLabel:string = '';
    constructor(private injector: Injector,
        private zone: NgZone,
        private router: Router,
        private route: ActivatedRoute,
        private growthService: GrowthService,
        private velocityService: VelocityService,
        // private dialogService: ModalDialogService,
        private utilService: UtilService,
        private logService: LogService,
        private logTypes: LogTypes,
        public constants: Constants,
        private dragDataService: DragDataService,
        private storageHelper: StorageHelperService,
        private dataShareService: DataShareService) {

        super(injector);
        this.customize = this.injector.get('config');
        this.PageContents = this.injector.get('PageContents');
        this.OtherContents = this.injector.get('OtherContents');
        this.selectedCard = this.injector.get('selectedCard');
        this.xLabel = this.injector.get("xLabel");
        this.yLabel = this.injector.get("yLabel");

        //Get current growthPlan from session storage
        this.currentGrowthPlan = this.storageHelper.GetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
        this.currentGrowthPlanId = this.currentGrowthPlan.growthPlanId;
        this.growthPlanId = this.currentGrowthPlanId;
        this.currentGrowthPlanName = this.currentGrowthPlan.growthPlanName;
        //this.selectedCard = {};

    }

    @ViewChild('zoomModal', { static:true }) zoomModal: any;
    returnedValue: any = {};

    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };



    onModalClosed($event: any) {
        this.returnedValue = this.selectedCard;

        this.subject.next(this.returnedValue);
        this.componentEvent.emit({ value: "closed" });
    }

    cancelClicked() {
        this.returnedValue = false;
        this.closeDialog();
    }

    okayClicked() {
        this.returnedValue = true;
        this.closeDialog();
    }

    openDialog() {
        this.returnedValue = false;
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.zoomModal.config = this.config;
        this.zoomModal.show();
        return this.observable;
    }

    private closeDialog() {

        this.zoomModal.hide();
        return of(this.returnedValue);
    }
    goToSummaryPage() {
        this.router.navigate(["act", "summary"]);
    }

    closeBtn() {
        this.closeDialog();

        //if (this.customize.close && this.customize.close == 'ok') {
        //    this.okayClicked();
        //} else {
        //    this.closeDialog();
        //}
    }
    @HostListener('window:resize') onResize() {

        this.resizeLayout();

        //var innerHeight = window.innerHeight;
        //this.quadrantHeight = innerHeight - 300;
        //this.quadrantAreaWidth = innerHeight - 320;

        //this.quadrantWidth = innerHeight - 320;
        var me = this;
        setTimeout(function () {
            //if (me.state != 'small' && me.selBoxType != '')
            //    me.expandQuadrant(me.selBoxType);
            me.setBubblePositions();
        }, 100);

        //setTimeout(() => {
        //    this.calQuadrantHeight = this.quadrant.nativeElement.offsetHeight;
        //});
        //setTimeout(function () {

        //    me.headerWidth = me.quadrantArea.nativeElement.offsetWidth + me.actionArea.nativeElement.offsetWidth;
        //    //me.extraSpaceMargin = me.extraArea.nativeElement.offsetWidth;
        //});


    }

    setBubblePositions() {
        var offSet = this.utilService.getOffset(this.quadrant.nativeElement.getElementsByClassName("first-box")[0]);
        var bubbleSize = this.getBubbleSize();
        var bubbleWidth = bubbleSize.width;
        var bubbleHeight = bubbleSize.height;
        for (let action of this.prioritizedActions) {
            action.xPos = action.xRatio * offSet.width / 100;
            action.yPos = action.yRatio * offSet.height / 100;
            if (action.xPos + bubbleWidth > (offSet.width - 1)) {
                action.xPos = offSet.width - bubbleWidth - 1;
            }


            if (action.yPos + bubbleHeight > (offSet.height - 1)) {
                action.yPos = offSet.height - bubbleHeight - 1;
            }
        }
        this.addCountBubble('');
    }
    detectMobile() {
        if (/mobile/i.test(navigator.userAgent) && !/ipad|tablet/i.test(navigator.userAgent)) {
            this.isMobile = true;
            return;
        }
        if (window.innerWidth <= 800 && window.innerHeight <= 600) {
            this.isMobile = true;
        }
    }
    ngOnInit() {
        this.detectMobile();
        this.growthPlanSessionContent = this.OtherContents[CONTENTS.GROWTH_PLAN_SESSION] || {};
        this.showDeleteActionalert = false;
        var appContent = this.OtherContents.actionDetails;
        this.actionCardAppcontent = { buttonCancel: this.SiteContents.buttonCancel, buttonDonePopup: this.SiteContents.buttonDonePopup, labelSelectActionStatus: this.PageContents.labelSelectActionStatus, labelCost: this.PageContents.labelEstimatedActionCost, labelOwner: this.PageContents.lblActionOwner, labelStartDate: this.PageContents.lblActionStartDate, labelCompletionDate: this.PageContents.lblActionCompletionDate }

        this.updateProgressBar = ProgressBarHelper.newUpdateProgressOption("mygrowthplan1");
        this.actionId = [];
        this.pageContents = this.PageContents;

        this.continueButtonText = this.SiteContents.buttonContinue;
        this.breadcrumbRoot = this.SiteContents.breadCrumpRoot;
        this.driverId = this.route.snapshot.paramMap.get('id');


        this.breadcrumbItems = [];
        if (this.driverId) {
            this.breadcrumbItems.push({ Text: this.pageContents.lblAct, Link: "", IsActive: true });
            this.breadcrumbItems.push({ Text: this.currentGrowthPlanName, Link: "../../../focus/seven-drivers", IsActive: false });
        }
        else {
            this.breadcrumbItems.push({ Text: this.pageContents.lblAct, Link: "", IsActive: true });
            this.breadcrumbItems.push({ Text: this.currentGrowthPlanName, Link: "../../focus/seven-drivers", IsActive: false });
        }
        //this.breadcrumbItems.push({ Text: this.currentGrowthPlanName, Link: "../../focus/seven-drivers", IsActive: false });
        this.breadcrumbItems.push({ Text: this.pageContents.title, IsActive: true });
        this.showBreadcrumb = true;

        // To get the action cards          
        this.getActionContents();
        this.resizeLayout();

        this.activeGPSubscription = this.dataShareService.callActivePlan.subscribe((res) => {
            if (res) {
                const sessionGrowthplan: GrowthPlanListDto = this.storageHelper.GetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
                if (sessionGrowthplan && sessionGrowthplan.growthPlanName) {
                    this.breadcrumbItems.splice(1, 1, { Text: sessionGrowthplan.growthPlanName, Link: "", IsActive: true });
                }
            }
        });

        let result = document.getElementsByClassName("participant-area");
        //document.getElementById('actCardListCont').offsetHeight;
        if (result.length) {
            this.parentClass = 'team-view';
        }

    }

    getActionContents() {
        let main = this;
        //To get the driver name & id
        this.growthService.getHotTopicFilters(this.updateProgressBar).subscribe(
            (data: any) => {
                main.drivers = data;
                main.getActionCards();
                if (main.driverId) {
                    main.OpenActionListOfDriver();
                }
            });




    }

    goToReport() {
        if (this.shouldShowNavigationToGrowthSummaryPage) {
            var isFromGrowthPlan = true;
            this.router.navigate(['act/summary/' + isFromGrowthPlan]);
        }
    }

    getDate(date: string) {
        let formattedDate: any;
        if (date) {
            var selDate = new Date(date);
            formattedDate = { date: { year: selDate.getFullYear(), month: selDate.getMonth() + 1, day: selDate.getDate() } };
            return formattedDate;
        }
        return "";
    }

    getActionCards() {

        //
        this.isPlotted = false;
        //

        let driverScored;

        this.velocityService.getGrowthPlanActions(this.currentGrowthPlanId, this.updateProgressBar).subscribe(
            (data) => {
                if (data && data.growthPlanStatus) {
                    if (data.growthPlanStatus.IsCorporate) {
                        this.isCorporate = true;
                        if (data.growthPlanStatus.growthPlanTypeId != 5) {
                            if (data.growthPlanStatus.IsCorporateOwner && !data.growthPlanStatus.IsDelegated) {
                                this.isAuthOwner = true;
                                this.self = false;
                                this.isLocked = false;

                            } else if (data.growthPlanStatus.IsCorporateOwner && data.growthPlanStatus.IsDelegated) {
                                this.self = false;
                                this.isLocked = true;
                            }
                            else if (data.growthPlanStatus.IsDelegated && data.growthPlanStatus.IsDeligatedOwner) {
                                this.self = false;
                                this.isAuthOwner = true;
                                this.isLocked = false;
                            } else {
                                this.self = false;
                                this.isLocked = true;
                            }
                        }

                        if (data.growthPlanStatus.growthPlanTypeId == 5) {
                            if (data.growthPlanStatus.IsFacilitator || data.growthPlanStatus.IsOwner) {
                                this.isAuthOwner = true;
                                this.self = false;
                                this.isLocked = false;
                            }
                            else {
                                this.self = false;
                                this.isLocked = true;
                            }
                        }
                        this.CorporateSelfGrowthPlanId = data.growthPlanStatus.CorporateSelfGrowthPlanId;
                    }

                    // Set the pages are read only for locked/shared growth plan
                    if (data.growthPlanStatus.IsLocked || data.growthPlanStatus.IsShared) {
                        this.isLocked = true;
                    }

                    if (this.isCorporate && !this.self) {
                        setTimeout(() => {
                            if (window.innerWidth <= 1399 && window.innerHeight <= 650) {
                                this.partcipantWidth = this.quadrantContainer.nativeElement.offsetWidth - this.quadrantArea.nativeElement.offsetWidth - this.actionArea1.nativeElement.offsetWidth;
                            }
                            this.setBubblePositions();
                        }, 100);
                    }
                }

                driverScored = data.driverScored;

                this.currencySymbol = data.currencySymbol;
                if (this.currencySymbol == undefined || this.currencySymbol == '') {
                    this.currencySymbol = "$";
                }

                this.IsDraftReportAvailable = data.IsDraftReportAvailable;
                this.IsDriverAssessed = data.IsDriverAssessed;

                let growthActionDetails;
                let customActionDetails;
                let corporateGrowthActionDetails;
                let corporateCustomActionDetails;
                if (this.isCorporate && this.self) {
                    growthActionDetails = data.corporateGrowthActions;
                    customActionDetails = data.corporateCustomActions;
                }
                if (this.isCorporate && !this.self) {
                    corporateGrowthActionDetails = data.growthActions;
                    corporateCustomActionDetails = data.customActions;
                }

                if (!this.isCorporate) {
                    growthActionDetails = data.growthActions;
                    customActionDetails = data.customActions;
                }

                if (driverScored.length > 0) {
                    if (customActionDetails != undefined) {
                        //for (let actions of data.customActions) {
                        for (let actions of customActionDetails) {
                            // this.growthPlanId = actions.growthPlanId;
                            if (driverScored.indexOf(actions.growthDriverId) > -1) {
                                //if (!this.actionCards[actions.growthDriverId]) {
                                //    this.actionCards[actions.growthDriverId] = [];
                                //}
                                this.actionCards.push({
                                    id: actions.id, cardNumber: actions.cardNumber, title: actions.title, customTitle: actions.customTitle, driverTag: actions.growthDriverId, cardId: actions.cardId, xPos: actions.xPos, yPos: actions.yPos, quadrant: actions.actionPriority, isSelected: actions.IsSelected, cardType: 'Custom', customActionId: actions.customActionId, estCost: actions.estCost, rag: actions.rag, ownerName: actions.ownerName, startDate: this.getDate(actions.startDate), completionDate: this.getDate(actions.completionDate)
                                });

                                if (actions.xPos != -1 || actions.IsSelected == false) {
                                    this.prioritizedActions.push({
                                        id: actions.id, cardNumber: actions.cardNumber, title: actions.title, customTitle: actions.customTitle, driverTag: actions.growthDriverId, cardId: actions.cardId, xPos: this.calculateActualXPos(actions.xPos), xRatio: actions.xPos, yPos: this.calculateActualYPos(actions.yPos), yRatio: actions.yPos, quadrant: actions.actionPriority, isSelected: actions.IsSelected, cardType: 'Custom', customActionId: actions.customActionId, estCost: actions.estCost, rag: actions.rag, ownerName: actions.ownerName, startDate: this.getDate(actions.startDate), completionDate: this.getDate(actions.completionDate), modifiedDateTime: actions.modifiedDateTime
                                    });
                                }

                            }
                        }
                    }
                    if (growthActionDetails != undefined) {
                        //  this.setActions(true);
                        //this.growthActions = data.growthActions;
                        //this.growthActions = growthActionDetails;
                        for (let actions of growthActionDetails) {
                            //this.growthPlanId = actions.growthPlanId;
                            //if (!this.actionCards[actions.driverId]) {
                            //    this.actionCards[actions.driverId] = [];
                            //}
                            this.actionCards.push({
                                id: actions.id, cardNumber: actions.driverActionId, title: actions.bodyFormatted, customTitle: actions.customTitle, driverTag: actions.driverId, cardId: actions.cardId, xPos: actions.xPos, yPos: actions.yPos, quadrant: actions.priorityId, isSelected: actions.IsSelected, cardType: 'GN', customActionId: actions.customActionId, estCost: actions.estCost, rag: actions.rag, ownerName: actions.ownerName, startDate: this.getDate(actions.startDate), completionDate: this.getDate(actions.completionDate)
                            });

                            if (actions.xPos != -1 || actions.IsSelected == false) {
                                this.prioritizedActions.push({
                                    id: actions.id, cardNumber: actions.driverActionId, title: actions.bodyFormatted, customTitle: actions.customTitle, driverTag: actions.driverId, cardId: actions.cardId, xPos: this.calculateActualXPos(actions.xPos), xRatio: actions.xPos, yPos: this.calculateActualYPos(actions.yPos), yRatio: actions.yPos, quadrant: actions.priorityId, isSelected: actions.IsSelected, cardType: 'GN', customActionId: actions.customActionId, estCost: actions.estCost, rag: actions.rag, ownerName: actions.ownerName, startDate: this.getDate(actions.startDate), completionDate: this.getDate(actions.completionDate), modifiedDateTime: actions.modifiedDateTime
                                });
                            }
                        }
                    }

                    if (corporateCustomActionDetails != undefined) {
                        for (let actions of corporateCustomActionDetails) {
                            //this.growthPlanId = actions.growthPlanId;
                            if (driverScored.indexOf(actions.growthDriverId) > -1) {
                                //if (!this.actionCards[actions.growthDriverId]) {
                                //    this.actionCards[actions.growthDriverId] = [];
                                //}
                                this.actionCards.push({
                                    id: actions.id, cardNumber: actions.cardNumber, title: actions.title, customTitle: actions.customTitle, driverTag: actions.growthDriverId, cardId: actions.cardId, xPos: actions.xPos, yPos: actions.yPos, quadrant: actions.actionPriority, isSelected: actions.IsSelected, cardType: 'Custom', customActionId: actions.customActionId, estCost: actions.estCost, rag: actions.rag, ownerName: actions.ownerName, startDate: this.getDate(actions.startDate), completionDate: this.getDate(actions.completionDate)
                                });

                                if (actions.xPos != -1 || actions.IsSelected == false) {
                                    if (this.isCorporate && !this.self) {
                                        this.prioritizedActions.push({
                                            id: actions.id, cardNumber: actions.cardNumber, title: actions.title, customTitle: actions.customTitle, driverTag: actions.growthDriverId, cardId: actions.cardId, xPos: this.calculateActualXPos(actions.xPos), xRatio: actions.xPos, yPos: this.calculateActualYPos(actions.yPos), yRatio: actions.yPos, quadrant: actions.actionPriority, isSelected: actions.IsSelected, cardType: 'Custom', customActionId: actions.customActionId, estCost: actions.estCost, rag: actions.rag, ownerName: actions.ownerName, startDate: this.getDate(actions.startDate), completionDate: this.getDate(actions.completionDate), modifiedDateTime: actions.modifiedDateTime
                                        });
                                    }
                                }

                            }
                        }
                    }
                    if (corporateGrowthActionDetails != undefined) {
                        //  this.setActions(true);
                        //this.growthActions = data.growthActions;
                        //this.growthActions = corporateGrowthActionDetails;
                        for (let actions of corporateGrowthActionDetails) {
                            //this.growthPlanId = actions.growthPlanId;
                            //if (!this.actionCards[actions.driverId]) {
                            //    this.actionCards[actions.driverId] = [];
                            //}
                            this.actionCards.push({
                                id: actions.id, cardNumber: actions.driverActionId, title: actions.bodyFormatted, customTitle: actions.customTitle, driverTag: actions.driverId, cardId: actions.cardId, xPos: actions.xPos, yPos: actions.yPos, quadrant: actions.priorityId, isSelected: actions.IsSelected, cardType: 'GN', customActionId: actions.customActionId, estCost: actions.estCost, rag: actions.rag, ownerName: actions.ownerName, startDate: this.getDate(actions.startDate), completionDate: this.getDate(actions.completionDate)
                            });

                            if (actions.xPos != -1 || actions.IsSelected == false) {
                                if (this.isCorporate && !this.self) {
                                    this.prioritizedActions.push({
                                        id: actions.id, cardNumber: actions.driverActionId, title: actions.bodyFormatted, customTitle: actions.customTitle, driverTag: actions.driverId, cardId: actions.cardId, xPos: this.calculateActualXPos(actions.xPos), xRatio: actions.xPos, yPos: this.calculateActualYPos(actions.yPos), yRatio: actions.yPos, quadrant: actions.priorityId, isSelected: actions.IsSelected, cardType: 'GN', customActionId: actions.customActionId, estCost: actions.estCost, rag: actions.rag, ownerName: actions.ownerName, startDate: this.getDate(actions.startDate), completionDate: this.getDate(actions.completionDate), modifiedDateTime: actions.modifiedDateTime
                                    });
                                }
                            }
                        }
                    }
                }
                //this.initialActions = Object.assign({}, this.prioritizedActions);
                this.initialActions = JSON.parse(JSON.stringify(this.prioritizedActions));
                this.setActions();
                //To open the first accordion
                //for (let driver in this.actionsToBePrioritized) {
                //    if (this.actionsToBePrioritized[driver].length > 0) {
                //        this.selectedId = driver;
                //        this.scrollDriverToTop();
                //        break;
                //    }
                //}
                //if (driverScored.length == 0 || (this.actionCards.length == 0 && this.actionId.length == 0))
                //    this.showDialog();
                //var addedActions = 0;
                //for (let action in this.actionCards) {
                //    if (this.actionCards && this.actionCards[action] && this.actionCards[action].length) {
                //        addedActions = addedActions + this.actionCards[action].length;
                //    }
                //}
                //for (let action in this.actionCards) {
                //    if (this.actionCards && this.actionCards[action] && this.actionCards[action].length) {
                //        addedActions = addedActions + this.actionCards[action].length;
                //    }
                //}
                // if (!this.isCorporate) {
                if (driverScored.length == 0 || this.actionCards.length == 0)
                    this.showDialog(data.growthPlanStatus);
                // }

                //
                //

                if (data.growthActions && data.growthActions.length > 0) {
                    data.growthActions.some((item) => {
                        if (item.xPos > -1 && item.IsSelected) {
                            this.isPlotted = true;
                            return true;
                        }
                    })
                    if (!this.isPlotted) {
                        data.growthActions.some((item) => {
                            if (item.xPos > -1 && item.IsSelected) {
                                this.isPlotted = true;
                                return true;
                            }
                        })
                    }
                }

                if (data.corporateGrowthActions && (data.corporateGrowthActions.length > 0 || data.corporateCustomActions.length > 0)) {
                    data.corporateGrowthActions.some((item) => {
                        if (item.xPos > -1 && item.IsSelected) {
                            this.isPlotted = true;
                            return true;
                        }
                    })
                    if (!this.isPlotted) {
                        data.corporateGrowthActions.some((item) => {
                            if (item.xPos > -1 && item.IsSelected) {
                                this.isPlotted = true;
                                return true;
                            }
                        })
                    }
                }

                if (this.isCorporate && !this.self && this.actionsToBePrioritized != undefined && this.selectedId != undefined && this.actionsToBePrioritized.length > 0) {
                    //this.setParticipants(this.actionsToBePrioritized[this.selectedId][0]);
                    if(this.selectedCard.cardNumber != undefined && this.selectedCard.cardNumber != "") {
                        //this.onselectedCard(this.selectedCard);
                        setTimeout(() => {
                            window.dispatchEvent(new Event('resize'));
                        });
                        }
else if (this.actionsToBePrioritized[0].cardNumber != undefined && this.actionsToBePrioritized[0].cardNumber != "") {
                        //this.onselectedCard(this.actionsToBePrioritized[0]);
                        setTimeout(() => {
                            window.dispatchEvent(new Event('resize'));
                        });
                    }
                }

                if (!this.isPlotted) {
                    this.velocityService.getDriverAssesments(this.updateProgressBar).subscribe(
                        (data) => {
                            var driverAssessed = data.growthPlan.IsAnyDriverAssessed;
                            if (!driverAssessed) {
                                this.shouldShowNavigationToGrowthSummaryPage = false;
                            }
                        });
                } else {
                    this.shouldShowNavigationToGrowthSummaryPage = true;
                }

                ////let newPrioritizedActions = {};
                //this.prioritizedActions.forEach((action, index) => {
                //    console.log(action);
                //    let x = action.xPos;
                //    let y = action.yPos;
                //    if (this.newPrioritizedActions[x + y] == undefined)
                //        this.newPrioritizedActions[x + y] = [];
                //    this.newPrioritizedActions[x + y].push(action);
                //});
                //console.log(this.newPrioritizedActions);
                //this.prioritizedActionsKeys = Object.keys(this.newPrioritizedActions);
                //console.log(this.prioritizedActionsKeys);
                this.addCountBubble('');
                if (this.selectedCard.id !== undefined && this.isCorporate && !this.self) {
                    let ac = Object.assign({}, this.selectedCard);
                    ac['selected'] = true;
                    this.onselectedCard(ac);
                } else if (this.selectedCard.id !== undefined) {
                    let ac = Object.assign({}, this.selectedCard);
                    ac['selected'] = true;
                    this.onselectedCard(ac);

                }
                    

            });

        //
        //
    }

    addCountBubble(ev) {
      //  setTimeout(() => {
            this.prioritizedActions.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                //return new Date(b.modifiedDateTime) - new Date(a.modifiedDateTime);

                let dateA = new Date(a.modifiedDateTime).getTime();
                let dateB = new Date(b.modifiedDateTime).getTime();
                return dateA > dateB ? 1 : -1;

                //return 1;
            });
            let previousPriorityList = Object.assign({}, this.newPrioritizedActions);
            this.newPrioritizedActions = {};
            this.countBubbleSelected = {};
            this.prioritizedActions.forEach((action, index) => {
                if (action.isSelected) {
                    //console.log(action);
                    let x = action.xPos;
                    let y = action.yPos;
                    let round = Math.round(x + Number.EPSILON).toString() + Math.round(y + Number.EPSILON).toString();
                    if (this.newPrioritizedActions[action.quadrant + 'test' + round] == undefined) {
                        this.newPrioritizedActions[action.quadrant + 'test' + round] = [];
                        this.countBubbleSelected[action.quadrant + 'test' + round] = false;
                        if (ev && ev.dragData.id == action.id) {
                            this.prioritizedActionsKeys.forEach((key) => {
                                previousPriorityList[key].forEach((a) => {
                                    if (ev && ev.dragData.id == a.id) {
                                        setTimeout(() => {
                                            let x = action.xPos;
                                            let y = action.yPos;
                                            let round = Math.round(x + Number.EPSILON).toString() + Math.round(y + Number.EPSILON).toString();
                                            this.countBubbleSelected[a.quadrant + 'test' + round] = true;
                                        })
                                    }
                                });
                            });
                        }
                        else if (this.selectedCard.id != undefined && this.selectedCard.id == action.id) {
                            this.countBubbleHighlight[action.quadrant + 'test' + round] = action;
                            console.log('else if');
                        }
                    } else {
                        if (this.selectedCard.id != undefined && this.selectedCard.id == action.id) {
                            this.countBubbleHighlight[action.quadrant + 'test' + round] = action;
                            console.log('else if else ');
                        }
                    }
                    this.newPrioritizedActions[action.quadrant + 'test' + round].push(action);
                }
            });
            //console.log(this.countBubbleSelected);
            this.prioritizedActionsKeys = Object.keys(this.newPrioritizedActions);
            //console.log(this.prioritizedActionsKeys);
      //  }, 0);
    }

    ngAfterViewInit() {
        //var innerHeight = window.innerHeight;
        //this.quadrantHeight = innerHeight - 300;
        //this.quadrantAreaWidth = innerHeight - 320;
        //this.quadrantWidth = innerHeight - 320;

        //setTimeout(() => {
        //    this.calQuadrantHeight = this.quadrant.nativeElement.offsetHeight;
        //});
        //setTimeout(function () {

        //    me.headerWidth = me.quadrantArea.nativeElement.offsetWidth + me.actionArea.nativeElement.offsetWidth;
        //    //me.extraSpaceMargin = me.extraArea.nativeElement.offsetWidth;
        //});
        // this.quadrant.nativeElement.scrollTop += 20;
        setTimeout(() => {
            this.resizeLayout();
        }, 200);
    }
    resizeLayout() {
        var innerHeight = window.innerHeight - 80;
        this.actionAreaWidth = this.actionArea1.nativeElement.offsetWidth;
        var innerWidth = this.mainContainer.nativeElement.offsetWidth - 300;
        if (this.isCorporate && !this.self) {

            if (window.innerWidth > 1400)
                innerWidth = this.mainContainer.nativeElement.offsetWidth - 600;
            else
                innerWidth = this.mainContainer.nativeElement.offsetWidth - 500;

        }
        if (window.innerWidth >= 1600) {
            innerHeight = window.innerHeight - 80;

        } else {
        }
        var usedConst = innerHeight;
        if (innerWidth < innerHeight) {
            usedConst = innerWidth;
        }

        this.quadrantHeight = usedConst;
        this.quadrantAreaWidth = usedConst - 20;

        this.quadrantWidth = usedConst - 20;
        if (this.isCorporate && !this.self)
            this.newAreaWidth = this.mainContainer.nativeElement.offsetWidth - this.quadrantAreaWidth - 330;
        else
            this.newAreaWidth = this.mainContainer.nativeElement.offsetWidth - this.quadrantAreaWidth - 30;

        setTimeout(() => {
            this.calQuadrantHeight = this.quadrant.nativeElement.offsetHeight;
            if (this.isCorporate && !this.self) {
                this.calQuadrantHeight = innerHeight - 30;
                this.quadrantHeight = innerHeight;

                this.partcipantQuadrantWidth = this.calQuadrantHeight > 600 ? '100%' : (this.calQuadrantHeight - 300) + 'px';
                if (window.innerWidth <= 1399 && window.innerHeight <= 650) {
                    this.partcipantWidth = this.quadrantContainer.nativeElement.offsetWidth - this.quadrantArea.nativeElement.offsetWidth - this.actionArea1.nativeElement.offsetWidth;
                }
            }
        });
        setTimeout(() => {
            this.setBubblePositions();
            if (this.isCorporate && this.curentActionCard && this.selectedPrioCorpActions.length > 0) {
                this.setParticipantsBubble();
            }
        }, 100);
    }
    showDialog(growthPlanStatus: any) {//Revisit
        //console.log(growthPlanStatus);
        ////this.OtherContents.growthPlanSession
        //let main = this;
        //let params: any;
        //if (growthPlanStatus.IsShared) {
        //    params = { 'description': this.OtherContents.growthPlanSession.msgPendingAssessmentbyOwner, 'buttonText': this.SiteContents.buttonOk };
        //}
        //else if (growthPlanStatus.IsSelf) {
        //    params = { 'description': this.pageContents.noActionsWarningMessage, 'buttonText': this.pageContents.buttonLetsBegin };
        //}
        //else if (growthPlanStatus.IsFacilitated && !growthPlanStatus.IsLocked) {
        //    params = { 'description': this.OtherContents.growthPlanSession.msgPendingAssessmentSelf, 'buttonText': this.SiteContents.buttonOk };
        //}
        //else if (growthPlanStatus.IsFacilitated && growthPlanStatus.IsLocked) {
        //    params = { 'description': this.OtherContents.growthPlanSession.msgCompletedAssessment, 'buttonText': this.SiteContents.buttonOk };
        //}
        //else {
        //    params = { 'description': this.pageContents.noActionsWarningMessage, 'buttonText': this.pageContents.buttonLetsBegin };
        //}
        //var dialog = this.dialogService.info(TemplateTypes.GROWTH_PLAN_NO_ACTION, params, true);
        //dialog.subscribe(
        //    (data) => {
        //        //dialog.source.observers[0].unsubscribe();
        //        if (data && data === 'start') {
        //            if (growthPlanStatus.IsSelf) {
        //                if (main.driverId)
        //                    main.router.navigate(["focus", "seven-drivers"]);
        //                else
        //                    main.router.navigate(["dashboard"]);
        //            }
        //            else if (growthPlanStatus.IsShared) {
        //                main.router.navigate(["focus", "seven-drivers"]);
        //            }
        //            else if (growthPlanStatus.IsFacilitated && !growthPlanStatus.IsLocked) {
        //                main.router.navigate(["dashboard"]);
        //            }
        //            else if (growthPlanStatus.IsFacilitated && growthPlanStatus.IsLocked) {
        //                main.router.navigate(["focus", "seven-drivers"]);
        //            }
        //            else {
        //                main.router.navigate(["dashboard"]);
        //            }
        //        }
        //    });
    }

    onDragStart(ev: any) {

        //console.log(ev.mouseEvent.offsetX, ev.mouseEvent.offsetY)
        this.highLightBubbles = "";
        this.selectedCard = {};
        this.mouseOffset.x = ev.mouseEvent.offsetX;
        this.mouseOffset.y = ev.mouseEvent.offsetY;
        this.draggedFrom = 0;
    }

    quadrantBubbleDragStart(ev: any) {

        this.highLightBubbles = "";
        this.selectedCard = {};
        this.mouseOffset.x = ev.mouseEvent.offsetX;
        this.mouseOffset.y = ev.mouseEvent.offsetY;
        this.draggedFrom = 1;
    }
    getBubbleSize(fromParticipant?: boolean) {
        var innerWidth = window.innerWidth;
        var innerHeight = window.innerHeight;

        var height = 20;
        var width = 35;
        let qWidth = this.quadrant.nativeElement.offsetHeight;
        if (fromParticipant && this.PQuadrant1) {
            qWidth = this.PQuadrant1.nativeElement.offsetHeight;
        }
        if (qWidth >= 700) {
            height = 25;
            width = 41;
        } else if (qWidth >= 600 && qWidth < 700) {
            height = 22;
            width = 33;
        } else if (qWidth >= 500 && qWidth < 600) {
            height = 20;
            width = 33;
        } else if (qWidth >= 400 && qWidth < 500) {
            height = 14;
            width = 22;
        }
        else if (qWidth >= 300 && qWidth < 400) {
            height = 12;
            width = 20;
        } else {
            height = 10;
            width = 15.2;
        }
        //else {
        //    height = 14;
        //    width = 24;
        //}
        return { height: height, width: width };
    }
    checkIfDroppable(ev: any, target: any, isExpand: boolean) {
        var droppable: false;
        var isDroppable = { droppable: false, x: 0, y: 0, xRatio: 0, yRatio: 0 };
        var currentAction = ev.dragData;
        if (this.draggedFrom == 0 && currentAction.quadrant != 0) {
            return { droppable: false, x: 0, y: 0, xRatio: 0, yRatio: 0 };
        }
        var quadrant = currentAction.quadrant;
        var targetElement = ev.mouseEvent.target;
        var x = ev.mouseEvent.offsetX;
        var y = ev.mouseEvent.offsetY;

        var offSet = this.utilService.getOffset(targetElement);
        var bubbleSize = this.getBubbleSize();
        var bubbleWidth = isExpand ? bubbleSize.width * 2 : bubbleSize.width;
        var bubbleHeight = isExpand ? bubbleSize.height * 2 : bubbleSize.height;
        //   var bubbleOffsetDistance = isExpand ? 5 * 2 : 5;
        // let qWidth = this.quadrant.nativeElement.offsetHeight;


        var bubbleOffsetDistance = isExpand ? 1 * 2 : 1;

        //////if (qWidth < 300) {
        //////    bubbleOffsetDistance = isExpand ? 1  : 0;
        //////}
        x = ev.mouseEvent.offsetX - (this.mouseOffset.x || 0);
        y = ev.mouseEvent.offsetY - (this.mouseOffset.y || 0);


        if (x < 0) {
            x = 0;
        }
        else if (x + bubbleWidth > (offSet.width - 1)) {
            x = offSet.width - bubbleWidth - 1;
        }

        if (y < 0) {
            y = 0;
        }
        else if (y + bubbleHeight > (offSet.height - 1)) {
            y = offSet.height - bubbleHeight - 1;
        }


        //check if bubble dropping on tooltip itself
        let result = document.getElementsByClassName("tooltiptext");
        let r = result[0] as HTMLElement;
        //console.log(r);
        let height, width, top, left, bubbleLeft, bubbleTop, bHeight, tTop, tLeft;
        let sameQ = false;
        if (r != undefined && r != null) {
            let tooltipqClass = r.parentNode.parentNode.parentNode.parentElement.className.split(' ');
            let tooltipQ = 4;
            if (tooltipqClass.indexOf('second-box') >= 0) {
                tooltipQ = 2;
            } else if (tooltipqClass.indexOf('first-box') >= 0) {
                tooltipQ = 1;
            } else if (tooltipqClass.indexOf('third-box') >= 0) {
                tooltipQ = 3;
            }

            let target = targetElement.classList;
            let newQuadrant = 4;
            if (target.contains('second-box')) {
                newQuadrant = 2;
            } else if (target.contains('first-box')) {
                newQuadrant = 1;
            } else if (target.contains('third-box')) {
                newQuadrant = 3;
            }
            if (tooltipQ == newQuadrant) {
                sameQ = true;
            }
            console.log(sameQ);
        }
        if (r != undefined && r != null && sameQ) {
            let classList = r.classList;
            height = r.offsetHeight;
            width = r.offsetWidth;
            top = r.offsetTop;
            left = r.offsetLeft;
            let bubble = document.getElementById('bubble' + r.id);
            let zoomBubble = document.getElementById('bubblezoom-view' + r.id);
            if (bubble != null) {
                bubbleLeft = bubble.offsetLeft;
                bubbleTop = bubble.offsetTop;
                bHeight = bubble.offsetHeight;
            } else {
                bubbleLeft = zoomBubble.offsetLeft;
                bubbleTop = zoomBubble.offsetTop;
                bHeight = zoomBubble.offsetHeight;
            }
            tTop = bubbleTop + bHeight;
            tLeft = bubbleLeft;
            let topAndHeight = tTop + height;
            let leftAndWidth = tLeft + width;
            if (classList.contains('top')) {
                if (classList.contains('right-arrow')) {
                    leftAndWidth = tLeft;
                    tLeft = tLeft - width;
                    topAndHeight = bubbleTop - 10;
                    tTop = bubbleTop - height - 5;
                } else {
                    topAndHeight = bubbleTop - 12;
                    tTop = bubbleTop - height - 5;
                }
            } else {
                if (classList.contains('right-arrow')) {
                    leftAndWidth = tLeft;
                    tLeft = tLeft - width;
                }
            }
            if (x > tLeft && x < leftAndWidth) {
                if (y > tTop && y < topAndHeight) {
                    return { droppable: false, x: 0, y: 0, xRatio: 0, yRatio: 0 };
                }
            }
        }

        //check if x and y near to any of the old bubbles
        let breakI = false;
        this.prioritizedActionsKeys.forEach((actionsKey) => {
            if (!breakI) {

                this.newPrioritizedActions[actionsKey].forEach((action, actionKey) => {
                    if (!breakI) {
                        let bubbleX = action.xPos;
                        let bubbleY = action.yPos;
                        let round = Math.round(bubbleX + Number.EPSILON) + Math.round(bubbleY + Number.EPSILON);
                        let currentBubbleRound = Math.round(x + Number.EPSILON) + Math.round(y + Number.EPSILON);

                        let sub = round - currentBubbleRound;

                        let subX = x - bubbleX;
                        let subY = y - bubbleY;
                        if (subX <= 3 && subX >= -3 && subY <= 3 && subY >= -3) {

                            x = bubbleX;
                            y = bubbleY;
                            breakI = true;
                        }

                        //if (sub <= 1 && sub >= -1) {

                        //}
                    }

                });
            }

        });

        //Prevent Overlapping of bubbles
        //var actionsInQuadrant = this.prioritizedActions.filter((x: any) => x.quadrant == target && x.isSelected == true && x.id !== currentAction.id);
        //if (actionsInQuadrant && actionsInQuadrant.length) {
        //    for (let action of actionsInQuadrant) {

        //        if ((x + bubbleWidth > action.xPos - bubbleOffsetDistance && x + bubbleWidth < action.xPos + bubbleWidth + bubbleOffsetDistance) && (y + bubbleHeight > action.yPos - bubbleOffsetDistance && y + bubbleHeight < action.yPos + bubbleHeight + bubbleOffsetDistance)) {
        //            return { droppable: false, x: 0, y: 0, xRatio: 0, yRatio: 0 };
        //        }
        //        else if ((x > action.xPos - bubbleOffsetDistance && x < action.xPos + bubbleWidth + bubbleOffsetDistance) && (y + bubbleHeight > action.yPos - bubbleOffsetDistance && y + bubbleHeight < action.yPos + bubbleHeight - bubbleOffsetDistance)) {
        //            return { droppable: false, x: 0, y: 0, xRatio: 0, yRatio: 0 };
        //        }
        //        else if ((x + bubbleWidth > action.xPos - bubbleOffsetDistance && x + bubbleWidth < action.xPos + bubbleWidth + bubbleOffsetDistance) && (y > action.yPos - bubbleOffsetDistance && y < action.yPos + bubbleHeight + bubbleOffsetDistance)) {
        //            return { droppable: false, x: 0, y: 0, xRatio: 0, yRatio: 0 };
        //        }
        //        else if ((x > action.xPos - bubbleOffsetDistance && x < action.xPos + bubbleWidth + bubbleOffsetDistance) && (y > action.yPos - bubbleOffsetDistance && y < action.yPos + bubbleHeight + bubbleOffsetDistance)) {
        //            return { droppable: false, x: 0, y: 0, xRatio: 0, yRatio: 0 };
        //        }

        //    }
        //}

        //Commenting as the new plugin, after changing the scale to width and height transitions, returns actia X, Y positions
        //if (isExpand) {
        //    var xRatio = x * 2 / offSet.width * 100;
        //  1  var yRatio = y * 2 / offSet.height * 100;
        //} else {
        var xRatio = x / offSet.width * 100;
        var yRatio = y / offSet.height * 100;
        //}



        return { droppable: true, x: x, y: y, xRatio: xRatio, yRatio: yRatio };

    }

    onDeleteDropSuccess(ev: any) {
        var currentAction = ev.dragData;
        if (this.IsDraftReportAvailable && currentAction.xPos != -1) {
            //if (!this.isLastRecord(ev)) {
            //    if (!this.IsDriverAssessed) {
            //        this.showDeleteActionDialog(true).subscribe(
            //            (data) => {
            //                if (!data) {
            //                    this.deleteFunction(ev);
            //                }
            //            });

            //    } else {
            //        this.deleteFunction(ev);

            //    }
            //} else {
            //if (this.showDeleteActionalert) {
            //    this.deleteFunction(ev);
            //} else {
            //    this.showDeleteActionDialog().subscribe(
            //        (data) => {
            //            if (!data) {
            //                this.showDeleteActionalert = true;

            //                this.deleteFunction(ev);
            //            }
            //        });
            //}
            //  }
        } else {
            this.deleteFunction(ev);
        }

    }
    isLastRecord(ev) {
        var currentAction = ev.dragData;
        var selected = this.initialActions.filter((x: any) => x.id != currentAction.id && x.isSelected && x.xPos > -1 && x.yPos > -1);

        return selected.length;


    }
    deleteFunction(ev: any) {
        // this.isChanged = true;
        this.highLightBubbles = "";
        this.selectedId = "";
        this.selectedCard = {};

        if (this.draggedFrom == 0) {
            return;
        }
        var currentAction = ev.dragData;
        //if (currentAction.xPos < 0) {
        //    this.prioritizedActions.push(currentAction);
        //}

        var actionAlreadyPresent = this.prioritizedActions.filter((x: any) => x.id == currentAction.id);
        if (actionAlreadyPresent && actionAlreadyPresent.length && actionAlreadyPresent.length > 0) {
            actionAlreadyPresent[0].isSelected = false;
            this.initialActions.filter((x: any) => x.id == currentAction.id)[0].isSelected = false;
        }
        else {
            currentAction.isSelected = false;
            this.prioritizedActions.push(currentAction);
            this.initialActions.push(currentAction);
        }

        if (this.actionCards != undefined) {
            var action = this.actionCards.filter((x: any) => x.id == currentAction.id);
            if (action && action.length && action.length > 0) {
                action[0].isSelected = false;
            }
        }




        this.setActions();
        this.mouseOffset = {};
        this.onSaveBubble(currentAction);
    }
    onBubbleDropped1(ev: any, target: any, isExpand?: boolean) {
        //  this.isChanged = true;
        var x = ev.mouseEvent.offsetX;
        var y = ev.mouseEvent.offsetY;
        var xRatio;
        var yRatio;
        if (isExpand) {
            var ifDroppable = this.checkIfDroppable(ev, target, true);
        } else {
            var ifDroppable = this.checkIfDroppable(ev, target, false);
        }
        if (!ifDroppable.droppable) {
            //ev.mouseEvent.preventDefault();
            return;
        }
        else {
            x = ifDroppable.x;
            y = ifDroppable.y;
            xRatio = ifDroppable.xRatio;
            yRatio = ifDroppable.yRatio;

        }


        //var x = ev.mouseEvent.offsetX;
        //var y = ev.mouseEvent.offsetY;

        var currentAction = ev.dragData;

        var actionAlreadyPresent = this.prioritizedActions.filter((x: any) => x.id == currentAction.id);
        var initialActionsPresent = this.initialActions.filter((x: any) => x.id == currentAction.id);

        if (initialActionsPresent && initialActionsPresent.length && initialActionsPresent.length > 0) {
            initialActionsPresent[0].xPos = x;
            initialActionsPresent[0].yPos = y;
            initialActionsPresent[0].xRatio = xRatio;
            initialActionsPresent[0].yRatio = yRatio;
            initialActionsPresent[0].quadrant = target;
            if (actionAlreadyPresent && actionAlreadyPresent.length && actionAlreadyPresent.length > 0) {
                actionAlreadyPresent[0].xPos = x;
                actionAlreadyPresent[0].yPos = y;
                actionAlreadyPresent[0].xRatio = xRatio;
                actionAlreadyPresent[0].yRatio = yRatio;
                actionAlreadyPresent[0].quadrant = target;

            }
        }
        else {
            currentAction.xPos = x;
            currentAction.yPos = y;
            currentAction.xRatio = xRatio;
            currentAction.yRatio = yRatio;
            currentAction.quadrant = target;
            this.prioritizedActions.push(currentAction);
            this.initialActions.push(currentAction);
        }
        var currentActionInList;
        let callUpdateCurrentBubble = false;
        if (this.actionCards != undefined) {
            currentActionInList = this.actionCards.filter((x: any) => x.id == currentAction.id);
            if (currentActionInList && currentActionInList.length && currentActionInList.length > 0) {
                currentActionInList[0].xPos = x;
                currentActionInList[0].yPos = y;
                currentActionInList[0].xRatio = xRatio;
                currentActionInList[0].yRatio = yRatio;
                currentActionInList[0].quadrant = target;
                callUpdateCurrentBubble = true;
            }
        }

        this.setActions();

        this.mouseOffset = {};
        this.onSaveBubble(currentAction);
        if (callUpdateCurrentBubble) {
            this.updateCurrentBubble(currentAction);
        }
        this.addCountBubble(ev);
    }

    updateCurrentBubble(currentBubble) {
        let action = this.prioritizedActions.filter((x: any) => x.id == currentBubble.id);
        let actionToPush = JSON.parse(JSON.stringify(action))

        let index = this.prioritizedActions.findIndex(x => x.id == currentBubble.id);
        this.prioritizedActions.splice(index, 1);
        let indexI = this.initialActions.findIndex(x => x.id == currentBubble.id);
        this.initialActions.splice(indexI, 1);
        let currentdate = new Date();
        let hours = currentdate.getHours();
        let amPm = 'AM';
        if (hours >= 12) {
            amPm = 'PM';
            hours = hours - 12;

        }

        let datetime = (currentdate.getMonth() + 1) + "/"
            + currentdate.getDate() + "/"
            + currentdate.getFullYear() + " "
            + hours + ":"
            + currentdate.getMinutes() + ":"
            + currentdate.getSeconds() + " "
            + amPm;
        currentBubble.modifiedDateTime = datetime;

        this.prioritizedActions.push(currentBubble);
        this.initialActions.push(currentBubble);
    }

    onSaveBubble(currentAction: any) {
        let main = this;
        let saveGrowthPlanActionPrioritize: SaveGrowthPlanActionPrioritize[] = [];
        //console.log('continue :', this.prioritizedActions);
        var priAct = this.initialActions.filter((x: any) => x.id == currentAction.id)[0];
        if (priAct) {
            var xCordinate = 0;
            var yCordinate = 0;
            if (priAct.xRatio && priAct.yRatio) {
                xCordinate = priAct.xRatio / 20;
                yCordinate = 5 - priAct.yRatio / 20;
                if (priAct.quadrant == 2 || priAct.quadrant == 4)
                    xCordinate += 5;
                if (priAct.quadrant == 1 || priAct.quadrant == 2)
                    yCordinate += 5;
            }

            if (!this.isCorporate) {
                let saveList = new SaveGrowthPlanActionPrioritize(priAct.id, this.growthPlanId, priAct.isSelected, priAct.quadrant, Math.round(priAct.xRatio), Math.round(priAct.yRatio), xCordinate, yCordinate);
                saveGrowthPlanActionPrioritize.push(saveList);
            } else {
                if (this.self) {
                    let saveList = new SaveGrowthPlanActionPrioritize(priAct.id, this.CorporateSelfGrowthPlanId, priAct.isSelected, priAct.quadrant, Math.round(priAct.xRatio), Math.round(priAct.yRatio), xCordinate, yCordinate);
                    saveGrowthPlanActionPrioritize.push(saveList);
                }
                else {
                    let saveList = new SaveGrowthPlanActionPrioritize(priAct.id, this.growthPlanId, priAct.isSelected, priAct.quadrant, Math.round(priAct.xRatio), Math.round(priAct.yRatio), xCordinate, yCordinate);
                    saveGrowthPlanActionPrioritize.push(saveList);
                }
            }


        }

        //New Change
        var actionsModel: SavePrioritizeActionsModel = new SavePrioritizeActionsModel(-1, this.growthPlanId, saveGrowthPlanActionPrioritize, this.IsDriverAssessed);

        //If Deleted - To select other action
        if (!actionsModel.actions[0].IsSelected && this.isCorporate && !this.self && this.actionsToBePrioritized != undefined && this.selectedId != undefined) {
            let hasCard = false;
            for (let key in this.actionsToBePrioritized) {

                // for (let key in this.actionsToBePrioritized[driver]) {

                //To slect only from the selcteddriver
                if (this.selectedFilterDriver == 0 || this.showAll || this.selectedFilterDriver == this.actionsToBePrioritized[key].driverTag)
                    if (this.actionsToBePrioritized[key].isSelected && this.actionsToBePrioritized[key].cardNumber != undefined && this.actionsToBePrioritized[key].cardNumber != "") {
                        hasCard = true;
                        this.onselectedCard(this.actionsToBePrioritized[key]);
                        break;
                    }
                //}
            }
            if (!hasCard)
                this.emptyPartcipant();

        }
        //To save the Actions
        this.velocityService.saveGrowthPlanActionPrioritize(actionsModel).subscribe(
            (data: any) => {
                if (data) {
                    if (this.driverId) {
                        this.logGrowthPlanModifiedPlusDriverEvent(this.growthPlanId, +this.driverId);
                    }
                    if (this.isCorporate) {
                        if ((this.selectedFilterDriver == 0 || this.showAll || this.selectedFilterDriver == currentAction.driverTag) && currentAction.isSelected)
                            this.setParticipants(currentAction, 'fromSave');
                    } else {
                        this.selectedCard = currentAction;

                    }
                    if (this.isCorporate && this.selectedPrioCorpActions && this.selectedPrioCorpActions.length == 1) {

                        if (this.selectedPrioCorpActions[0].team && (priAct.id == this.selectedPrioCorpActions[0].id)) {
                            if (priAct) {
                                this.curentActionCard.xRatio = priAct.xRatio;
                                this.curentActionCard.yRatio = priAct.yRatio;
                            }

                            this.setParticipants(this.curentActionCard,'fromSave');
                        }
                    }
                    //this.router.navigate(["focus","seven-drivers"]);

                } else {
                    main.utilService.showError("Error in saving");
                }
            });

    }


    deletedCount() {
        var isAlreadyaddedAction = this.initialActions.filter((x: any) => x.isSelected == false);
        if (isAlreadyaddedAction && isAlreadyaddedAction.length) {
            return isAlreadyaddedAction.length;
        }
        else { return 0; }
    }



    isDraggable(action: any) {
        let isDraggable: boolean;
        isDraggable = this.isLocked ? false : true;
        //var isAlreadyaddedAction = this.prioritizedActions.filter((x: any) => x.id == action.id && x.isSelected == true && x.xPos>1);
        //if (isAlreadyaddedAction && isAlreadyaddedAction.length && isAlreadyaddedAction.length > 0) {
        //    //if (isAlreadyaddedAction[0].xPos > -1) {
        //    isDraggable = false;
        //    //}
        //}

        return isDraggable;
    }
    // getActionCardDetails() {
    //     let cardreferance = { "cardReferences": this.actionReferance };
    //     this.growthService.getGrowthPlanCardDetails(cardreferance, this.updateProgressBar).subscribe(
    //         (data: any) => {
    //             for (let actions of data.growthPlanActions) {

    //                 let actionDetails = this.growthActions.filter((x: any) => x.cardReference === actions.reference);
    //                 if (actionDetails) {
    //                     if (!this.actionCards[actionDetails[0].driverReference]) {
    //                         this.actionCards[actionDetails[0].driverReference] = [];
    //                     }
    //                     this.actionCards[actionDetails[0].driverReference].push({ id: actionDetails[0].id, cardNumber: actions.cardNumber, title: actions.bodyFormatted, driverTag: actionDetails[0].driverReference, xPos: actionDetails[0].xPos, yPos: actionDetails[0].yPos, quadrant: actionDetails[0].priorityId, isSelected: actionDetails[0].IsSelected, cardReferene: actionDetails[0].cardReference, cardType: 'GN' });
    //                     if (actionDetails[0].xPos != -1 || actionDetails[0].IsSelected == false) {
    //                         this.prioritizedActions.push({ id: actionDetails[0].id, cardNumber: actions.cardNumber, title: actions.bodyFormatted, driverTag: actionDetails[0].driverReference, xPos: this.calculateActualXPos(actionDetails[0].xPos), xRatio: actionDetails[0].xPos, yPos: this.calculateActualYPos(actionDetails[0].yPos), yRatio: actionDetails[0].yPos, quadrant: actionDetails[0].priorityId, isSelected: actionDetails[0].IsSelected, cardReferene: actionDetails[0].cardReference, cardType: 'GN' });
    //                     }
    //                 }
    //             }

    //             //this.actionsToBePrioritized = Object.assign({}, this.actionCards);//this.prioritizedActions.filter(x => x.xPos > -1);

    //             this.setActions(true);

    //         });
    // }

    //   getActionCardDetails() {
    //    let cardId = { "cardIds": this.actionId };
    //    this.growthService.getGrowthPlanCardDetails(cardId, this.updateProgressBar).subscribe(
    //        (data: any) => {
    //            for (let actions of data.growthPlanActions) {

    //                let actionDetails = this.growthActions.filter((x: any) => x.cardId === actions.id);
    //                if (actionDetails) {
    //                    if (!this.actionCards[actionDetails[0].driverId]) {
    //                        this.actionCards[actionDetails[0].driverId] = [];
    //                    }
    //                    this.actionCards[actionDetails[0].driverId].push({ id: actionDetails[0].id, cardNumber: actions.cardNumber, title: actions.bodyFormatted, driverTag: actionDetails[0].driverId, xPos: actionDetails[0].xPos, yPos: actionDetails[0].yPos, quadrant: actionDetails[0].priorityId, isSelected: actionDetails[0].IsSelected, cardId: actionDetails[0].cardId, cardType: 'GN', estCost: actionDetails[0].estCost, rag: actionDetails[0].rag});
    //                    if (actionDetails[0].xPos != -1 || actionDetails[0].IsSelected == false) {
    //                        this.prioritizedActions.push({ id: actionDetails[0].id, cardNumber: actions.cardNumber, title: actions.bodyFormatted, driverTag: actionDetails[0].driverId, xPos: this.calculateActualXPos(actionDetails[0].xPos), xRatio: actionDetails[0].xPos, yPos: this.calculateActualYPos(actionDetails[0].yPos), yRatio: actionDetails[0].yPos, quadrant: actionDetails[0].priorityId, isSelected: actionDetails[0].IsSelected, cardId: actionDetails[0].cardId, cardType: 'GN', estCost: actionDetails[0].estCost, rag: actionDetails[0].rag});

    //                    }
    //                }
    //            }

    //            //this.actionsToBePrioritized = Object.assign({}, this.actionCards);//this.prioritizedActions.filter(x => x.xPos > -1);

    //            this.setActions(true);

    //        });
    //}

    getCountInQuadrant(qNo: number) {
        if (qNo == 0) {
            var isAlreadyaddedAction = this.prioritizedActions.filter((x: any) => x.isSelected == true && x.xPos > -1);
            if (isAlreadyaddedAction && isAlreadyaddedAction.length) {
                return isAlreadyaddedAction.length;
            }
            else {
                return 0;
            }
        }
        else {
            var isAlreadyaddedAction = this.prioritizedActions.filter((x: any) => x.isSelected == true && x.quadrant == qNo);
            if (isAlreadyaddedAction && isAlreadyaddedAction.length) {
                return isAlreadyaddedAction.length;
            }
            else {
                return 0;
            }
        }
    }

    setActions() {
        //if (!prioritised) {
        //this.actionsToBePrioritized = Object.assign({}, this.actionCards);
        this.actionsToBePrioritized = [];//this.actionCards.filter(x => x.isSelected == true);

        if (this.actionCards != undefined) {
            //  for (let driver in this.actionCards) {
            var actions = this.actionCards.filter(x => x.isSelected == true);
            if (actions && actions.length && actions.length > 0) {
                this.actionsToBePrioritized = this.sorted(actions);
                //this.actionsToBePrioritized.map(action =>
                //    action.open = false
                //);
            }
            //else {
            //    this.actionsToBePrioritized[driver = [];
            //}
            // }
        }


        //}
        //else {
        //    this.actionsToBePrioritized = {};
        //    if (this.actionCards != undefined) {
        //        for (let driver in this.actionCards) {
        //            var actions = this.actionCards[driver].filter(x => x.xPos > -1 && x.isSelected == true && x.quadrant == 2);
        //            if (actions && actions.length && actions.length > 0) {
        //                this.actionsToBePrioritized[driver] = this.sorted(actions);
        //                this.actionsToBePrioritized[driver].map(action =>
        //                    action.open = false
        //                );
        //            }
        //            else {
        //                this.actionsToBePrioritized[driver] = [];
        //            }
        //        }
        //    }


        //}
        //if (this.driverId) {
        //    this.scrollDriverToTop();
        //}
    }
    OpenActionListOfDriver() {

        let selDriverId = this.drivers.filter((x: any) => x.id == this.driverId);
        if (selDriverId.length > 0) {
            this.selectedId = selDriverId[0].id;
        }
    }
    //onGrowthPlanContinue() {
    //    this.isLeaving = true;
    //    let main = this;
    //    let saveGrowthPlanActionPrioritize: SaveGrowthPlanActionPrioritize[] = [];
    //    //console.log('continue :', this.prioritizedActions);
    //    for (let priAct of this.prioritizedActions) {
    //        var xCordinate = 0;
    //        var yCordinate = 0;
    //        if (priAct.xRatio && priAct.yRatio) {
    //            xCordinate = priAct.xRatio / 20;
    //            yCordinate = 5 - priAct.yRatio / 20;
    //            if (priAct.quadrant == 2 || priAct.quadrant == 4)
    //                xCordinate += 5;
    //            if (priAct.quadrant == 1 || priAct.quadrant == 2)
    //                yCordinate += 5;
    //        }

    //        let saveList = new SaveGrowthPlanActionPrioritize(priAct.id, this.growthPlanId, priAct.isSelected, priAct.quadrant, Math.round(priAct.xRatio), Math.round(priAct.yRatio), xCordinate, yCordinate);
    //        saveGrowthPlanActionPrioritize.push(saveList);

    //    }
    //    console.log(saveGrowthPlanActionPrioritize);

    //    //New Change
    //    var actionsModel: SavePrioritizeActionsModel = new SavePrioritizeActionsModel(-1, this.growthPlanId, saveGrowthPlanActionPrioritize, this.IsDriverAssessed);



    //    //To save the Actions
    //    this.velocityService.saveGrowthPlanActionPrioritize(actionsModel).subscribe(
    //        (data: any) => {
    //            if (data) {
    //                if (this.driverId) {
    //                    this.router.navigate(["focus", "seven-drivers"]);
    //                    this.logGrowthPlanModifiedPlusDriverEvent(this.growthPlanId, +this.driverId);
    //                } else {
    //                    this.router.navigate(["dashboard"]);
    //                    this.logGrowthPlanModifiedEvent(this.growthPlanId);
    //                }
    //                //this.router.navigate(["focus","seven-drivers"]);

    //            } else {
    //                main.utilService.showError("Error in saving");
    //            }
    //        });

    //}

    //changeList(type: number) {
    //    this.selectedId = "";
    //    this.selectedCard = {};
    //    this.selectedType = type;
    //    if (type == 1) {
    //        this.setActions(true);
    //    }
    //    else {
    //        this.setActions(false);
    //    }

    //    this.listCardPos = "0px";
    //}
    // chkDisableList(driverReference: string) {
    //     return this.actionsToBePrioritized[driverReference] ? (this.actionsToBePrioritized[driverReference].length > 0 ? false : true) : true
    // }

    //chkDisableList(driverId: string) {
    //    return this.actionsToBePrioritized[driverId] ? (this.actionsToBePrioritized[driverId].length > 0 ? false : true) : true
    //}

    //expandQuadrant(boxType: string) {
    //    if (this.dragDataService.isDragging) {
    //        return;
    //    }
    //    this.showBubble = false;
    //    let expandQuadrant = this.quadrant.nativeElement.getElementsByClassName('expand-quadrant');
    //    let innerQuadrant = this.quadrant.nativeElement.getElementsByClassName('inner-expand');
    //    if (boxType != 'expand-box') {

    //        if (this.state != 'small' && this.selBoxType != boxType) {
    //            this.state = "small";
    //            this.className = "";
    //            let me = this;

    //            setTimeout(function () {
    //                me.currentExpandedQuadrant = -1;
    //                expandQuadrant[0].style.height = "0px";
    //                expandQuadrant[0].style.width = "0px";
    //                innerQuadrant[0].style.height = "0px";
    //                innerQuadrant[0].style.width = "0px";
    //                me.setBubblePositions();
    //                setTimeout(function () {
    //                    me.setPositionQuadrant(boxType);
    //                }, 500);
    //                me.dropEnabled = true;
    //            }, 500);

    //        } else {
    //            //this.setBubblePositions();
    //            this.setPositionQuadrant(boxType);
    //            this.addCountBubble();
    //        }


    //    } else {

    //        this.state = "small";
    //        this.className = "";
    //        var me = this;
    //        setTimeout(function () {
    //            //expandQuadrant[0].style.display = "none";
    //            me.currentExpandedQuadrant = -1;
    //            console.log(expandQuadrant);
    //            expandQuadrant[0].style.height = "0px";
    //            expandQuadrant[0].style.width = "0px";
    //            innerQuadrant[0].style.height = "0px";
    //            innerQuadrant[0].style.width = "0px";
    //            me.setBubblePositions();
    //            me.dropEnabled = true;
    //        }, 500);
    //    }

    //}

    setPositionQuadrant(boxType: string) {
        this.selBoxType = boxType;
        let expandQuadrant = this.quadrant.nativeElement.getElementsByClassName('expand-quadrant');
        let innerQuadrant = this.quadrant.nativeElement.getElementsByClassName('inner-expand');
        let reqQuadrant = this.quadrant.nativeElement.getElementsByClassName(boxType);
        //expandQuadrant[0].style.height = reqQuadrant[0].offsetHeight + 'px';
        //expandQuadrant[0].style.width = reqQuadrant[0].offsetWidth + 'px';
        //innerQuadrant[0].style.height = reqQuadrant[0].offsetHeight + 'px';
        //innerQuadrant[0].style.width = reqQuadrant[0].offsetWidth + 'px';
        expandQuadrant[0].style.display = "block";
        expandQuadrant[0].style.top = "auto";
        expandQuadrant[0].style.right = "auto";
        expandQuadrant[0].style.left = "auto";
        expandQuadrant[0].style.bottom = "auto";
        if (boxType == "second-box") {
            expandQuadrant[0].style.top = "0px";
            expandQuadrant[0].style.right = "0px";
            innerQuadrant[0].style.backgroundColor = "#E5E5EF";
            this.state = "expand-lb";
            this.className = "expand-blue";
            this.currentExpandedQuadrant = 2;
        }
        else if (boxType == "first-box") {
            expandQuadrant[0].style.top = "0px";
            expandQuadrant[0].style.left = "0px";
            innerQuadrant[0].style.backgroundColor = "#F4F4F9";
            this.state = "expand-rb";
            this.className = "expand-green";
            this.currentExpandedQuadrant = 1;
        }
        else if (boxType == "third-box") {
            expandQuadrant[0].style.bottom = "0px";
            expandQuadrant[0].style.left = "0px";
            innerQuadrant[0].style.backgroundColor = "#F4F4F9";
            this.state = "expand-rt";
            this.className = "expand-gray";
            this.currentExpandedQuadrant = 3;
        }
        else if (boxType == "fourth-box") {
            expandQuadrant[0].style.bottom = "0px";
            expandQuadrant[0].style.right = "0px";
            innerQuadrant[0].style.backgroundColor = "#F4F4F9";
            this.state = "expand-lt";
            this.className = "expand-orange";
            this.currentExpandedQuadrant = 4;
        }

        expandQuadrant[0].style.height = reqQuadrant[0].offsetHeight * 2 + 'px';
        expandQuadrant[0].style.width = reqQuadrant[0].offsetWidth * 2 + 'px';
        innerQuadrant[0].style.height = reqQuadrant[0].offsetHeight * 2 + 'px';
        innerQuadrant[0].style.width = reqQuadrant[0].offsetWidth * 2 + 'px';
        var me = this;

        setTimeout(function () {
            me.dropEnabled = false;
            me.showBubble = true;

            var offSet = me.utilService.getOffset(me.quadrant.nativeElement.getElementsByClassName("first-box")[0]);
            for (let action of me.prioritizedActions) {
                if (me.currentExpandedQuadrant == action.quadrant) {
                    action.xPos = (action.xRatio * offSet.width / 100) * 2;
                    action.yPos = (action.yRatio * offSet.height / 100) * 2;
                    //console.log(action);
                }

            }

        }, 500);
    }

    calculateActualXPos(xPos: number) {
        let Quadrant = this.quadrant.nativeElement.getElementsByClassName("first-box");
        var bubbleSize = this.getBubbleSize();
        var bubbleWidth = bubbleSize.width;
        let x = xPos * Quadrant[0].offsetWidth / 100;
        if (x + bubbleWidth > (Quadrant[0].offsetWidth - 1)) {
            x = Quadrant[0].offsetWidth - bubbleWidth - 1;
        }
        return x;
    }
    calculateActualYPos(yPos: number) {
        let Quadrant = this.quadrant.nativeElement.getElementsByClassName("first-box");
        var bubbleSize = this.getBubbleSize();
        var bubbleHeight = bubbleSize.height;
        var y = yPos * Quadrant[0].offsetHeight / 100;
        if (y + bubbleHeight > (Quadrant[0].offsetHeight - 1)) {
            y = Quadrant[0].offsetHeight - bubbleHeight - 1;
        }

        return y;    }

    //getBgColor(quadrant: any) {
    //    switch (quadrant) {
    //        case 1: return "#D6CF66";
    //        case 2: return "#7AACCB";
    //        case 3: return "#A3ADAE";
    //        case 4: return "#FEE098";
    //        default: {
    //            return "transparent";
    //        }
    //    }

    //}
    getBorderClassName(action: any) {
        let className = '';
        if (action.rag && action.rag != "") {
            if (action.rag == 'R') className = 'red';
            else if (action.rag == 'A') className = 'amber';
            else if (action.rag == 'G') className = 'green';
        } else {
            if (action.xPos > -1 && action.isSelected) {
                className = 'priortized';
            }
        }
        if (this.selectedCard && action.cardNumber == this.selectedCard.cardNumber) {
            className = 'sel_card ' + className;
        }
        return className;
    }
    getbubbleClass(expand?: boolean, fromParticipant?: boolean) {
        let qWidth = this.quadrant.nativeElement.offsetHeight;
        if (fromParticipant && this.PQuadrant1) {
            qWidth = this.PQuadrant1.nativeElement.offsetHeight;
        }
        let className = '';
        if (qWidth >= 700) {
            className = 'ex-ex-large-bubble';
        } else if (qWidth >= 600 && qWidth < 700) {
            className = 'ex-large-bubble';
        } else if (qWidth >= 500 && qWidth < 600) {
            className = 'large-bubble';
        } else if (qWidth >= 400 && qWidth < 500) {
            className = 'medium-bubble';
        }
        else if (qWidth >= 300 && qWidth < 400) {
            className = 'small-bubble';
        } else {
            className = 'ex-small-bubble';
        }
        if (!expand) {
            return className;
        } else {
            return 'exp ' + className;
        }

    }
    //scrollAccordion(ev: any) {

    //    //To check the clicked event id from the heading
    //    let clickedElement = ev.target;
    //    while (clickedElement) {
    //        if (clickedElement.classList) {
    //            if (clickedElement.classList.contains("panel-heading")) {
    //                clickedElement = clickedElement.parentNode.parentNode;
    //                if (clickedElement.classList.contains("panel-open")) {
    //                    this.listCardPos = "";
    //                    var me = this;
    //                    setTimeout(function () {
    //                        me.listCardPos = clickedElement.offsetTop;
    //                    });
    //                }
    //                else {
    //                    this.listCardPos = "0px";
    //                }
    //                break;
    //            }
    //            if (clickedElement.classList.contains("panel-body")) {
    //                // ev.stopPropagation();
    //                // ev.preventDefault();
    //                //console.log(clickedElement);
    //                break;
    //            }
    //        }
    //        clickedElement = clickedElement.parentNode;
    //    }

    //    this.selectedId = "";
    //}
    scrollDriverToTop() {

        var me = this;
        setTimeout(function () {
            console.log('here');
            me.listCardPos = me.accordionGroup.nativeElement.querySelector('.panel-open').offsetTop;
        }, 500);
    }
    // goToActionDetail(cardReferene: string, actionId: number, cardType: string, customActionId: number) {

    //     var actionDetails = {
    //         'actionCardReferance': cardReferene, 'actionId': actionId, 'cardType': cardType, 'customActionId': customActionId
    //     };

    //     var appContent = this.OtherContents.actionDetails;
    //     var siteContents = this.SiteContents;

    //     this.dialogService.actionDetail(actionDetails, appContent, siteContents);

    // }


    onselectedCard(card) {
        //  this.changeList(1);

        if (this.selectedType == 1) {
            let hasCard = false;
            if (this.selectedFilterDriver != 0) {
                if (card.driverTag != this.selectedFilterDriver)
                    this.selectedDriver(card.driverTag);
            }
            //for (let driver in this.actionsToBePrioritized) {
            var actions = this.actionsToBePrioritized.filter(x => x.cardNumber == card.cardNumber);
            if (actions.length > 0) {
                hasCard = true;
                // break;
            }
            // }
            //if (!hasCard) {
            //    this.changeList(2);
            //} 
        }
        this.selectedId = card.driverTag;
        this.selectedCard = card;
        // Set participant details for that card
        this.setParticipants(card);
        setTimeout(() => {
            this.setSelectedCardScrollPostion(card.cardNumber);
        }, 500);

        this.listCardPos = "";
    }

    setSelectedCardScrollPostion(cardNumber) {

        let elem: any = document.getElementById("zoom-actionCard" + cardNumber);
        if (elem) {
          let topPos = elem.offsetTop - 14;
          this.listCardPos = topPos.toString();
        }
        setTimeout(() => {
            if (document.getElementById("zoom-al") && document.getElementById("zoom-al").getElementsByClassName("mCSB_container") && elem) {
                let el = document.getElementById("zoom-al").getElementsByClassName("mCSB_container")[0] as HTMLElement;
                let pos = elem.offsetTop - 13;
                if (el.offsetHeight > document.getElementById("zoom-al").offsetHeight) {
                    if ((el.offsetHeight - document.getElementById("zoom-al").offsetHeight) < pos) {
                        pos = el.offsetHeight - document.getElementById("zoom-al").offsetHeight;
                    }
                    el.style.top = '-' + pos + 'px';
                }
            }
        }, 1000);
        
    }

    onClickandHoldBubble(card) {

        this.highLightBubbles = "";
        if (card != "") {
            this.highLightBubbles = card.driverTag;
        }
    }

    openRag(actionId: any, returnValue: any) {
        this.closeOtherActions(actionId);
        if (returnValue) {

            if (this.initialActions.filter((x: any) => x.id == actionId).length > 0)
                this.initialActions.filter((x: any) => x.id == actionId)[0].rag = returnValue.ragStatus;

            if (this.prioritizedActions.filter((x: any) => x.id == actionId).length > 0)
                this.prioritizedActions.filter((x: any) => x.id == actionId)[0].rag = returnValue.ragStatus;
            this.actionsToBePrioritized.filter((x: any) => x.id == actionId)[0].rag = returnValue.ragStatus;
            this.actionsToBePrioritized.filter((x: any) => x.id == actionId)[0].estCost = returnValue.cost;
            this.actionsToBePrioritized.filter((x: any) => x.id == actionId)[0].ownerName = returnValue.owner;
            this.actionsToBePrioritized.filter((x: any) => x.id == actionId)[0].startDate = returnValue.startDate;
            this.actionsToBePrioritized.filter((x: any) => x.id == actionId)[0].completionDate = returnValue.completionDate;
        } else {
            this.actionsToBePrioritized.filter((x: any) => x.id == actionId)[0].open = !this.actionsToBePrioritized.filter((x: any) => x.id == actionId)[0].open;

        }
    }
    closeOtherActions(actionId: any) {
        //  for (let driver in this.actionsToBePrioritized) {
        for (let key in this.actionsToBePrioritized) {
            if (actionId != this.actionsToBePrioritized[key].id) {
                this.actionsToBePrioritized[key].open = false;
            }
        }
        //}
    }
    getClassName(action: any) {
        let className = '';
        if (action.rag == 'R') className = 'rag_red';
        else if (action.rag == 'A') className = 'rag_amber';
        else if (action.rag == 'G') className = 'rag_green';
        else className = 'no_rag';
        if (Object.keys(this.selectedCard).length && this.selectedCard && action.cardNumber == this.selectedCard.cardNumber) {
            className = 'sel_bubble ' + className;
        }
        return className
    }

    logGrowthPlanModifiedEvent(growthPlanId: number) {
        let log = new LogAction();
        log.action = this.logTypes.GROWTH_PLAN_MODIFIED;
        log.growthPlanId = growthPlanId;
        this.logService.logAction(log);
    }

    logGrowthPlanModifiedPlusDriverEvent(growthPlanId: number, driverId: number) {
        let log = new LogAction();
        log.action = this.logTypes.GROWTH_PLAN_MODIFIED;
        log.growthPlanId = growthPlanId;
        log.driverId = driverId;
        this.logService.logAction(log);
    }

    //canDeactivate(): Observable<boolean> | boolean {

    //    if (this.isChanged && !this.isLeaving) {
    //        let config = {
    //            type: 'LOCATION_DELETE',
    //            action: {
    //                cancel: this.pageContents.alertStayButton,
    //                ok: this.pageContents.alertLeaveButton
    //            }
    //        };
    //        return this.dialogService.confirmNavigation(
    //            this.pageContents.alertHeading,
    //            this.pageContents.alertMessage,
    //            config);

    //    } else {
    //        return true;
    //    }

    //}

    isEqual(value, other) {
        // Get the value type
        var type = Object.prototype.toString.call(value);
        // If the two objects are not the same type, return false
        if (type !== Object.prototype.toString.call(other)) return false;
        // If items are not an object or array, return false
        if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;
        // Compare the length of the length of the two items
        var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
        var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
        if (valueLen !== otherLen) return false;

        // Compare properties
        if (type === '[object Array]') {
            for (var i = 0; i < valueLen; i++) {
                if (this.compare(value[i], other[i]) === false) return false;
            }
        } else {
            for (var key in value) {
                if (value.hasOwnProperty(key)) {
                    if (this.compare(value[key], other[key]) === false) return false;
                }
            }
        }
        // If nothing failed, return true
        return true;

    }

    compare(item1, item2) {
        // Get the object type
        var itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
            if (!this.isEqual(item1, item2)) return false;
        }
        // Otherwise, do a simple comparison
        else {
            // If the two items are not the same type, return false
            if (itemType !== Object.prototype.toString.call(item2)) return false;
            // Else if it's a function, convert to a string and compare
            // Otherwise, just compare
            if (itemType === '[object Function]') {
                if (item1.toString() !== item2.toString()) return false;
            } else {
                if (item1 !== item2) return false;
            }
        }
    }

    //showDeleteActionDialog() {
    //    let config = {
    //        type: 'LOCATION_DELETE',
    //        action: {
    //            ok: this.SiteContents.buttonCancel,
    //            cancel: this.PageContents.lblDeleteAction
    //        },
    //        //  clickGrowthPlan: noCard,
    //        close: 'ok'
    //    };
    //    //   let msgContents = this.PageContents.msgConfirmDeleteActionNavigation;
    //    //let htmlContent = "<a>" + this.pageContents.lblClick + '</a>'
    //    //   msgContents = msgContents.replace("#CLICK", htmlContent);
    //    //    var msg = noCard ? msgContents : this.PageContents.msgConfirmDeleteAction;
    //    return this.dialogService.confirmNavigation(
    //        this.PageContents.lblConfirmDelete,
    //        this.PageContents.msgConfirmDeleteAction,
    //        config);
    //}

    comparegrowthPlanId(a, b) {
        if (parseInt(a.cardNumber) < parseInt(b.cardNumber))
            return -1;
        if (parseInt(a.cardNumber) > parseInt(b.cardNumber))
            return 1;
        return 0;
    }

    sorted(actions: number[]) {
        return actions.sort(this.comparegrowthPlanId);
    }


    getHeaderWidth() {
        return this.quadrantArea.nativeElement.offsetWidth + this.actionAreaWidth + 38;

    }

    ngOnDestroy() {
        this.activeGPSubscription.unsubscribe();
    }
    /* changeSelf(val) {
         this.self = val;
         this.getActionCards();
         this.resizeLayout();
     }
     */
    emptyPartcipant() {
        this.selectedPrioCorpActions = [];
        this.selectedCardNumber = '';
        this.selectedTitle = '';

    }
    setParticipants(action, fromSave = '') {
        console.log('fromSave ' + fromSave);
        let returnFrom = false;
        if (fromSave != 'fromSave') {
            console.log(action);
            this.countBubbleSelected = {};
            this.countBubbleHighlight = {};
            this.hideShowTooltip = false;
            this.prioritizedActionsKeys.forEach((key) => {
                this.countBubbleSelected[key] = false;
                //this.countBubbleHighlight[key] = false;
                if (this.newPrioritizedActions[key].length > 1) {
                    this.newPrioritizedActions[key].forEach((act) => {
                        if (action.id == act.id) {
                            //this.countBubbleSelected[key] = act;
                            this.countBubbleHighlight[key] = act;
                            returnFrom = true;
                        }
                    });
                }
            });
        }
        
        this.selectedId = action.driverTag;
        this.selectedCard = action;
        this.curentActionCard = action;
        if (returnFrom && fromSave != 'fromFilter') {
            return false;
        }
        if (this.isCorporate && !this.self) {

            this.selectedCardNumber = action.cardNumber;
            this.selectedTitle = action.customTitle ? action.customTitle : action.title;

            var participantPrioModel: ParticipantPrioritizeModel;
            let isCustomAction;
            if (action.customActionId != undefined && action.customActionId != '') {
                isCustomAction = 1;
            }
            if (isCustomAction == 1) {
                participantPrioModel = new ParticipantPrioritizeModel(-1, this.currentGrowthPlanId, 2, action.customActionId);
            }
            else {
                participantPrioModel = new ParticipantPrioritizeModel(-1, this.currentGrowthPlanId, 1, action.cardId);
            }
            this.velocityService.getParticipantPrioritization(participantPrioModel).subscribe(
                (data) => {
                    let bubbleSize = this.getBubbleSize(true);
                    let bubbleWidth = bubbleSize.width;
                    let bubbleHeight = bubbleSize.height;
                    console.log('pp');
                    var offSet = this.utilService.getOffset(this.participantArea.nativeElement.getElementsByClassName("first-box")[0]);
                    if (data.length > 0) {
                        this.selectedPrioCorpActions = [];
                        let orgValues = data;
                        orgValues.map((obj) => {
                            obj.active = false;
                            obj.zValue = 101;
                            return obj;
                        });
                        //   var offSet = this.utilService.getOffset(targetElement);

                        // var bubbleOffsetDistance = isExpand ? 5 * 2 : 5;
                        let qWidth = this.quadrant.nativeElement.offsetHeight;


                        var bubbleOffsetDistance = 1;
                        var zValue = 100;

                        for (let org of orgValues) {
                            org.xRatio = org.xPos;
                            org.yRatio = org.yPos;
                            org.xPos = org.xRatio * offSet.width / 100;
                            org.yPos = org.yRatio * offSet.height / 100;
                            for (let action of this.selectedPrioCorpActions) {
                                if (org.xPos > -1 && org.yPos > -1) {
                                    if ((org.xPos + bubbleWidth > action.xPos - bubbleOffsetDistance && org.xPos + bubbleWidth < action.xPos + bubbleWidth + bubbleOffsetDistance) && (org.yPos + bubbleHeight > action.yPos - bubbleOffsetDistance && org.yPos + bubbleHeight < action.yPos + bubbleHeight + bubbleOffsetDistance)) {
                                        org.zValue = zValue;
                                        zValue--;
                                    }
                                    else if ((org.xPos > action.xPos - bubbleOffsetDistance && org.xPos < action.xPos + bubbleWidth + bubbleOffsetDistance) && (org.yPos + bubbleHeight > action.yPos - bubbleOffsetDistance && org.yPos + bubbleHeight < action.yPos + bubbleHeight - bubbleOffsetDistance)) {
                                        org.zValue = zValue;
                                        zValue--;
                                    }
                                    else if ((org.xPos + bubbleWidth > action.xPos - bubbleOffsetDistance && org.xPos + bubbleWidth < action.xPos + bubbleWidth + bubbleOffsetDistance) && (org.yPos > action.yPos - bubbleOffsetDistance && org.yPos < action.yPos + bubbleHeight + bubbleOffsetDistance)) {
                                        org.zValue = zValue;
                                        zValue--;
                                    }
                                    else if ((org.xPos > action.xPos - bubbleOffsetDistance && org.xPos < action.xPos + bubbleWidth + bubbleOffsetDistance) && (org.yPos > action.yPos - bubbleOffsetDistance && org.yPos < action.yPos + bubbleHeight + bubbleOffsetDistance)) {
                                        org.zValue = zValue;
                                        zValue--;
                                    }
                                }
                            };
                            console.log('pp1');
                            this.selectedPrioCorpActions.push(org);
                        }
                    } else {
                        console.log('pp2');
                        this.selectedPrioCorpActions = [];
                        if (action.isSelected && action.xPos > -1 && action.yPos > -1) {
                            let org = JSON.parse(JSON.stringify(action));
                            org.participantAbbrivation = this.OtherContents.growthPlanSession.lblTText;
                            org.team = true;
                            org.xRatio = action.xRatio ? action.xRatio : action.xPos;
                            org.yRatio = action.yRatio ? action.yRatio : action.yPos;
                            if ((org.xRatio || org.xRatio == 0) && (org.yRatio || org.yRatio == 0)) {
                                org.xPos = org.xRatio * offSet.width / 100;
                                org.yPos = org.yRatio * offSet.height / 100;

                                if ((org.xPos + bubbleWidth) > (offSet.width - 1)) {
                                    org.xPos = offSet.width - 1 - bubbleWidth;
                                }
                                if ((org.yPos + bubbleHeight) > (offSet.height - 1)) {
                                    org.yPos = offSet.height - 1 - bubbleHeight;
                                }
                            }
                            org.priorityId = action.quadrant;
                            this.selectedPrioCorpActions.push(org);
                            console.log('pp3');

                        }
                    }
                    if (fromSave != 'fromFilter') {
                        this.resizeLayout();
                    }
                });
        }
    }
    selectAction(action) {
        this.selectedPrioCorpActions.map(action =>
            action.active = false
        );
        this.selectedPrioCorpActions.filter(x => x.participantId == action.participantId)[0].active = true;
        this.topPartPos = "0px";
        setTimeout(() => {
            this.topPartPos = "";
        });
    }

    setParticipantsBubble() {
        let bubbleSize = this.getBubbleSize(true);
        let bubbleWidth = bubbleSize.width;
        let bubbleHeight = bubbleSize.height;
        var offSet = this.utilService.getOffset(this.participantArea.nativeElement.getElementsByClassName("first-box")[0]);
        for (let action of this.selectedPrioCorpActions) {
            action.xPos = action.xRatio * offSet.width / 100;
            action.yPos = action.yRatio * offSet.height / 100;
            if ((action.xPos + bubbleWidth) > (offSet.width - 1)) {
                action.xPos = offSet.width - bubbleWidth - 1;
            }
            if ((action.yPos + bubbleHeight) > (offSet.height - 1)) {
                action.yPos = offSet.height - bubbleHeight - 1;
            }
        }
    }
    selectedDriver(val) {
        this.selectedFilterDriver = val;
        this.showAll = false;
        this.filerActions();

    }
    getFilterAction() {
        if (this.selectedFilterDriver == 0)
            return this.actionsToBePrioritized;
        else
            return this.actionsToBePrioritized.filter((x: any) => x.driverTag == this.selectedFilterDriver)
    }


    clickQuadrants(e) {
        console.log(e);
        if (e == 'fromBubble') {
            this.selectedId = '';
            //this.selectedCard = '';
            this.curentActionCard = {};
            let keys = Object.keys(this.countBubbleSelected);
            keys.forEach((index) => {
                this.countBubbleSelected[index] = false;
                this.countBubbleHighlight[index] = false;
                if (this.isCorporate && !this.self) {
                    this.newPrioritizedActions[index].forEach((a) => {
                        if (a.id == this.selectedCard.id) {
                            this.countBubbleHighlight[index] = a;
                        }
                    })
                }
            });
            this.hideShowTooltip = !this.hideShowTooltip;
        }
        else if (e == '' || e == undefined) {
            //this.hideShowTooltip = !this.hideShowTooltip;
        }
        else {
            console.log('heree');
            //this.onselectedCard(e);
            setTimeout(() => {
                if (this.selectedType == 1) {
                    let hasCard = false;
                    if (this.selectedFilterDriver != 0) {
                        if (e.driverTag != this.selectedFilterDriver)
                            this.selectedDriver(e.driverTag);
                    }
                    var actions = this.actionsToBePrioritized.filter(x => x.cardNumber == e.cardNumber);
                    if (actions.length > 0) {
                        hasCard = true;
                    }
                }
                this.selectedId = e.driverTag;
                this.selectedCard = e;
                if (this.isCorporate) {
                    this.setParticipants(e, 'fromFilter');
                }

                this.setSelectedCardScrollPostion(e.cardNumber);
            }, 500);
        }

    }

    clickFilter() {
        this.selectedCard = {};
        this.changeFilter = false;
        setTimeout(() => {
            this.changeFilter = true;

            let keys = Object.keys(this.countBubbleSelected);
            keys.forEach((index) => {
                this.countBubbleHighlight[index] = false;
            });
        });

        this.selectedPrioCorpActions = [];
        this.selectedCardNumber = '';
        this.selectedTitle = '';
    }

    changeShowAll() {
        setTimeout(() => {
            this.showAll = !this.showAll;
            this.filerActions();
        });
    }

    filerActions() {
        if (this.selectedFilterDriver == 0 || this.showAll)
            this.prioritizedActions = JSON.parse(JSON.stringify(this.initialActions));
        else {
            this.prioritizedActions = JSON.parse(JSON.stringify(this.initialActions.filter((x: any) => x.driverTag == this.selectedFilterDriver)));
        }
        this.addCountBubble('');
        this.setBubblePositions();

        setTimeout(() => {
            this.setBubblePositions();
            this.addCountBubble('');
        });
    }

    showBorder(action) {
        if (this.selectedFilterDriver != 0 && this.showAll) {
            if (action.driverTag == this.selectedFilterDriver)
                return true;
        }
        return false;
    }
}
