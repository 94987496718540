import { Component, OnInit, Injector, HostListener, ElementRef, Renderer2, EventEmitter, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from './../../component/base.component'
import { ComponentContent } from './../../component/component-content.decorator';
import { CONTENTS } from './../../config/content.const';
import { VelocityService } from './../../service/velocity.service';
import { GrowthService } from './../../service/growth.service';
import { UtilService } from './../../service/util.service';
import { Observable, of,timer } from 'rxjs';
import { Subscription } from 'rxjs';
import { LogService } from "./../../service/log.service";
import { LogTypes, LogAction } from "./../../service/model/log-models";
import { SaveGrowthPlanActionPrioritize, SavePrioritizeActionsModel, ParticipantPrioritizeModel } from './././../../focus/model/save-gp-action-prioritize.model';
import { ProgressBarHelper, ProgressBarOptions } from './../../progress-bar-module/progress-bar-options';
import { StorageHelperService } from './../../service/storage-helper.service';
import { Constants } from "./../../service/constant.service";
import { Subject } from 'rxjs';

import { forkJoin } from 'rxjs';

@ComponentContent(CONTENTS.SUB_DRIVER_GROWTH_PLAN, [CONTENTS.FOCUS, CONTENTS.GROWTH_PLAN_SESSION])
@Component({
        //moduleId: module.id.toString(),
        selector: "zoom-focus-gp",
  templateUrl: './zoom-2x2-focus-gp.html',
    styleUrls: ['./zoom-2x2-focus-gp.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ZooomFocusGPComponent extends BaseComponent implements OnInit {
    currentGrowthPlan: any;
    currentGrowthPlanId: number;
    currentGrowthPlanName: string = '';
    // variable to check the current growth plan is locked or not
    isLocked: boolean = false;
    self: boolean = true;

    driverId: any = '0';
    subDriverId: any = '0';
    breadcrumbItems: { Text: string, Link: string, IsActive: boolean }[] = [];
    drivers: any = [];
    growthPlanId: number;
    actionCards: any = {};
    corporateActionCards: any = {};

    showBreadcrumb: boolean = false;
    selectedType: number = 1;
    updateProgressBar: ProgressBarOptions;

    draggedFrom = 0;
    mouseOffset: any = {};
    selectedCard: any = {};
    highLightBubbles: string = "";
    dropEnabled: boolean = true;

    partcipantQuadrantWidth: any;
    quadrantHeight: number;
    quadrantAreaWidth: number;
    quadrantWidth: number;
    headerWidth: number;
    calQuadrantHeight: number;
    partcipantWidth: number;
    prioritizedActions = [];
    selectedPrioCorpActions = [];
    subDriverAction = [];
    subDriverNonPrioritzedCount: number = 0;
    actionCount: number = 0;
    IsDriverAssessed: false;
    driverName: string;
    subDriverName: string;
    actionAreaWidth: number;
    isCorporate: boolean = false;
    isAuthOwner: boolean = false;
    CorporateSelfGrowthPlanId: number;
    actionData: any;
    selectedCardNumber: any;
    selectedTitle: any;
    noTeamAccessed: boolean = false;
    noSelfAccessed: boolean = false;

    showRefresh: boolean = false;
    timeOutWarningTimer: Observable<number> = timer(0);
    timeoutSubscribers: Subscription;
    timerOn: boolean = false;
    refreshDisabled: boolean = false;
    oldRealJsonVAlue: any;
    oldSelectedCard: any;
    curentActionCard: any;
    selectedPartcipant: any;
    topPartPos = '';
    IsFacilitator: boolean = false;
    hideShowTooltip: boolean = false;
    parentClass: string = '';

  @ViewChild('mainContainer1', { static: true }) mainContainer: ElementRef;
  @ViewChild('Quadrant1', { static: true }) quadrant: ElementRef;
  @ViewChild('actionArea1', { static: true }) actionArea: ElementRef;   
  @ViewChild('quadrantArea1', { static: true }) quadrantArea: ElementRef;
  @ViewChild('participantArea1', { static: false }) participantArea: ElementRef;
  @ViewChild('quadrantContainer1', { static: true }) quadrantContainer: ElementRef;
  @ViewChild('PQuadrant1', { static: false }) PQuadrant1: ElementRef;

    public configSet = [
        {
            className: 'lable-text',
            newFont: '12px',
            oldFont: '20px'
        },
        {
            className: 'toggle',
            newFont: '9px',
            oldFont: '12px'
        }
    ];
    hasApply: boolean = false;
    newPrioritizedActions = {};
    prioritizedActionsKeys = [];
    newAreaWidth: number;
    listCardPos = "";
    countBubbleSelected: any = {};
    countBubbleHighlight: any = {};
    assessmentMode: boolean = true;
    currentGrowthPlanTypeId: number;
    xLabel:string = '';
    yLabel:string = '';
    constructor(
        private injector: Injector,
        private velocityService: VelocityService,
        private growthService: GrowthService,
        private utilService: UtilService,
        private logService: LogService,
        private logTypes: LogTypes,
        public constants: Constants,
        private storageHelper: StorageHelperService
        ) {
        super(injector);
        this.PageContents = this.injector.get('PageContents');
        this.OtherContents = this.injector.get('OtherContents');
        this.driverId = this.injector.get('driverId');
        this.subDriverId = this.injector.get('subDriverId');
        this.self = this.injector.get("team")
        this.selectedCard = this.injector.get("selectedCard");
        this.xLabel = this.injector.get("xLabel");
        this.yLabel = this.injector.get("yLabel");
        //this.selectedCard = {};
    }
    ngOnInit() {

        //Get current growthPlan from session storage
        this.currentGrowthPlan = this.storageHelper.GetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
        this.currentGrowthPlanId = this.currentGrowthPlan.growthPlanId;
        this.currentGrowthPlanName = this.currentGrowthPlan.growthPlanName;
        this.growthPlanId = this.currentGrowthPlan.growthPlanId;
        this.currentGrowthPlanTypeId = this.currentGrowthPlan?.growthPlanTypeId;

        this.breadcrumbItems = [];
        this.breadcrumbItems.push({ Text: this.OtherContents.focus.textFocus, Link: "", IsActive: true });
        this.breadcrumbItems.push({ Text: this.OtherContents.focus.breadCrumpRoot, Link: "./../../../../seven-drivers", IsActive: false });
        this.breadcrumbItems.push({ Text: this.currentGrowthPlanName, Link: "../../../../seven-drivers", IsActive: false });
        this.updateProgressBar= ProgressBarHelper.newUpdateProgressOption("sevenDriverGP1");

        this.resizeLayout();


        setTimeout(() => {
            this.resizeLayout();

            //me.headerWidth = me.quadrantArea.nativeElement.offsetWidth + me.actionArea.nativeElement.offsetWidth;
           // me.extraSpaceMargin = me.extraArea.nativeElement.offsetWidth;
        },200);     
        // To get the action cards          
        this.getActionContents();
    }
    getActionsOnly() {
       // var drivers = this.growthService.getHotTopicFilters(this.updateProgressBar);
            var actionService = this.velocityService.getSubDriverActions(this.subDriverId, this.currentGrowthPlanId, this.updateProgressBar);
        forkJoin( actionService)
            .subscribe(
            (data: any) => {
                if (data) {
                    this.actionData = data[0];
                    this.getActionCards(data[0]);
                }
                });     
    }
    getActionContents() {
        var drivers = this.growthService.getHotTopicFilters(this.updateProgressBar);
        var actionService = this.velocityService.getSubDriverActions(this.subDriverId, this.currentGrowthPlanId, this.updateProgressBar);

        if (this.subDriverId) {
        var driverAccessed = this.velocityService.getDriverThemeAssessment({ driverId: this.driverId, themeId: this.subDriverId, sibling: 0, child: 0, growthPlanId: this.currentGrowthPlanId });
        } else {
            var driverAccessed = this.growthService.getSubDriver(this.driverId);
        }
        forkJoin(drivers, actionService, driverAccessed)
            .subscribe(
            (data:any) => {
                    if (data) {
                        this.IsFacilitator = data[2]?.growthPlanStatus?.IsFacilitator;
                        if (this.currentGrowthPlanTypeId == 5 && this.IsFacilitator) {
                            this.assessmentMode = data[2]?.growthPlanStatus?.IsAssessmentMode;
                        }

                    if (data[1] && data[1].growthPlanStatus) {
                        // Set the pages are read only for locked/shared growth plan
                        if (data[1].growthPlanStatus.IsLocked || data[1].growthPlanStatus.IsShared) {
                            this.isLocked = true;
                        }
                        if (data[1].growthPlanStatus.IsCorporate) {
                            this.isCorporate = true;
                            if(data[1].growthPlanStatus.growthPlanTypeId != 5) {
                                if (data[1].growthPlanStatus.IsCorporateOwner && !data[1].growthPlanStatus.IsDelegated) {
                                    this.isAuthOwner = true;
                                    this.showRefresh = true;
                                } else if (data[1].growthPlanStatus.IsDelegated && data[1].growthPlanStatus.IsDeligatedOwner) {
                                    this.isAuthOwner = true;
                                    this.showRefresh = true;
                                } else if (data[1].growthPlanStatus.IsCorporateOwner && data[1].growthPlanStatus.IsDelegated) {
                                    this.isLocked = true;
                                 //   this.self = false;
                                    this.showRefresh = true;
                                } else {
                                    this.isLocked = true;
                                  //  this.self = false;
                                }
                            }

                            if (data[1].growthPlanStatus.growthPlanTypeId == 5) {
                                this.isAuthOwner = true;
                                this.showRefresh = true;
                              //  this.self = false;
                                
                                if(data[1].growthPlanStatus.IsFacilitator) {
                                    this.IsFacilitator = true;
                                }
                            }
                            
                            if (data[1].growthPlanStatus.IsLocked || data[1].growthPlanStatus.IsShared) {
                                this.showRefresh = false;
                            }
                            if (!this.self && this.showRefresh && !this.timerOn) {
                                this.startTimer();
                            } else if (this.self && this.timerOn) {
                                this.stopTimer();
                            }
                            this.CorporateSelfGrowthPlanId = data[1].growthPlanStatus.CorporateSelfGrowthPlanId;
                            if (this.subDriverId) {


                                if (!data[2].corporateSelfDriverThemeAssessments || (data[2].corporateSelfDriverThemeAssessments && (data[2].corporateSelfDriverThemeAssessments.length == 0))) {
                                    this.noSelfAccessed = true;
                                } else {
                                    // this.self = true;
                                }
                                if (!data[2].driverThemeAssessments || (data[2].driverThemeAssessments && (data[2].driverThemeAssessments.length == 0))) {
                                    this.noTeamAccessed = true;
                                } else {
                                    //  this.self = false;
                                }
                            } else {
                                if (!data[2].driver.isCorporateSelfDriverAssessed) {
                                    this.noSelfAccessed = true;
                                } else {
                                    // this.self = true;
                                }
                                if (!data[2].driver.isDriverAssessed) {
                                    this.noTeamAccessed = true;
                                } else {
                                    //  this.self = false;
                                }
                            }
                            
                            //if (this.noTeamAccessed && this.noSelfAccessed) {
                            //    this.router.navigate(['focus/seven-drivers/driver-assesment/' + this.driverId + '/sublevel/' + this.subDriverId]);
                            //}
                            this.resizeLayout();
                        } else {
                            //if (!data[2].driverThemeAssessments || (data[2].driverThemeAssessments && (data[2].driverThemeAssessments.length == 0))) {
                            //    this.router.navigate(['focus/seven-drivers/driver-assesment/' + this.driverId + '/sublevel/' + this.subDriverId]);
                            //}
                        }
                      
                    }

                    if (!this.assessmentMode) {
                        this.isLocked = true;
                    }

                    this.drivers = data[0];
                    this.driverName = this.drivers.filter(x => x.id == this.driverId)[0].driverName;
                    this.subDriverName = data[1]["subDriverName"];
                    // this.breadcrumbItems.push({ Text: this.driverName, Link: "../../../driver-assesment/" + this.driverId, IsActive: false });
                    // this.breadcrumbItems.push({ Text: this.OtherContents.focus.textSubdrivers, Link: "../../../driver-assesment/" + this.driverId + "/sublevel", IsActive: false });

                    // this.breadcrumbItems.push({ Text: this.subDriverName, Link: "../../../driver-assesment/" + this.driverId + "/sublevel/" + this.subDriverId, IsActive: false });
                    // this.breadcrumbItems.push({ Text: this.OtherContents.focus.breadCrumpActions, Link: "../../../action-card/" + this.driverId + "/" + this.subDriverId, IsActive: false });
                    // this.breadcrumbItems.push({ Text: this.PageContents.breadCrumbTwo, Link: "", IsActive: true });
                    // this.showBreadcrumb = true;
                    this.actionData = data[1];
                    this.getActionCards(data[1]);
                    if (this.driverId) {
                       // this.OpenActionListOfDriver();
                    }
                    setTimeout(() => {
                        this.hasApply = true;
                    });
                }
            });
    }
    getActionCards(data: any) {
      //  this.initialActions = JSON.parse(JSON.stringify(this.prioritizedActions));
        this.setActions(true);
        this.IsDriverAssessed = data.IsDriverAssessed;
        this.actionCards = {};
        this.corporateActionCards = {};
        this.prioritizedActions = [];
        this.subDriverAction = [];
        //if (driverScored.length == 0 || (this.actionCards.length == 0 && this.actionId.length == 0))
        //    this.showDialog();
        //var addedActions = 0;
        
      //  this.growthActions = data.growthActions;

        // For Corporate toggle currently, we are showing only corporateGrowthActions
        // For normal growth plan ,  we are showing only growthActions
        let growthActionDetails;
        let customActionDetails;
        let corporateGrowthActionDetails;
        let corporateCustomActionDetails;
        if (this.isCorporate && this.self && this.isAuthOwner) {
            //growthActionDetails = data.growthActions;
            //customActionDetails = data.customActions;

            growthActionDetails = data.corporateGrowthActions;
            customActionDetails = data.corporateCustomActions;
        }
        if (this.isCorporate && !this.self){
            //corporateGrowthActionDetails = data.corporateGrowthActions;
            //corporateCustomActionDetails = data.corporateCustomActions;

            corporateGrowthActionDetails = data.growthActions;
            corporateCustomActionDetails = data.customActions;
        }

        if (!this.isCorporate) {
            growthActionDetails = data.growthActions;
            customActionDetails = data.customActions;
        }

        if (growthActionDetails != undefined) {

            for (let actions of growthActionDetails) {
                //this.growthPlanId = actions.growthPlanId;

                if (!this.actionCards[actions.driverId]) {
                    this.actionCards[actions.driverId] = [];
                }
                this.actionCards[actions.driverId].push({
                    id: actions.id, cardNumber: actions.driverActionId, title: actions.bodyFormatted, customTitle: actions.customTitle, driverTag: actions.driverId, cardId: actions.cardId, xPos: actions.xPos, yPos: actions.yPos, quadrant: actions.priorityId, isSelected: actions.IsSelected, cardType: 'GN', customActionId: actions.customActionId, estCost: actions.estCost, rag: actions.rag, subDriverid: actions.subDriverid
                });

                if (actions.xPos != -1 || actions.IsSelected == false) {

                    this.prioritizedActions.push({
                        id: actions.id, cardNumber: actions.driverActionId, title: actions.bodyFormatted, customTitle: actions.customTitle, driverTag: actions.driverId, cardId: actions.cardId, xPos: this.calculateActualXPos(actions.xPos), xRatio: actions.xPos, yPos: this.calculateActualYPos(actions.yPos), yRatio: actions.yPos, quadrant: actions.priorityId, isSelected: actions.IsSelected, cardType: 'GN', customActionId: actions.customActionId, estCost: actions.estCost, rag: actions.rag, subDriverid: actions.subDriverid, modifiedDateTime: actions.modifiedDateTime
                    });
                }
            }
        }

        if (customActionDetails != undefined) {
            for (let actions of customActionDetails) {
                //this.growthPlanId = actions.growthPlanId;

                if (!this.actionCards[actions.growthDriverId]) {
                    this.actionCards[actions.growthDriverId] = [];
                }
                this.actionCards[actions.growthDriverId].push({
                    id: actions.id, cardNumber: actions.cardNumber, title: actions.title, customTitle: actions.customTitle, driverTag: actions.growthDriverId, cardId: actions.cardId, xPos: actions.xPos, yPos: actions.yPos, quadrant: actions.actionPriority, isSelected: actions.IsSelected, cardType: 'Custom', customActionId: actions.customActionId, estCost: actions.estCost, rag: actions.rag, subDriverid: actions.subDriverid
                });

                if (actions.xPos != -1 || actions.IsSelected == false) {
                    this.prioritizedActions.push({
                        id: actions.id, cardNumber: actions.cardNumber, title: actions.title, customTitle: actions.customTitle, driverTag: actions.growthDriverId, cardId: actions.cardId, xPos: this.calculateActualXPos(actions.xPos), xRatio: actions.xPos, yPos: this.calculateActualYPos(actions.yPos), yRatio: actions.yPos, quadrant: actions.actionPriority, isSelected: actions.IsSelected, cardType: 'Custom', customActionId: actions.customActionId, estCost: actions.estCost, rag: actions.rag, subDriverid: actions.subDriverid, modifiedDateTime: actions.modifiedDateTime
                    });
                }
            }
        }


        if (corporateGrowthActionDetails != undefined) {
            for (let actions of corporateGrowthActionDetails) {
                //this.growthPlanId = actions.growthPlanId;

                if (!this.corporateActionCards[actions.driverId]) {
                    this.corporateActionCards[actions.driverId] = [];
                }
                this.corporateActionCards[actions.driverId].push({
                    id: actions.id, cardNumber: actions.driverActionId, title: actions.bodyFormatted, customTitle: actions.customTitle, driverTag: actions.driverId, cardId: actions.cardId, xPos: actions.xPos, yPos: actions.yPos, quadrant: actions.priorityId, isSelected: actions.IsSelected, cardType: 'GN', customActionId: actions.customActionId, estCost: actions.estCost, rag: actions.rag, subDriverid: actions.subDriverid
                });

                if (actions.xPos != -1 || actions.IsSelected == false) {

                    if (this.isCorporate && !this.self) {
                        this.prioritizedActions.push({
                            id: actions.id, cardNumber: actions.driverActionId, title: actions.bodyFormatted, customTitle: actions.customTitle, driverTag: actions.driverId, cardId: actions.cardId, xPos: this.calculateActualXPos(actions.xPos), xRatio: actions.xPos, yPos: this.calculateActualYPos(actions.yPos), yRatio: actions.yPos, quadrant: actions.priorityId, isSelected: actions.IsSelected, cardType: 'GN', customActionId: actions.customActionId, estCost: actions.estCost, rag: actions.rag, subDriverid: actions.subDriverid, modifiedDateTime: actions.modifiedDateTime
                        });
                    }
                }
            }
        }


        if (corporateCustomActionDetails != undefined) {
            for (let actions of corporateCustomActionDetails) {
                //this.growthPlanId = actions.growthPlanId;
                if (!this.corporateActionCards[actions.growthDriverId]) {
                    this.corporateActionCards[actions.growthDriverId] = [];
                }
                this.corporateActionCards[actions.growthDriverId].push({
                    id: actions.id, cardNumber: actions.cardNumber, title: actions.title, customTitle: actions.customTitle, driverTag: actions.growthDriverId, cardId: actions.cardId, xPos: actions.xPos, yPos: actions.yPos, quadrant: actions.actionPriority, isSelected: actions.IsSelected, cardType: 'Custom', customActionId: actions.customActionId, estCost: actions.estCost, rag: actions.rag, subDriverid: actions.subDriverid, participantAbbrivation: "SP"
                });


                if (actions.xPos != -1 || actions.IsSelected == false) {
                    if (this.isCorporate && !this.self) {
                        this.prioritizedActions.push({
                            id: actions.id, cardNumber: actions.cardNumber, title: actions.title, customTitle: actions.customTitle, driverTag: actions.growthDriverId, cardId: actions.cardId, xPos: this.calculateActualXPos(actions.xPos), xRatio: actions.xPos, yPos: this.calculateActualYPos(actions.yPos), yRatio: actions.yPos, quadrant: actions.actionPriority, isSelected: actions.IsSelected, cardType: 'Custom', customActionId: actions.customActionId, estCost: actions.estCost, rag: actions.rag, subDriverid: actions.subDriverid, modifiedDateTime: actions.modifiedDateTime
                        });
                    }
                }
            }
        }

        
        //for (let action in this.actionCards) {
        //    if (this.actionCards && this.actionCards[action] && this.actionCards[action].length) {
        //        addedActions = addedActions + this.actionCards[action].length;
        //    }
        //}
        //if (driverScored.length == 0 || addedActions == 0)
        //    this.showDialog();
        //
        //

        this.actionCount = 0;
        this.subDriverNonPrioritzedCount = 0;
        if (this.self && this.actionCards != undefined && this.actionCards[this.driverId] != undefined) {
            if (this.subDriverId) {
                this.subDriverAction = this.actionCards[this.driverId].filter(x => x.subDriverid == this.subDriverId && x.xPos == -1 && x.isSelected == true);
            } else {
                this.subDriverAction = this.actionCards[this.driverId].filter(x => x.driverTag == this.driverId && x.xPos == -1 && x.isSelected == true);
            }
            if (this.subDriverAction) {
                this.actionCount = this.subDriverAction.length;
                this.subDriverNonPrioritzedCount = this.subDriverAction.length;
            } //this.setBubblePositions();
        }

        if (this.isCorporate && !this.self && this.corporateActionCards != undefined && this.corporateActionCards[this.driverId] != undefined) {
            if (this.subDriverId) {
                this.subDriverAction = this.corporateActionCards[this.driverId].filter(x => x.subDriverid == this.subDriverId && x.xPos == -1 && x.isSelected == true);
            } else {
                this.subDriverAction = this.corporateActionCards[this.driverId].filter(x => x.driverTag == this.driverId && x.xPos == -1 && x.isSelected == true);
            }
            if (this.subDriverAction) {
                this.actionCount = this.subDriverAction.length;
                this.subDriverNonPrioritzedCount = this.subDriverAction.length;
            }
        }

        if (this.isCorporate && !this.self && this.subDriverAction != undefined && this.subDriverAction.length > 0) {
            if (Object.keys(this.selectedCard).length == 0)
                this.setParticipants(this.subDriverAction[0]);
            else
                this.setParticipants(this.selectedCard);

        }

        this.addCountBubble('');
        if (this.selectedCard.id !== undefined && this.isCorporate && !this.self) {
            let ac = Object.assign({}, this.selectedCard);
            ac['selected'] = true;
            this.onselectedCard(ac);
        }
    }

    addCountBubble(ev) {
       // setTimeout(() => {
            this.prioritizedActions.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                //return new Date(b.modifiedDateTime) - new Date(a.modifiedDateTime);

                let dateA = new Date(a.modifiedDateTime).getTime();
                let dateB = new Date(b.modifiedDateTime).getTime();
                return dateA > dateB ? 1 : -1;

                //return 1;
            });
            let previousPriorityList = Object.assign({}, this.newPrioritizedActions);
            this.newPrioritizedActions = {};
            this.countBubbleSelected = {};
            this.prioritizedActions.forEach((action, index) => {
                if (action.isSelected) {
                    //console.log(action);
                    let x = action.xPos;
                    let y = action.yPos;
                    let round = Math.round(x + Number.EPSILON).toString() + Math.round(y + Number.EPSILON).toString();
                    if (this.newPrioritizedActions[action.quadrant + 'test' + round] == undefined) {
                        this.newPrioritizedActions[action.quadrant + 'test' + round] = [];
                        this.countBubbleSelected[action.quadrant + 'test' + round] = false;
                        if (ev && ev.dragData.id == action.id) {
                            this.prioritizedActionsKeys.forEach((key) => {
                                previousPriorityList[key].forEach((a) => {
                                    if (ev && ev.dragData.id == a.id) {
                                        console.log(this.countBubbleSelected[a.quadrant + 'test' + round]);
                                        setTimeout(() => {
                                            let x = action.xPos;
                                            let y = action.yPos;
                                            let round = Math.round(x + Number.EPSILON).toString() + Math.round(y + Number.EPSILON).toString();
                                            this.countBubbleSelected[a.quadrant + 'test' + round] = true;
                                        })
                                    }
                                });
                            });
                        } else if (this.selectedCard.id != undefined && this.selectedCard.id == action.id) {
                            this.countBubbleHighlight[action.quadrant + 'test' + round] = action;
                        }
                    } else {
                        if (this.selectedCard.id != undefined && this.selectedCard.id == action.id) {
                            this.countBubbleHighlight[action.quadrant + 'test' + round] = action;
                        }
                    }
                    this.newPrioritizedActions[action.quadrant + 'test' + round].push(action);
                }

            });
            this.prioritizedActionsKeys = Object.keys(this.newPrioritizedActions);
            //console.log(this.prioritizedActionsKeys);
      //  }, 0);
    }

setActions(prioritised: boolean) {
    //if (prioritised) {
    //    //this.actionsToBePrioritized = Object.assign({}, this.actionCards);
    //    this.actionsToBePrioritized = {};//this.actionCards.filter(x => x.isSelected == true);

    //    for (let driver in this.actionCards) {
    //        var actions = this.actionCards[driver].filter(x => x.isSelected == true);
    //        if (actions && actions.length && actions.length > 0) {
    //            this.actionsToBePrioritized[driver] = this.sorted(actions);
    //            this.actionsToBePrioritized[driver].map(action =>
    //                action.open = false
    //            );
    //        }
    //        else {
    //            this.actionsToBePrioritized[driver] = [];
    //        }
    //    }
    //}
    //else {
    //    this.actionsToBePrioritized = {};
    //    for (let driver in this.actionCards) {
    //        var actions = this.actionCards[driver].filter(x => x.xPos == -1 && x.isSelected == true);
    //        if (actions && actions.length && actions.length > 0) {
    //            this.actionsToBePrioritized[driver] = this.sorted(actions);
    //            this.actionsToBePrioritized[driver].map(action =>
    //                action.open = false
    //            );
    //        }
    //        else {
    //            this.actionsToBePrioritized[driver] = [];
    //        }
    //    }
    //}
    //if (this.driverId) {
    //    this.scrollDriverToTop();
    //}
}

calculateActualXPos(xPos: number) {
    let Quadrant = this.quadrant.nativeElement.getElementsByClassName("first-box");
    var bubbleSize = this.getBubbleSize();
    var bubbleWidth = bubbleSize.width;
    let x = xPos * Quadrant[0].offsetWidth / 100;
    if (x + bubbleWidth > (Quadrant[0].offsetWidth - 1)) {
        x = Quadrant[0].offsetWidth - bubbleWidth - 1;
    }
    return x;

   
}
calculateActualYPos(yPos: number) {
    let Quadrant = this.quadrant.nativeElement.getElementsByClassName("first-box");
    var bubbleSize = this.getBubbleSize();
    var bubbleHeight = bubbleSize.height;
    var y = yPos * Quadrant[0].offsetHeight / 100;
    if (y + bubbleHeight > (Quadrant[0].offsetHeight - 1)) {
        y = Quadrant[0].offsetHeight - bubbleHeight - 1;
    }

    return y;
}

resizeLayout() {
        var otherSpace = 100;
      /*  if (this.isCorporate)
            otherSpace = 326;
    */
        var innerHeight = window.innerHeight - otherSpace;
        this.actionAreaWidth = this.actionArea.nativeElement.offsetWidth;
        var innerWidth = this.mainContainer.nativeElement.offsetWidth - 300;
        if (this.isCorporate && !this.self) {

            if (window.innerWidth > 1400)
                innerWidth = this.mainContainer.nativeElement.offsetWidth - 600;
            else
                innerWidth = this.mainContainer.nativeElement.offsetWidth - 500;

       }
        //if (window.innerWidth >= 1600) {
        //    innerHeight = window.innerHeight - 300;

        //}

        var usedConst = innerHeight;
        if (innerWidth < innerHeight) {
            usedConst = innerWidth;
        } 

            this.quadrantHeight = usedConst;
            this.quadrantAreaWidth = usedConst - 20;
      
            this.quadrantWidth = usedConst - 20;
    if (this.isCorporate && !this.self)
        this.newAreaWidth = this.mainContainer.nativeElement.offsetWidth - this.quadrantAreaWidth - 330;
    else
        this.newAreaWidth = this.mainContainer.nativeElement.offsetWidth - this.quadrantAreaWidth - 30;



            setTimeout(() => {
                this.calQuadrantHeight = this.quadrant.nativeElement.offsetHeight;
                if (this.isCorporate && !this.self) {
                    this.calQuadrantHeight = innerHeight -30;
                    this.quadrantHeight = innerHeight;
                    this.partcipantQuadrantWidth = this.calQuadrantHeight > 600 ? '100%' : (this.calQuadrantHeight - 300) + 'px';
                    if (window.innerWidth <= 1399 && window.innerHeight <= 650) {
                        this.partcipantWidth =this.quadrantContainer.nativeElement.offsetWidth - this.quadrantArea.nativeElement.offsetWidth - this.actionArea.nativeElement.offsetWidth;
                    } 
                        
                        
                }
            });
            setTimeout(() => {
                this.setBubblePositions();
                if (this.isCorporate && this.curentActionCard && this.selectedPrioCorpActions.length > 0) {
                    this.setParticipantsBubble();
                }
            }, 100);
        
}
    getbubbleClass(fromParticipant?: boolean) {
        let qWidth = this.quadrant.nativeElement.offsetHeight;
        if (fromParticipant && this.PQuadrant1) {
            qWidth = this.PQuadrant1.nativeElement.offsetHeight;
        }
        if (qWidth >= 700) {
            return 'ex-ex-large-bubble';
        } else if (qWidth >= 600 && qWidth < 700) {
            return 'ex-large-bubble';
        } else if (qWidth >= 500 && qWidth < 600) {
            return 'large-bubble';
        } else if (qWidth >= 400 && qWidth < 500) {
            return 'medium-bubble';
        }
        else if (qWidth >= 300 && qWidth < 400) {
            return 'small-bubble';
        } else {
            return 'ex-small-bubble';
        }
       
}

    getParticipantBubbleClass() {
        let qWidth = this.quadrant.nativeElement.offsetHeight;
        if (qWidth >= 700) {
            return 'ex-ex-large-bubble';
        } else if (qWidth >= 600 && qWidth < 700) {
            return 'ex-large-bubble';
        } else if (qWidth >= 500 && qWidth < 600) {
            return 'large-bubble';
        } else if (qWidth >= 400 && qWidth < 500) {
            return 'medium-bubble';
        }
        else if (qWidth >= 300 && qWidth < 400) {
            return 'small-bubble';
        } else {
            return 'ex-small-bubble';
        }

    }
    @HostListener('window:resize') onResize() {
        this.resizeLayout();       
        


    }
    setBubblePositions() {
        var offSet = this.utilService.getOffset(this.quadrant.nativeElement.getElementsByClassName("first-box")[0]);
        var bubbleSize = this.getBubbleSize();
        var bubbleWidth = bubbleSize.width;
        var bubbleHeight = bubbleSize.height;
       // var bubbleOffsetDistance = isExpand ? 5 * 2 : 5;
       for (let action of this.prioritizedActions) {
            action.xPos = action.xRatio * offSet.width / 100;
           action.yPos = action.yRatio * offSet.height / 100;

           if (action.xPos + bubbleWidth > (offSet.width - 1)) {
               action.xPos = offSet.width - bubbleWidth - 1;
           }


           if (action.yPos + bubbleHeight > (offSet.height - 1)) {
               action.yPos = offSet.height - bubbleHeight - 1;
           }

        }

        this.addCountBubble('');
    }

    //setParticipantBubblePositions() {
    //    var offSet = this.utilService.getOffset(this.quadrant.nativeElement.getElementsByClassName("first-box")[0]);
    //    for (let action of this.prioritizedActions) {
    //        action.xPos = action.xRatio * offSet.width / 100;
    //        action.yPos = action.yRatio * offSet.height / 100;
    //    }
    //}

    ngAfterViewInit() {
        this.resizeLayout();
    }
    changeList(type: number) {
        this.selectedType = type;

    }

    

    onDragStart(ev: any) {
        //console.log(ev.mouseEvent.offsetX, ev.mouseEvent.offsetY)
        this.highLightBubbles = "";
        this.mouseOffset.x = ev.mouseEvent.offsetX;
        this.mouseOffset.y = ev.mouseEvent.offsetY;
        this.draggedFrom = 0;
    }

    quadrantBubbleDragStart(ev: any) {
        this.highLightBubbles = "";
        this.mouseOffset.x = ev.mouseEvent.offsetX;
        this.mouseOffset.y = ev.mouseEvent.offsetY;
        this.draggedFrom = 1;
    }
    getBubbleSize(fromParticipant?: boolean) {
        //var innerWidth = window.innerWidth;
        //var innerHeight = window.innerHeight;

        var height = 22;
        var width = 43;
        //if (innerWidth <= 1199 || innerHeight <= 768) {
        //    height = 14;
        //    width = 24;
        //}
        let qWidth = this.quadrant.nativeElement.offsetHeight;
        if (fromParticipant && this.PQuadrant1) {
            qWidth = this.PQuadrant1.nativeElement.offsetHeight;
        }
        if (qWidth >= 700) {
            height = 25;
            width = 41;
        } else if (qWidth >= 600 && qWidth < 700) {
            height = 22;
            width = 33;
        } else if (qWidth >= 500 && qWidth < 600) {
            height = 20;
            width = 33;
        } else if (qWidth >= 400 && qWidth < 500) {
            height = 14;
            width = 22;
        }
        else if (qWidth >= 300 && qWidth < 400) {
            height = 12;
            width = 20;
        } else {
            height = 10;
            width = 15.2;
        }
       
        //else {
        //    height = 14;
        //    width = 24;
        //}
        return { height: height, width: width };
    }
    checkIfDroppable(ev: any, target: any, isExpand: boolean) {
        var droppable: false;
        var isDroppable = { droppable: false, x: 0, y: 0, xRatio: 0, yRatio: 0 };
        var currentAction = ev.dragData;
        if (this.draggedFrom == 0 && currentAction.quadrant != 0) {
            return { droppable: false, x: 0, y: 0, xRatio: 0, yRatio: 0 };
        }
        var quadrant = currentAction.quadrant;
        var targetElement = ev.mouseEvent.target;
        var x = ev.mouseEvent.offsetX;
        var y = ev.mouseEvent.offsetY;

        var offSet = this.utilService.getOffset(targetElement);
        var bubbleSize = this.getBubbleSize();
        var bubbleWidth = isExpand ? bubbleSize.width * 2 : bubbleSize.width;
        var bubbleHeight = isExpand ? bubbleSize.height * 2 : bubbleSize.height;
       // var bubbleOffsetDistance = isExpand ? 5 * 2 : 5;
        let qWidth = this.quadrant.nativeElement.offsetHeight;


        var bubbleOffsetDistance = isExpand ? 1 * 2 : 1;

        //if (qWidth < 300) {
        //    bubbleOffsetDistance = isExpand ? 1 : 0;
        //}


        x = ev.mouseEvent.offsetX - (this.mouseOffset.x || 0);
        y = ev.mouseEvent.offsetY - (this.mouseOffset.y || 0);


        if (x < 0) {
            x = 0;
        }
        else if (x + bubbleWidth > (offSet.width - 1)) {
            x = offSet.width - bubbleWidth - 1;
        }

        if (y < 0) {
            y = 0;
        }
        else if (y + bubbleHeight > (offSet.height - 1)) {
            y = offSet.height - bubbleHeight - 1;
        }

        //check if bubble dropping on tooltip itself
        let result = document.getElementsByClassName("tooltiptext");
        let r = result[0] as HTMLElement;
        //console.log(r);
        let height, width, top, left, bubbleLeft, bubbleTop, bHeight, tTop, tLeft;
        let sameQ = false;
        if (r != undefined && r != null) {
            let tooltipqClass = r.parentNode.parentNode.parentNode.parentNode.parentElement.className.split(' ');
            console.log(tooltipqClass);
            let tooltipQ = 4;
            if (tooltipqClass.indexOf('second-box') >= 0) {
                tooltipQ = 2;
            } else if (tooltipqClass.indexOf('first-box') >= 0) {
                tooltipQ = 1;
            } else if (tooltipqClass.indexOf('third-box') >= 0) {
                tooltipQ = 3;
            }

            let target = targetElement.classList;
            let newQuadrant = 4;
            if (target.contains('second-box')) {
                newQuadrant = 2;
            } else if (target.contains('first-box')) {
                newQuadrant = 1;
            } else if (target.contains('third-box')) {
                newQuadrant = 3;
            }
            if (tooltipQ == newQuadrant) {
                sameQ = true;
            }
            console.log(sameQ);
        }
        if (r != undefined && r != null && sameQ) {
            let classList = r.classList;
            height = r.offsetHeight;
            width = r.offsetWidth;
            top = r.offsetTop;
            left = r.offsetLeft;
            let bubble = document.getElementById('bubble' + r.id);
            let zoomBubble = document.getElementById('bubblezoom-view' + r.id);
            if (bubble != null) {
                bubbleLeft = bubble.offsetLeft;
                bubbleTop = bubble.offsetTop;
                bHeight = bubble.offsetHeight;
            } else {
                bubbleLeft = zoomBubble.offsetLeft;
                bubbleTop = zoomBubble.offsetTop;
                bHeight = zoomBubble.offsetHeight;
            }
            tTop = bubbleTop + bHeight;
            tLeft = bubbleLeft;
            let topAndHeight = tTop + height;
            let leftAndWidth = tLeft + width;
            if (classList.contains('top')) {
                if (classList.contains('right-arrow')) {
                    leftAndWidth = tLeft;
                    tLeft = tLeft - width;
                    topAndHeight = bubbleTop - 10;
                    tTop = bubbleTop - height - 5;
                } else {
                    topAndHeight = bubbleTop - 12;
                    tTop = bubbleTop - height - 5;
                }
            } else {
                if (classList.contains('right-arrow')) {
                    leftAndWidth = tLeft;
                    tLeft = tLeft - width;
                }
            }
            if (x > tLeft && x < leftAndWidth) {
                if (y > tTop && y < topAndHeight) {
                    return { droppable: false, x: 0, y: 0, xRatio: 0, yRatio: 0 };
                }
            }
        }


        //check if x and y near to any of the old bubbles
        let breakI = false;
        this.prioritizedActionsKeys.forEach((actionsKey) => {
            if (!breakI) {

                this.newPrioritizedActions[actionsKey].forEach((action, actionKey) => {
                    if (!breakI) {
                        let bubbleX = action.xPos;
                        let bubbleY = action.yPos;
                        let round = Math.round(bubbleX + Number.EPSILON) + Math.round(bubbleY + Number.EPSILON);
                        let currentBubbleRound = Math.round(x + Number.EPSILON) + Math.round(y + Number.EPSILON);
                        let sub = round - currentBubbleRound;
                        let subX = x - bubbleX;
                        let subY = y - bubbleY;
                        if (target == action.quadrant && subX <= 3 && subX >= -3 && subY <= 3 && subY >= -3) {
                            x = bubbleX;
                            y = bubbleY;
                            breakI = true;
                        }
                    }

                });
            }

        });


        //Prevent Overlapping of bubbles
        //var actionsInQuadrant = this.prioritizedActions.filter((x: any) => x.quadrant == target && x.isSelected == true && x.id !== currentAction.id);
        //if (actionsInQuadrant && actionsInQuadrant.length) {
        //    for (let action of actionsInQuadrant) {

        //        if ((x + bubbleWidth > action.xPos - bubbleOffsetDistance && x + bubbleWidth < action.xPos + bubbleWidth + bubbleOffsetDistance) && (y + bubbleHeight > action.yPos - bubbleOffsetDistance && y + bubbleHeight < action.yPos + bubbleHeight + bubbleOffsetDistance)) {
        //            return { droppable: false, x: 0, y: 0, xRatio: 0, yRatio: 0 };
        //        }
        //        else if ((x > action.xPos - bubbleOffsetDistance && x < action.xPos + bubbleWidth + bubbleOffsetDistance) && (y + bubbleHeight > action.yPos - bubbleOffsetDistance && y + bubbleHeight < action.yPos + bubbleHeight - bubbleOffsetDistance)) {
        //            return { droppable: false, x: 0, y: 0, xRatio: 0, yRatio: 0 };
        //        }
        //        else if ((x + bubbleWidth > action.xPos - bubbleOffsetDistance && x + bubbleWidth < action.xPos + bubbleWidth + bubbleOffsetDistance) && (y > action.yPos - bubbleOffsetDistance && y < action.yPos + bubbleHeight + bubbleOffsetDistance)) {
        //            return { droppable: false, x: 0, y: 0, xRatio: 0, yRatio: 0 };
        //        }
        //        else if ((x > action.xPos - bubbleOffsetDistance && x < action.xPos + bubbleWidth + bubbleOffsetDistance) && (y > action.yPos - bubbleOffsetDistance && y < action.yPos + bubbleHeight + bubbleOffsetDistance)) {
        //            return { droppable: false, x: 0, y: 0, xRatio: 0, yRatio: 0 };
        //        }

        //    }
        //}

        //Commenting as the new plugin, after changing the scale to width and height transitions, returns actia X, Y positions
        //if (isExpand) {
        //    var xRatio = x * 2 / offSet.width * 100;
        //  1  var yRatio = y * 2 / offSet.height * 100;
        //} else {
        var xRatio = x / offSet.width * 100;
        var yRatio = y / offSet.height * 100;
        //}


        return { droppable: true, x: x, y: y, xRatio: xRatio, yRatio: yRatio };

    }

    isDraggable(action: any) {
        let isDraggable: boolean;
    
        isDraggable = this.isLocked ? false : true;
        //var isAlreadyaddedAction = this.prioritizedActions.filter((x: any) => x.id == action.id && x.isSelected == true && x.xPos>1);
        //if (isAlreadyaddedAction && isAlreadyaddedAction.length && isAlreadyaddedAction.length > 0) {
        //    //if (isAlreadyaddedAction[0].xPos > -1) {
        //    isDraggable = false;
        //    //}
        //}
        
        //if (this.isCorporate) {
        //    if (this.self) {
        //        if (this.noSelfAccessed) {
        //            isDraggable = false;
        //        }
        //    } else {
        //        if (this.noTeamAccessed) {
        //            isDraggable = false;
        //        }
        //    }
        //}
        return isDraggable;
    }

    onBubbleDropped(ev: any, target: any, isExpand?: boolean) {

        // restrict the readonly growth plan changes
        if (this.isLocked) {
            return;
        }

        var x = ev.mouseEvent.offsetX;
        var y = ev.mouseEvent.offsetY;
        var xRatio;
        var yRatio;
        if (isExpand) {
            var ifDroppable = this.checkIfDroppable(ev, target, true);
        } else {
            var ifDroppable = this.checkIfDroppable(ev, target, false);
        }
        if (!ifDroppable.droppable) {
            //ev.mouseEvent.preventDefault();
            return;
        }
        else {
            x = ifDroppable.x;
            y = ifDroppable.y;
            xRatio = ifDroppable.xRatio;
            yRatio = ifDroppable.yRatio;

        }
        this.selectedCard = {};


        //var x = ev.mouseEvent.offsetX;
        //var y = ev.mouseEvent.offsetY;

        var currentAction = ev.dragData;
        var actionAlreadyPresent = this.prioritizedActions.filter((x: any) => x.id == currentAction.id);
        if (actionAlreadyPresent && actionAlreadyPresent.length && actionAlreadyPresent.length > 0) {
            actionAlreadyPresent[0].xPos = x;
            actionAlreadyPresent[0].yPos = y;
            actionAlreadyPresent[0].xRatio = xRatio;
            actionAlreadyPresent[0].yRatio = yRatio;
            actionAlreadyPresent[0].quadrant = target;

        }
        else {
            currentAction.xPos = x;
            currentAction.yPos = y;
            currentAction.xRatio = xRatio;
            currentAction.yRatio = yRatio;
            currentAction.quadrant = target;
            this.prioritizedActions.push(currentAction);
        }
        var currentActionInList;
        if (this.actionCards[currentAction.driverTag]) {
            currentActionInList = this.actionCards[currentAction.driverTag].filter((x: any) => x.id == currentAction.id);
        } else if (this.corporateActionCards[currentAction.driverTag]) {
            currentActionInList = this.corporateActionCards[currentAction.driverTag].filter((x: any) => x.id == currentAction.id);

        }
        if (currentActionInList && currentActionInList.length && currentActionInList.length > 0) {
            currentActionInList[0].xPos = x;
            currentActionInList[0].yPos = y;
            currentActionInList[0].xRatio = xRatio;
            currentActionInList[0].yRatio = yRatio;
            currentActionInList[0].quadrant = target;

        }
        this.setActions(this.selectedType == 1 ? true : false);

        this.mouseOffset = {};
        var subList = this.subDriverAction.filter((x: any) => x.id == currentAction.id);
        if (subList && subList.length && subList.length > 0) {
            subList[0].xPos = x;
            subList[0].yPos = y;
            subList[0].xRatio = xRatio;
            subList[0].yRatio = yRatio;
            subList[0].quadrant = target;

        }
        if (this.subDriverAction) {
            var proAction = this.subDriverAction.filter((x: any) => x.xPos <= -1)
            if (proAction)
                this.subDriverNonPrioritzedCount = proAction.length;

        }
        this.onSaveBubble(currentAction);
        this.updateCurrentBubble(currentAction);
        this.addCountBubble('');
    }

    updateCurrentBubble(currentBubble) {
        let action = this.prioritizedActions.filter((x: any) => x.id == currentBubble.id);
        let actionToPush = JSON.parse(JSON.stringify(action))
        //console.log(JSON.stringify(this.prioritizedActions));
        //console.log(JSON.stringify(currentBubble));
        //console.log(JSON.stringify(action));
        //console.log(JSON.stringify(actionToPush));
        let index = this.prioritizedActions.findIndex(x => x.id == currentBubble.id);
        this.prioritizedActions.splice(index, 1);

        let currentdate = new Date();
        let hours = currentdate.getHours();
        let amPm = 'AM';
        if (hours >= 12) {
            amPm = 'PM';
            hours = hours - 12;

        }

        let datetime = (currentdate.getMonth() + 1) + "/"
            + currentdate.getDate() + "/"
            + currentdate.getFullYear() + " "
            + hours + ":"
            + currentdate.getMinutes() + ":"
            + currentdate.getSeconds() + " "
            + amPm;
        currentBubble.modifiedDateTime = datetime;

        this.prioritizedActions.push(currentBubble);
    }
 
    getClassName(action: any) {
        let className = '';
        if (action.rag == 'R') className = 'rag_red';
        else if (action.rag == 'A') className = 'rag_amber';
        else if (action.rag == 'G') className = 'rag_green';
        else className = 'no_rag';
        if (this.selectedCard && action.cardNumber == this.selectedCard.cardNumber) {
            className = 'sel_bubble ' + className;
        }
        return className
    }

    getBorderClassName(action: any) {
        let className = '';
        if (action.rag && action.rag != "") {
            if (action.rag == 'R') className = 'red';
            else if (action.rag == 'A') className = 'amber';
            else if (action.rag == 'G') className = 'green';
        } else {
            if (action.xPos > -1 && action.isSelected) {
                className = 'priortized';
            }
        }
        if (this.selectedCard && action.cardNumber == this.selectedCard.cardNumber) {
            className += ' sel_card';
        }
        return className;
    }

    onClickandHoldBubble(card) {

        this.highLightBubbles = "";
        if (card != "") {
            this.highLightBubbles = card.driverTag;
        }
    }

    onSaveBubble(currentAction:any) {
        let main = this;
        let saveGrowthPlanActionPrioritize: SaveGrowthPlanActionPrioritize[] = [];
        //console.log('continue :', this.prioritizedActions);
        var priAct = this.prioritizedActions.filter((x: any) => x.id == currentAction.id)[0];
        if (priAct) {
            var xCordinate = 0;
            var yCordinate = 0;
            if (priAct.xRatio && priAct.yRatio) {
                xCordinate = priAct.xRatio / 20;
                yCordinate = 5 - priAct.yRatio / 20;
                if (priAct.quadrant == 2 || priAct.quadrant == 4)
                    xCordinate += 5;
                if (priAct.quadrant == 1 || priAct.quadrant == 2)
                    yCordinate += 5;
            }   
            if (this.isCorporate)
            {
                if (this.self) {
                    let saveList = new SaveGrowthPlanActionPrioritize(priAct.id, this.CorporateSelfGrowthPlanId, priAct.isSelected, priAct.quadrant, Math.round(priAct.xRatio), Math.round(priAct.yRatio), xCordinate, yCordinate);
                    saveGrowthPlanActionPrioritize.push(saveList);
                }
                else {
                    let saveList = new SaveGrowthPlanActionPrioritize(priAct.id, this.growthPlanId, priAct.isSelected, priAct.quadrant, Math.round(priAct.xRatio), Math.round(priAct.yRatio), xCordinate, yCordinate);
                    saveGrowthPlanActionPrioritize.push(saveList);
                }
            } else {
                let saveList = new SaveGrowthPlanActionPrioritize(priAct.id, this.growthPlanId, priAct.isSelected, priAct.quadrant, Math.round(priAct.xRatio), Math.round(priAct.yRatio), xCordinate, yCordinate);
                console.log(saveList);
                saveGrowthPlanActionPrioritize.push(saveList);
            }
            

        }

        console.log(saveGrowthPlanActionPrioritize);

        //New Change
        var actionsModel: SavePrioritizeActionsModel;
        if (this.isCorporate) {
            if (this.self) {
                actionsModel = new SavePrioritizeActionsModel(-1, this.CorporateSelfGrowthPlanId, saveGrowthPlanActionPrioritize, this.IsDriverAssessed);
            } else {
                actionsModel = new SavePrioritizeActionsModel(-1, this.growthPlanId, saveGrowthPlanActionPrioritize, this.IsDriverAssessed);
            }
        }
        else {
            actionsModel = new SavePrioritizeActionsModel(-1, this.growthPlanId, saveGrowthPlanActionPrioritize, this.IsDriverAssessed);
        }



        //To save the Actions
        this.velocityService.saveGrowthPlanActionPrioritize(actionsModel).subscribe(
            (data: any) => {
                if (data) {
                    if (this.driverId) {
                        this.logGrowthPlanModifiedPlusDriverEvent(this.growthPlanId, +this.driverId);
                    }
                    this.selectedCard = currentAction;
                    if (this.isCorporate) {
                        this.setParticipants(currentAction);
                    }
                    if (this.isCorporate && this.selectedPrioCorpActions && this.selectedPrioCorpActions.length == 1) {
                     
                       // let isSelected = actionsModel.actions.filter(x => x.id == this.selectedPrioCorpActions[0].id)[0];

                        if (this.selectedPrioCorpActions[0].team && (priAct.id == this.selectedPrioCorpActions[0].id)) {
                            if (priAct) {
                                this.curentActionCard.xRatio = priAct.xRatio;
                                this.curentActionCard.yRatio = priAct.yRatio;
                            }
                            this.setParticipants(this.curentActionCard,false,false,'fromSave');
                        }
                    }
                    //this.router.navigate(["focus","seven-drivers"]);

                } else {
                    main.utilService.showError("Error in saving");
                }
            });

    }
    logGrowthPlanModifiedPlusDriverEvent(growthPlanId: number, driverId: number) {
        let log = new LogAction();
        log.action = this.logTypes.GROWTH_PLAN_MODIFIED;
        log.growthPlanId = growthPlanId;
        log.driverId = driverId;
        this.logService.logAction(log);
    }
   /* getHeaderWidth() {
        console.log(this.quadrantArea.nativeElement.offsetWidth);
        console.log(this.actionArea.nativeElement.offsetWidth);
        if (this.isCorporate && !this.self && window.innerWidth > 1500) {
            return this.quadrantArea.nativeElement.offsetWidth + this.actionArea.nativeElement.offsetWidth + 28 + 450;

        }
        return this.quadrantArea.nativeElement.offsetWidth + this.actionArea.nativeElement.offsetWidth + 28;

    }*/
    onselectedCard(card) {
       
        this.selectedCard = card;
        // Set participant details for that card
        this.setParticipants(card,false,false,'fromFilter');
        setTimeout(() => {
            this.setSelectedCardScrollPostion(card.cardNumber);
        }, 500);
    }

    setSelectedCardScrollPostion(cardNumber) {

        let elem: any = document.getElementById("actionCard" + cardNumber);
        if (elem) {
          let topPos = elem.offsetTop - 11;
          this.listCardPos = topPos.toString();
          //setTimeout(() => {
            //    if (document.getElementById("mCSB_1_container")) {
            //        let pos = elem.offsetTop - 10;
            //        document.getElementById("mCSB_1_container").style.top = '-' + pos + 'px';
            //    }
            //}, 500);
        }


    }

    setParticipants(action, realTimeUpdate?: boolean, fromRefresh?: boolean, fromSave = '') {
        let returnFrom = false;
        if (fromSave != 'fromSave') {
            this.countBubbleSelected = {};
            this.countBubbleHighlight = {};
            this.hideShowTooltip = false;
            this.prioritizedActionsKeys.forEach((key) => {
                this.countBubbleSelected[key] = false;
                this.countBubbleHighlight[key] = false;
                if (this.newPrioritizedActions[key].length > 1) {
                    this.newPrioritizedActions[key].forEach((act) => {
                        if (action.id == act.id) {
                            //this.countBubbleSelected[key] = act;
                            this.countBubbleHighlight[key] = act;
                            returnFrom = true;
                        }
                    });
                }
            });
        }
        
        this.selectedCard = action;
        this.curentActionCard = action;
        if (!realTimeUpdate || fromRefresh) {
            this.selectedPartcipant = '';
        }
        this.selectedCard = action;
        if (returnFrom && fromSave != 'fromFilter') {
            return false;
        }
        if (this.isCorporate && !this.self) {

            this.selectedCardNumber = action.cardNumber;
            this.selectedTitle = action.customTitle ? action.customTitle : action.title;
            var participantPrioModel: ParticipantPrioritizeModel;
            let isCustomAction;
            if (action.customActionId != undefined && action.customActionId != '') {
                isCustomAction = 1;
            }
            if (isCustomAction == 1) {
                participantPrioModel = new ParticipantPrioritizeModel(-1, this.growthPlanId, 2, action.customActionId);
            }
            else {
                participantPrioModel = new ParticipantPrioritizeModel(-1, this.growthPlanId, 1, action.cardId);
            }
           /* var dummy = [
                {
                    "userName": "Evangelin Prabha", "pid":1,"id": 8660, "type": 2, "title": "ACTIONS", "bodyFormatted": "Align performance management with business strategy", "body": "", "subTitle": "", "description": "Align performance management with business strategy", "cardTypeName": "ACTIONS",
                    "active":false,"zValue":101, "driverActionId": 108, "growthPlanId": 772, "growthPlanActionId": 0, "cardId": 2007, "driverId": 1, "IsSelected": true, "IsDeleted": false, "xPos": 67.0, "yPos": 29.0, "priorityId": 2, "rag": "", "estCost": 0.0, "actCost": 0.0, "customTitle": "", "xCoordinate": 8.32718879945817, "yCoordinate": 8.52995384673369, "subDriverid": 0, "ownerName": "", "startDate": null, "completionDate": null, "note": null, "participants": null
                },
                {
                    "userName": "Sandeep Patil", "pid":2,"id": 8661, "type": 2, "title": "ACTIONS", "bodyFormatted": "Reward innovation", "body": "", "subTitle": "", "description": "Reward innovation", "cardTypeName": "ACTIONS",
                    "active": false,"zValue": 101,"driverActionId": 112, "growthPlanId": 772, "growthPlanActionId": 0, "cardId": 2011, "driverId": 1, "IsSelected": true, "IsDeleted": false, "xPos": 66.0, "yPos": 63.0, "priorityId": 2, "rag": "", "estCost": 0.0, "actCost": 0.0, "customTitle": "", "xCoordinate": 8.28110585014941, "yCoordinate": 6.84792619696411, "subDriverid": 0, "ownerName": "", "startDate": null, "completionDate": null, "note": null, "participants": null
                },
                {
                    "userName": "Anoop Vijakumar", "pid": 3, "id": 8662, "type": 2, "title": "ACTIONS", "bodyFormatted": "Develop clear leadership and management succession plan", "body": "", "subTitle": "", "description": "Develop clear leadership and management succession plan", "cardTypeName": "ACTIONS",
                    "active": false,"zValue": 101,"driverActionId": 118, "growthPlanId": 772, "growthPlanActionId": 0, "cardId": 2017, "driverId": 1, "IsSelected": true, "IsDeleted": false, "xPos": 29.0, "yPos": 58.0, "priorityId": 2, "rag": "", "estCost": 0.0, "actCost": 0.0, "customTitle": "", "xCoordinate": 6.43778787779918, "yCoordinate": 7.10138241816226, "subDriverid": 0, "ownerName": "", "startDate": null, "completionDate": null, "note": null, "participants": null
                },
                {
                    "userName": "Abijith senan", "pid": 4,"id": 8663, "type": 2, "title": "ACTIONS", "bodyFormatted": "Develop clear inspirational vision and strategy, and communicate both internally and externally", "body": "", "subTitle": "", "description": "Develop clear inspirational vision and strategy, and communicate both internally and externally", "cardTypeName": "ACTIONS",
                    "active": false,"zValue": 101,"driverActionId": 119, "growthPlanId": 772, "growthPlanActionId": 0, "cardId": 2018, "driverId": 1, "IsSelected": true, "IsDeleted": false, "xPos": 68.0, "yPos": 30.0, "priorityId": 2, "rag": "", "estCost": 0.0, "actCost": 0.0, "customTitle": "", "xCoordinate": 8.32718879945817, "yCoordinate": 8.52995384673369, "subDriverid": 0, "ownerName": "", "startDate": null, "completionDate": null, "note": null, "participants": null
                },
                {
                    "userName": "Second Name", "pid": 5,"id": 8662, "type": 2, "title": "ACTIONS", "bodyFormatted": "Develop clear leadership and management succession plan", "body": "", "subTitle": "", "description": "Develop clear leadership and management succession plan", "cardTypeName": "ACTIONS",
                    "active": false,"zValue": 101, "driverActionId": 118, "growthPlanId": 772, "growthPlanActionId": 0, "cardId": 2017, "driverId": 1, "IsSelected": true, "IsDeleted": false, "xPos": 29.0, "yPos": 58.0, "priorityId": 2, "rag": "", "estCost": 0.0, "actCost": 0.0, "customTitle": "", "xCoordinate": 6.43778787779918, "yCoordinate": 7.10138241816226, "subDriverid": 0, "ownerName": "", "startDate": null, "completionDate": null, "note": null, "participants": null
                },
                {
                    "userName": "Third Name", "pid": 6,"id": 8663, "type": 2, "title": "ACTIONS", "bodyFormatted": "Develop clear inspirational vision and strategy, and communicate both internally and externally", "body": "", "subTitle": "", "description": "Develop clear inspirational vision and strategy, and communicate both internally and externally", "cardTypeName": "ACTIONS",
                    "active": false,"zValue": 101, "driverActionId": 119, "growthPlanId": 772, "growthPlanActionId": 0, "cardId": 2018, "driverId": 1, "IsSelected": true, "IsDeleted": false, "xPos": 68.0, "yPos": 30.0, "priorityId": 2, "rag": "", "estCost": 0.0, "actCost": 0.0, "customTitle": "", "xCoordinate": 8.32718879945817, "yCoordinate": 8.52995384673369, "subDriverid": 0, "ownerName": "", "startDate": null, "completionDate": null, "note": null, "participants": null
                }
            ];
                  */
            let service = this.velocityService.getParticipantPrioritization(participantPrioModel);
            if (realTimeUpdate) {
                this.refreshDisabled = true;
                service = this.velocityService.getParticipantPrioritization(participantPrioModel, ProgressBarHelper.NoProgressBar);
            }
            service.subscribe(
                (data) => {
                    let bubbleSize = this.getBubbleSize(true);
                    let bubbleWidth = bubbleSize.width;
                    let bubbleHeight = bubbleSize.height;
                    var offSet = this.utilService.getOffset(this.participantArea.nativeElement.getElementsByClassName("first-box")[0]);
                    if (data.length > 0){
                    if (JSON.stringify(data) !== JSON.stringify(this.oldRealJsonVAlue) || JSON.stringify(this.selectedCard) !== JSON.stringify(this.oldSelectedCard)) {
                        this.oldRealJsonVAlue = data;
                        this.oldSelectedCard = this.selectedCard;
                        this.selectedPrioCorpActions = [];
                        let orgValues = data;
                        orgValues.map((obj) => {
                            obj.active = false;
                            obj.zValue = 101;
                            return obj;
                        });
                        //   var offSet = this.utilService.getOffset(targetElement);
                        
                        // var bubbleOffsetDistance = isExpand ? 5 * 2 : 5;
                        let qWidth = this.quadrant.nativeElement.offsetHeight;


                        var bubbleOffsetDistance = 1;
                        var zValue = 100;
                        for (let org of orgValues) {
                            org.xRatio = org.xPos;
                            org.yRatio = org.yPos;
                            org.xPos = org.xRatio * offSet.width / 100;
                            org.yPos = org.yRatio * offSet.height / 100;

                            for (let action of this.selectedPrioCorpActions) {
                                if (org.xPos > -1 && org.yPos > -1) {
                                    if ((org.xPos + bubbleWidth > action.xPos - bubbleOffsetDistance && org.xPos + bubbleWidth < action.xPos + bubbleWidth + bubbleOffsetDistance) && (org.yPos + bubbleHeight > action.yPos - bubbleOffsetDistance && org.yPos + bubbleHeight < action.yPos + bubbleHeight + bubbleOffsetDistance)) {
                                        org.zValue = zValue;
                                        zValue--;
                                    }
                                    else if ((org.xPos > action.xPos - bubbleOffsetDistance && org.xPos < action.xPos + bubbleWidth + bubbleOffsetDistance) && (org.yPos + bubbleHeight > action.yPos - bubbleOffsetDistance && org.yPos + bubbleHeight < action.yPos + bubbleHeight - bubbleOffsetDistance)) {
                                        org.zValue = zValue;
                                        zValue--;
                                    }
                                    else if ((org.xPos + bubbleWidth > action.xPos - bubbleOffsetDistance && org.xPos + bubbleWidth < action.xPos + bubbleWidth + bubbleOffsetDistance) && (org.yPos > action.yPos - bubbleOffsetDistance && org.yPos < action.yPos + bubbleHeight + bubbleOffsetDistance)) {
                                        org.zValue = zValue;
                                        zValue--;
                                    }
                                    else if ((org.xPos > action.xPos - bubbleOffsetDistance && org.xPos < action.xPos + bubbleWidth + bubbleOffsetDistance) && (org.yPos > action.yPos - bubbleOffsetDistance && org.yPos < action.yPos + bubbleHeight + bubbleOffsetDistance)) {
                                        org.zValue = zValue;
                                        zValue--;
                                    }
                                }
                            };
                            this.selectedPrioCorpActions.push(org);
                        }
                       
                        if (this.selectedPartcipant) {
                            this.selectAction(this.selectedPartcipant);
                        }
                        console.log(this.selectedPrioCorpActions);
                        }
                    } else {
                        this.selectedPrioCorpActions = [];
                    if(action.isSelected && action.xPos > -1 && action.yPos > -1) {
                        let org = JSON.parse(JSON.stringify(action));
                        org.participantAbbrivation = this.OtherContents.growthPlanSession.lblTText;
                        org.team = true;
                        if ((action.xRatio || action.xRatio == 0) && (action.yRatio || action.yRation == 0)) {
                            org.xPos = action.xRatio * offSet.width / 100;
                            org.yPos = action.yRatio * offSet.height / 100;
                           
                            if ((org.xPos + bubbleWidth) > offSet.width) {
                                org.xPos = offSet.width - bubbleWidth;
                            }
                            if ((org.yPos + bubbleHeight) > offSet.height) {
                                org.yPos = offSet.height - bubbleHeight;
                            }
                        }
                        org.priorityId = action.quadrant;
                        this.selectedPrioCorpActions.push(org);

                        }
                    
                    }
                    if (fromSave != 'fromFilter') {
                        this.resizeLayout();
                    }
                    if (realTimeUpdate) {
                        this.refreshDisabled = false;
                        if (fromRefresh) {
                            if (this.showRefresh && !this.self && !this.timerOn) {
                                this.startTimer();
                            }
                        }
                    }
                });
        }
    }
    selectAction(action) {
        this.selectedPartcipant = action;
        this.selectedPrioCorpActions.map(action =>
            action.active = false
              );
        this.selectedPrioCorpActions.filter(x => x.participantId == action.participantId)[0].active = true;
        this.topPartPos = "0px";
        setTimeout(() => {
            this.topPartPos = "";
        }); 
    }

    startTimer() {
        let canTimerOn = true;
        let timeValue = 30000;

        if (this.SiteContents.corporateRealTimeSync && this.SiteContents.corporateRealTimeSync.toLowerCase() != 'on')
            canTimerOn = false;
        if (this.SiteContents.corporateRealTimeSyncTimer && parseInt(this.SiteContents.corporateRealTimeSyncTimer)) {
            timeValue = parseInt(this.SiteContents.corporateRealTimeSyncTimer);
        }
        if (canTimerOn && timeValue) {
            this.timerOn = true;
            this.timeOutWarningTimer = timer(timeValue, timeValue);
            var time = 1;
            var apiCall;
            this.timeoutSubscribers = this.timeOutWarningTimer.subscribe(tick => {
                this.setParticipants(this.selectedCard, true, false);  
            });

        }

    }
    stopTimer() {
        console.log('stop');
        this.timerOn = false;
        if (this.timeoutSubscribers && !this.timeoutSubscribers.closed) {
            this.timeoutSubscribers.unsubscribe();
        }
    }

    ngOnDestroy() {
        console.log('destroy');
        this.stopTimer();

    }
    callRefresh() {
        this.stopTimer();
        this.setParticipants(this.selectedCard, true, true);  
    }
    setParticipantsBubble() {
        let bubbleSize = this.getBubbleSize(true);
        let bubbleWidth = bubbleSize.width;
        let bubbleHeight = bubbleSize.height;
        var offSet = this.utilService.getOffset(this.participantArea.nativeElement.getElementsByClassName("first-box")[0]);
        for (let action of this.selectedPrioCorpActions) {
             action.xPos = action.xRatio * offSet.width / 100;
            action.yPos = action.yRatio * offSet.height / 100;
          
            if ((action.xPos + bubbleWidth) > (offSet.width -1)) {
                action.xPos = offSet.width - 1-bubbleWidth;
            }
            if ((action.yPos + bubbleHeight) > (offSet.height-1)) {
                action.yPos = offSet.height - 1-bubbleHeight;
            }
        }
    }

    clickQuadrants(e) {
        console.log(e);
        if (e == 'fromBubble') {
            //this.selectedId = '';
            //this.selectedCard = '';
            this.curentActionCard = {};
            let keys = Object.keys(this.countBubbleSelected);
            keys.forEach((index) => {
                this.countBubbleSelected[index] = false;
                this.countBubbleHighlight[index] = false;
                if (this.isCorporate && !this.self) {
                    this.newPrioritizedActions[index].forEach((a) => {
                        if (a.id == this.selectedCard.id) {
                            this.countBubbleHighlight[index] = a;
                        }
                    })
                }
            });
            this.hideShowTooltip = !this.hideShowTooltip;
        }
        else if (e == '' || e == undefined) {
            //this.hideShowTooltip = !this.hideShowTooltip;
        }
        else {
            this.onselectedCard(e);
            this.selectedCard = e;
            //setTimeout(() => {
            let keys = Object.keys(this.countBubbleSelected);
            keys.forEach((index) => {
                this.countBubbleSelected[index] = false;
                this.countBubbleHighlight[index] = false;
                this.newPrioritizedActions[index].forEach((a) => {
                    if (a.id == this.selectedCard.id) {
                        this.countBubbleHighlight[index] = a;
                    }
                })
            });
                 //this.onselectedCard(e);
                //this.hideShowTooltip = !this.hideShowTooltip;
            //});
        }
    }

    @Output("closeEvent") componentEvent: any = new EventEmitter();
    @ViewChild('zoomModal', { static:true }) zoomModal: any;
    returnedValue: any = {};
    public config = {
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true
    };
    observable: Observable<any>;
    subject: any;

    openDialog() {
       // this.returnedValue ;
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.zoomModal.config = this.config;
        this.zoomModal.show();
        return this.observable;
    }

    private closeDialog() {
        this.zoomModal.hide();
        this.returnedValue = this.selectedCard;
        return of(this.returnedValue);
    }

    onModalClosed($event: any) {
        this.returnedValue = this.selectedCard;

        this.subject.next(this.returnedValue);
        this.componentEvent.emit({ value: "closed" });
    }
    closeBtn() {
        this.closeDialog();
    }
}
