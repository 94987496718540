import { Component, OnInit, ViewChild, Injector, Output, EventEmitter, AfterViewInit, ElementRef } from '@angular/core';

import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

import { TemplateTypes } from './../template-types.enum';
import { VelocityService } from '../../service/velocity.service';
import { ProgressBarHelper, ProgressBarOptions } from '../../progress-bar-module/progress-bar-options';
import { BaseComponent } from '../../component/base.component';
import { LogService } from "../../service/log.service";
import { LogTypes, LogAction } from "../../service/model/log-models";


@Component({
    selector: "info-dialog",
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.scss']
})

export class InfoDialogComponent extends BaseComponent implements OnInit, AfterViewInit {
    templateType: TemplateTypes = TemplateTypes.DEFAULT;
    types = TemplateTypes;
    isPinned = false;

    templateData: any = {};
    hideCloseButton: boolean = false;
    backdropClose: boolean = false;
    observable: Observable<any>;
    subject: any;
    @Output("closeEvent") componentEvent: any = new EventEmitter();
    @ViewChild('infoInput') infoInput: ElementRef;

    constructor(private injector: Injector,
        private velocityService: VelocityService,
        private logService: LogService,
        private logTypes: LogTypes) {
        super(injector);
        this.templateType = this.injector.get('templateType');
        this.templateData = this.injector.get('templateData');
        this.hideCloseButton = this.injector.get('hideCloseButton');
        this.backdropClose = this.injector.get('backdropClose');
    }
    @ViewChild('infoModal', { static: true }) infoModal: any;
    returnedValue: string = 'close';

    public config = {
        animated: false,
        keyboard: true,
        backdrop: true,
        ignoreBackdropClick: true
    };

    ngOnInit() {
        if (this.templateType == 5) {
            this.logCardAcceseedEvent(this.templateData.id);
        }
        this.infoModal.config.ignoreBackdropClick = !this.backdropClose;
    }

    ngAfterViewInit() {
        this.infoInput.nativeElement.focus();
    }

    onModalClosed($event: any) {
        this.subject.next(this.returnedValue);
        this.componentEvent.emit({ value: "closed" });

    }


    openDialog() {

        //To prevent the dialog box closing by ESC key - Implement for Growth Plan No Action card dialog box
        if (this.templateType == 10) {
            this.config.keyboard = false;
        }

        this.returnedValue = "close";
        this.subject = new Subject();
        this.observable = this.subject.asObservable();
        this.infoModal.config = this.config;
        this.infoModal.show();
        return this.observable;
    }

    public closeDialog() {
        this.infoModal.hide();
    }
    onGrowthPlanAction() {
        this.returnedValue = "start";
        this.closeDialog();
    }

    onPinInsights() {
        this.templateData.isPinned = !this.templateData.isPinned;

        this.velocityService.setInsightsPin(this.templateData.id, this.templateData.isPinned == true ? 1 : 0, this.templateData.source).subscribe((result: any) => {
            if (this.templateData.isPinned)
                this.logPinInsightEvent(this.templateData.id);
        });
    }

    logCardAcceseedEvent(cardId: number) {
        let log = new LogAction();
        log.action = this.logTypes.CARD_ACCESSED;
        log.cardId= cardId;
        this.logService.logAction(log);
    }

    logPinInsightEvent(cardId: number) {
        let log = new LogAction();
        log.action = this.logTypes.INSIGHT_PINNED;
        log.cardId = cardId;
        this.logService.logAction(log);
    }
}