import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';


import { StorageHelperService } from './../../service/storage-helper.service';

import { AuthenticationService } from './auth.service';
import { User } from '../model/user';
import { TimerService } from '../../service/timer.service';
import { Constants } from '../../service/constant.service';
import { GrowthPlanListDto } from '../../focus/model/face-to-face.model';

@Injectable()
export class UserService {
    storageType: string = "local";//"session";
    isMessagePopupClosed: boolean = false;
    private isLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
    public currentUserObservable = this.currentUserSubject.asObservable();
    public TCSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public TCObservable = this.TCSubject.asObservable();
    private authenticationService: AuthenticationService;
    constructor(
        private storageHelperService: StorageHelperService,
        private timer: TimerService,
        private constants: Constants,
    ) {
    }

    setAuthenticationService(authenticationService) {
        this.authenticationService = authenticationService;
    }

    //getUsers(): Observable<User[]> {
    //// add authorization header with jwt token
    //const headers = new Headers({ 'Authorization': 'Bearer ' + this.authenticationService.token });
    //const options = new RequestOptions({ headers: headers });

    //// get users from api
    //return this.http.get('/api/users', options)
    //    .map((response: Response) => response.json());
    // }

    getCurrentUser() {
        var currentUser: any;
        if (this.storageType == "session") {
            currentUser = this.storageHelperService.GetSessionValue("currentuser");
        }
        else {
            currentUser = this.storageHelperService.GetLocalValue("currentuser");
            // this.currentUserSubject.next(currentUser);
        }
        return currentUser;

    }

    setCurrentUser(user: any) {
        if (user && !user.hasOwnProperty("cookieAccepted")) {
            user.cookieAccepted = false;
        }

        if (this.storageType == "session") {
            this.storageHelperService.SetSessionValue("currentuser", user);
        }
        else {
            this.storageHelperService.SetLocalValue("currentuser", user);
        }

        //Set current GP



        this.currentUserSubject.next(user);
        this.isLoggedInSubject.next(true);
    }

    updateUserTC(status: boolean) {
         let user = this.getCurrentUser();
         user.IsTCAccepted = status;
        console.log(user);

        if (this.storageType == "session") {
            this.storageHelperService.SetSessionValue("currentuser", user);
        }
        else {
            this.storageHelperService.SetLocalValue("currentuser", user);
        }
        this.currentUserSubject.next(user);

    }
    setUserProfileImage(userProfileImage: any) {
        var currentUser: any;
        //get user
        if (this.storageType == "session") {
            currentUser = this.storageHelperService.GetSessionValue("currentuser");
        }
        else {
            currentUser = this.storageHelperService.GetLocalValue("currentuser");

        }
        currentUser.profileUrl = userProfileImage;

        //set user
        if (this.storageType == "session") {
            this.storageHelperService.SetSessionValue("currentuser", currentUser);
        }
        else {
            this.storageHelperService.SetLocalValue("currentuser", currentUser);
        }
        this.currentUserSubject.next(currentUser);

        //   this.currentUserSubject.next(currentUser);
    }

    setUserPreferredName(userPreferredName: any) {

        var currentUser = this.getCurrentUser();
        currentUser.preferredName = userPreferredName;
        this.setCurrentUser(currentUser);
    }

    setCookiePolicy(isAccept: any) {

        var currentUser = this.getCurrentUser();
        currentUser.cookieAccepted = isAccept;
        this.setCurrentUser(currentUser);
    }


    logout() {
        if (this.storageType == "session") {
            this.storageHelperService.ClearSessionValue("currentuser");
        }
        else {
            this.storageHelperService.ClearLocalValue("currentuser");
        }
      this.storageHelperService.ClearSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
      localStorage.removeItem("hidedashboardNotification");
        this.isLoggedInSubject.next(false);
        this.currentUserSubject.next(null);
        this.timer.stopAndKill();
        this.timer.clearStartTime();
    }

    logoutmanual() {
        //setTimeout(() => {
            //var currentUser = this.getCurrentUser();
            //if (currentUser != null) {
                this.authenticationService.autoLogout().subscribe(success => {
                    //token is valid - redirect to dashboard
                    if (this.storageType == "session") {
                        this.storageHelperService.ClearSessionValue("currentuser");
                    }
                    else {
                        this.storageHelperService.ClearLocalValue("currentuser");
                      localStorage.removeItem("dashboardView");
                      localStorage.removeItem("hidedashboardNotification");
                    }
                    this.storageHelperService.ClearSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
                    this.isLoggedInSubject.next(false);
                    this.currentUserSubject.next(null);
                    this.timer.stopAndKill();
                    this.timer.clearStartTime();


                }, error => {
                    //token is invalid - clear local storage and redirect to dashboard
                    console.log("token is invalid")

                });
           // }
        //}, 200);

        
    }

    //getMessagePopupCloseFlag() {
    //    return this.isMessagePopupClosed;
    //}

    //setMessagePopupCloseFlag(closeFlag: boolean) {
    //    this.isMessagePopupClosed = closeFlag;
    //}

    isLoggedIn(): Observable<boolean> {
        var currentUser = this.getCurrentUser();
        if (currentUser && currentUser.userId) {
            this.isLoggedInSubject.next(true);
        }
        else {
            this.isLoggedInSubject.next(false);
        }
        return this.isLoggedInSubject.asObservable();
    }

    getCurrentUserAsObservable(): Observable<any> {
        var currentUser: any;
        if (this.storageType == "session") {
            currentUser = this.storageHelperService.GetSessionValue("currentuser");
            this.currentUserSubject.next(currentUser);
        }
        else {
            currentUser = this.storageHelperService.GetLocalValue("currentuser");
            this.currentUserSubject.next(currentUser);
        }
        return this.currentUserObservable;

    }
    isTermsCondition(): Observable<boolean> {
        return this.TCObservable;
    }
    setUserPreferredTimezone(userPreferredTimezone: any) {

        var currentUser = this.getCurrentUser();
        currentUser.preferredTimezoneId = parseInt(userPreferredTimezone.id);
        currentUser.hourOffset = parseInt(userPreferredTimezone.hourOffset);
        currentUser.minOffset = parseInt(userPreferredTimezone.minOffset);
        this.setCurrentUser(currentUser);
    }
    setUserPreferredLanguage(languageID: any) {

        var currentUser = this.getCurrentUser();
        currentUser.preferredLanguageId = parseInt(languageID);
        this.setCurrentUser(currentUser);
    }

    setUserNotification(isNotificationEnabled: any) {

        var currentUser = this.getCurrentUser();
        currentUser.notificationStatus = isNotificationEnabled;
        this.setCurrentUser(currentUser);
    }

    getGrowthplanId(): number {
        let growthPlanId: number = 0;
        const growthPlanData: GrowthPlanListDto = this.storageHelperService.GetSessionValue(this.constants.SEVEN_DRIVER_GROWTH_PLAN);
        growthPlanId = growthPlanData ? growthPlanData.growthPlanId : 0;
        return growthPlanId;
    }
}
