// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    environmentName: 'dev',

    //Old Classic Evnvironment
    //VELOCITY_API: 'https://velocityapi.vel-dev.ey.com/api/',
    //GROWTH_NAVIGATOR_API: 'https://velocitygnapi.vel-dev.ey.com/api/',
    //QUERY_ENGINE_API: 'https://velocityqeapi.vel-dev.ey.com/api/',
    //AZURE_TENANT: 'https://login.microsoftonline.com/eygs.onmicrosoft.com',
    //AZURE_CLIENTID: '1870fd51-20fa-4249-a3d1-c38e23756137',
    //USE_MYEY_LOGIN: true,
    //REDIRECT_URL: 'https://velocity.vel-dev.ey.com/welcome',
    //POST_LOGOUT_REDIRECT_URI: 'https://velocity.vel-dev.ey.com/welcome',
    //SCOPE: 'openid email profile eyvelocityportal',
    //DISABLE_DEV_MODE: true,
    //DOMAIN: 'auth02.ey.com',
    //CLIENT_ID_AUTH0: 'YFfO2g6nlxuzv7YTgpeq1zLDIn3ubuPw'

    //New Fabric Environement
    VELOCITY_API: 'https://velocityapi.vel-dev1.ey.com/api/',
    GROWTH_NAVIGATOR_API: 'https://velocitygnapi.vel-dev1.ey.com/api/',
    QUERY_ENGINE_API: 'https://velocityqeapi.vel-dev1.ey.com/api/',
    AZURE_TENANT: 'https://login.microsoftonline.com/eyqa.onmicrosoft.com',
    AZURE_CLIENTID: '1179dce6-6bf1-41be-a2e7-79c74e97959b',
    USE_MYEY_LOGIN: true,
    REDIRECT_URL: 'https://velocity.vel-dev1.ey.com/welcome',
    POST_LOGOUT_REDIRECT_URI: 'https://velocity.vel-dev1.ey.com/welcome',
    SCOPE: 'openid email profile eyvelocityportal',
    DISABLE_DEV_MODE: true,
    DOMAIN: 'auth-qa02.ey.com', 
    CLIENT_ID_AUTH0: 'LW4vh3SnqIoDZfAXwbVBr3nqmNVqaII1'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
